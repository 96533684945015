import React, { Component } from 'react';
import Translation from './Translation';
import axios from 'axios';
import UserController from './UserController';

import Drop from './img/Drop.svg';
import './Dashboard.css';
class PaddockStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      sent: false
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }

  save(){
    const self = this;
    self.setState({ sent: true });
    var paddocks = this.props.farm.paddocks.map((p, j)=> {return {"id": p.id, "name": p.name, "status": p.status};})
    axios
      .post('https://staging.grasslandtools.ie/api/updatestatuses/', {
        sessionid: UserController.getSessionkey(),
        paddocks: paddocks,
      })
      .then(function(response) {
        self.setState({ sent: false });
      })
      .catch(function(error) {
        self.setState({ sent: false });
        alert(self.trans('error'));
      });}

  render() {
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('paddock_status')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "0 20px 10px"}}>
          <div className="MetricGroup">
            <div className="Metric" style={{width: "100%"}}>
              <div className="Button Action" onClick={this.save.bind(this)}>
                {this.state.sent ? this.trans("saving_") : this.trans('save')}
              </div>
            </div>
          </div>
          <table style={{width: "100%", padding: "0 10px"}}>
            <thead>
              <tr>
                <th style={{width: "20%"}}>{this.trans('in_grazing_platform')}</th>
                <th style={{width: "20%"}}>{this.trans('silage_cut_now')}</th>
                <th style={{width: "20%"}}>{this.trans('silage_cut_later')}</th>
                <th style={{width: "20%"}}>{this.trans('closed')}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.farm.paddocks.map((p, i)=><tr key={i}>
                <td style={{width: "20%"}}>{p.status==1 ? <div className="Button Green">{p.name}</div> : <div className="Button Grey" onClick={this.props.updateDatasetStatus.bind(this, {padstr: '' + p.id,status: 1})}>{p.name}</div>}</td>
                <td style={{width: "20%"}}>{p.status==7 ? <div className="Button Red">{p.name}</div> : <div className="Button Grey" onClick={this.props.updateDatasetStatus.bind(this, {padstr: '' + p.id,status: 7})}>{p.name}</div>}</td>
                <td style={{width: "20%"}}>{p.status==6 ? <div className="Button Red">{p.name}</div> : <div className="Button Grey" onClick={this.props.updateDatasetStatus.bind(this, {padstr: '' + p.id,status: 6})}>{p.name}</div>}</td>
                <td style={{width: "20%"}}>{p.status==8 ? <div className="Button Red">{p.name}</div> : <div className="Button Grey" onClick={this.props.updateDatasetStatus.bind(this, {padstr: '' + p.id,status: 8})}>{p.name}</div>}</td>
              </tr>)}
            </tbody>
          </table>
        </div>}
      </div>
    );
  }
}

export default PaddockStatus;
