import React, { Component } from 'react';

import axios from 'axios';
import UserController from './UserController';
import Translation from './Translation';

import Loading from './Loading';

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      sent: false,
      old_password: '',
      new_password_1: '',
      new_password_2: ''
    };
    this.trans = Translation.getInstance().translate;
  }

  opChange(event) {
    this.setState({ old_password: event.target.value });
  }
  np1Change(event) {
    this.setState({ new_password_1: event.target.value });
  }
  np2Change(event) {
    this.setState({ new_password_2: event.target.value });
  }

  updatePassword() {
    const self = this;
    if (!this.state.old_password) {
      self.setState({
        error: this.trans('input_old_password')
      });
      return;
    }
    if (!this.state.new_password_1) {
      self.setState({
        error: this.trans('input_new_password')
      });
      return;
    }
    if (!this.state.new_password_2) {
      self.setState({
        error: this.trans('_confirm_new_password')
      });
      return;
    }
    if (this.state.new_password_1 !== this.state.new_password_2) {
      self.setState({
        error: this.trans('new_password_and_confirm_password_must_match')
      });
      return;
    }
    if (this.state.old_password === this.state.new_password_1) {
      self.setState({
        error: this.trans('new_and_old_passwords_are_the_same')
      });
      return;
    }
    self.setState({ sent: true, error: null });
    axios
      .post('https://staging.grasslandtools.ie/api/updatepassword/', {
        sessionid: UserController.getSessionkey(),
        old_password: this.state.old_password,
        new_password: this.state.new_password_1
      })
      .then(function(response) {
        if (response.data.error) {
          self.setState({
            sent: false,
            error: self.trans(response.data.error)
          });
          return;
        }
        self.setState({ sent: false });
        self.props.getDataset(null, 'Overview', null);
        self.props.setAppState({ display: 'farm_selector' });
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }

  render() {
    if (this.state.sent) {
      return (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 100px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <div className="LoginContainer">
            <div className="Login">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loading text={'updating_your_password'} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <div className="LoginContainer">
          <div className="Login">
            <form>
              <h3>{this.trans('change_password')}</h3>
              <p
                style={{
                  color: 'red'
                }}
              >
                {this.state.error}
              </p>
              <label>{this.trans('old_password')}:</label>
              <input
                className="FormInput"
                type="password"
                value={this.state.old_password}
                onChange={this.opChange.bind(this)}
              />
              <p />
              <label>{this.trans('new_password')}:</label>
              <input
                className="FormInput"
                type="password"
                value={this.state.new_password_1}
                onChange={this.np1Change.bind(this)}
              />
              <p />
              <label>{this.trans('confirm_new_password')}:</label>
              <input
                className="FormInput"
                type="password"
                value={this.state.new_password_2}
                onChange={this.np2Change.bind(this)}
              />
              <div className="Button" onClick={this.updatePassword.bind(this)}>
                {this.trans('change_password')}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
