import React, { Component } from 'react';

import axios from 'axios';
import UserController from './UserController';
import Translation from './Translation';

class CreateHerd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      herd_no: "",
      herd_name: "",
    };
    this.trans = Translation.getInstance().translate;
    this.handleHerdNoChange = this.handleHerdNoChange.bind(this)
    this.handleHerdNameChange = this.handleHerdNameChange.bind(this)
    this.createHerd = this.createHerd.bind(this)
  }

  handleHerdNameChange(event) {
    this.setState({ herd_name: event.target.value });
  }
  handleHerdNoChange(event) {
    this.setState({ herd_no: event.target.value });
  }
  createHerd(){
    if(this.state.herd_name && this.state.herd_no) {
      this.props.loadingMessage('creating_herd');
      const self = this
      axios
        .post('https://staging.grasslandtools.ie/api/v2/createherd/', {
          sessionid: UserController.getSessionkey(),
          herdno: this.state.herd_no,
          herdname: this.state.herd_name,
        })
        .then(response=>{
          self.props.herdCreated(response.data.herd_name,response.data.herd_id);
        })
    }
  }

  render() {
      return (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 65px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <div className="LoginContainer">
            <div className="Login">
              <form>
                <h3>{this.trans('create_herd')}</h3>
                <hr
                  style={{
                    marginBottom: '15px'
                  }}
                />
                <p />
                <label>{this.trans('herd_name')}</label>
                <input
                  className="FormInput"
                  type="text"
                  value={this.state.herd_name}
                  onChange={this.handleHerdNameChange}
                  placeholder={this.trans('name')}
                />
                <p />
                <label>{this.trans('herd_number')}</label>
                <input
                  className="FormInput"
                  type="text"
                  value={this.state.herd_no}
                  onChange={this.handleHerdNoChange}
                  placeholder={this.trans('herd_number')}
                />
                <p />
                <div
                  className="Button"
                  id="button"
                  value="Submit"
                  onClick={this.createHerd.bind(this)}
                >
                  {this.trans('create_herd')}
                </div>
              </form>
              <p />
            </div>
          </div>
        </div>
      );
  }
}

export default CreateHerd;
