var UserController = (function() {
  var username = '';
  var password = '';
  var sessionkey = '';
  var farmid = '';
  var herdid = '';
  var user_type = '';
  var email = '';
  var whitelist = false;
  var stripe = '';
  var farmLimit = 0;
  var survey_method = '';
  var electricity_device = '';

  var getUsername = function() {
    return username;
  };

  var setUsername = function(n) {
    username = n;
  };

  var getPassword = function() {
    return password;
  };

  var setPassword = function(p) {
    password = p;
  };

  var getSessionkey = function() {
    return sessionkey;
  };

  var setSessionkey = function(k) {
    sessionkey = k;
  };

  var getFarmid = function() {
    return farmid;
  };

  var setFarmid = function(f) {
    farmid = f;
  };


  var getHerdid = function() {
    return herdid;
  };

  var setHerdid = function(h) {
    herdid = h;
  };

  var getUserType = function() {
    return user_type;
  };

  var setUserType = function(t) {
    user_type = t;
  };

  var getEmail = function() {
    return email;
  };

  var setEmail = function(e) {
    email = e;
  };

  var getWhitelist = function() {
    return whitelist;
  };

  var setWhitelist = function(w) {
    whitelist = w;
  };

  var getStripe = function() {
    return stripe;
  };

  var setStripe = function(s) {
    stripe = s;
  };

  var getFarmLimit = function() {
    return farmLimit;
  };

  var setFarmLimit = function(s) {
    farmLimit = s;
  };

  var getSurveyMethod = function() {
    return survey_method;
  };

  var setSurveyMethod = function(s) {
    survey_method = s;
  };

  var getElectricityDevice = function() {
    return electricity_device;
  };

  var setElectricityDevice = function(s) {
    electricity_device = s;
  };

  return {
    getUsername: getUsername,
    setUsername: setUsername,
    getPassword: getPassword,
    setPassword: setPassword,
    getSessionkey: getSessionkey,
    setSessionkey: setSessionkey,
    getFarmid: getFarmid,
    setFarmid: setFarmid,
    getHerdid: getHerdid,
    setHerdid: setHerdid,
    getUserType: getUserType,
    setUserType: setUserType,
    getEmail: getEmail,
    setEmail: setEmail,
    getWhitelist: getWhitelist,
    setWhitelist: setWhitelist,
    getStripe: getStripe,
    setStripe: setStripe,
    getFarmLimit: getFarmLimit,
    setFarmLimit: setFarmLimit,
    getSurveyMethod: getSurveyMethod,
    setSurveyMethod: setSurveyMethod,
    getElectricityDevice: getElectricityDevice,
    setElectricityDevice: setElectricityDevice
  };
})();

export default UserController;
