import React, { Component } from 'react';

import axios from 'axios';
import UserController from './UserController';
import Translation from './Translation';
import EmailValidator from 'email-validator';
import Loading from './Loading';
import ChangePassword from './ChangePassword';
import UpdatePaymentInformation from './UpdatePaymentInformation';
import countries from './json/countries.json';

class AccountSettings extends Component {
  constructor(props) {
    super(props);
    const user = this.props.user_data;
    this.state = {
      disp: 'settings',
      error: null,
      sent: false,
      email: user.email,
      phone: user.phone,
      country: user.address.country,
      county: user.address.county,
      town: user.address.town,
      townland: user.address.townland,
      postcode: user.address.postcode
    };
    this.trans = Translation.getInstance().translate;
  }
  ChangePassword() {
    this.setState({ disp: 'change_password' });
  }
  UpdatePaymentDetails() {
    this.setState({ disp: 'update_payment_information' });
  }

  emailChange(event) {
    this.setState({ email: event.target.value });
  }
  phoneChange(event) {
    this.setState({ phone: event.target.value });
  }
  countryChange(event) {
    this.setState({ country: event.target.value });
  }
  countyChange(event) {
    this.setState({ county: event.target.value });
  }
  townChange(event) {
    this.setState({ town: event.target.value });
  }
  townlandChange(event) {
    this.setState({ townland: event.target.value });
  }
  postcodeChange(event) {
    this.setState({ postcode: event.target.value });
  }

  updateDetails() {
    const self = this;

    if (!this.state.email) {
      self.setState({
        error: this.trans('input_email_address')
      });
      return;
    }
    if (!EmailValidator.validate(this.state.email)) {
      self.setState({
        error: this.trans('input_a_valid_email_address')
      });
      return;
    }
    if (!this.state.phone) {
      self.setState({
        error: this.trans('input_phone')
      });
      return;
    }
    if (!this.state.country) {
      self.setState({
        error: this.trans('input_country')
      });
      return;
    }
    if (!this.state.county) {
      self.setState({
        error: this.trans('input_county')
      });
      return;
    }
    if (!this.state.town) {
      self.setState({
        error: this.trans('input_town')
      });
      return;
    }
    self.setState({ sent: true });
    axios
      .post('https://staging.grasslandtools.ie/api/updateaccount/', {
        sessionid: UserController.getSessionkey(),
        email: this.state.email,
        phone: this.state.phone,
        country: this.state.country,
        county: this.state.county,
        town: this.state.town,
        townland: this.state.townland,
        postcode: this.state.postcode
      })
      .then(function(response) {
        self.props.getDataset(null, 'Overview', null);
        self.props.setAppState({ display: 'farm_selector' });
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }

  render() {
    if (this.state.disp === 'change_password') {
      return (
        <ChangePassword
          user_data={this.props.user_data}
          getDataset={this.props.getDataset}
          setAppState={this.props.setAppState}
        />
      );
    }
    if (this.state.disp === 'update_payment_information') {
      return (
        <UpdatePaymentInformation
          user_data={this.props.user_data}
          getDataset={this.props.getDataset}
          setAppState={this.props.setAppState}
        />
      );
    }
    if (this.state.sent) {
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loading text={'updating_your_account'} />
        </div>
      );
    }
    const countryOptions = [<option key={0} value="" />].concat(
      countries.countries.map((c, i) => {
        return (
          <option key={i + 1} value={c.code}>
            {c.name}
          </option>
        );
      })
    );
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 65px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <div className="LoginContainer">
          <div className="Login">
            <form>
              <h3>{this.trans('account_settings')}</h3>
              <div className="Button" onClick={this.ChangePassword.bind(this)}>
                {this.trans('change_password')}
              </div>
              <div
                className="Button"
                style={
                  UserController.getStripe() !== "none"
                    ? null
                    : {
                        display: 'none'
                      }
                }
                onClick={this.UpdatePaymentDetails.bind(this)}
              >
                {this.trans('update_payment_information')}
              </div>
              <p />
              <h4>{this.trans('update_personal_information')}</h4>
              <p
                style={{
                  color: 'red'
                }}
              >
                {this.state.error}
              </p>
              <label>{this.trans('email')}:</label>
              <input
                className="FormInput"
                type="email"
                value={this.state.email}
                onChange={this.emailChange.bind(this)}
              />
              <p />
              <label>{this.trans('phone_number')}:</label>
              <input
                className="FormInput"
                type="text"
                value={this.state.phone}
                onChange={this.phoneChange.bind(this)}
              />
              <p />
              <label>{this.trans('country')}:</label>
              <select
                className="FormInput"
                value={this.state.country}
                onChange={this.handleCountryChange}
              >
                {countryOptions}
              </select>
              <p />
              <label>{this.trans('county')}:</label>
              <input
                className="FormInput"
                type="text"
                value={this.state.county}
                onChange={this.countyChange.bind(this)}
              />
              <p />
              <label>{this.trans('town')}:</label>
              <input
                className="FormInput"
                type="text"
                value={this.state.town}
                onChange={this.townChange.bind(this)}
              />
              <p />
              <label>{this.trans('townland')}:</label>
              <input
                className="FormInput"
                type="text"
                value={this.state.townland}
                onChange={this.townlandChange.bind(this)}
              />
              <p />
              <label>{this.trans('postcode')}:</label>
              <input
                className="FormInput"
                type="text"
                value={this.state.postcode}
                onChange={this.postcodeChange.bind(this)}
              />
              <div className="Button" onClick={this.updateDetails.bind(this)}>
                {this.trans('update_details')}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountSettings;
