import React, { Component } from 'react';
import { max } from 'd3-array';
import { axisLeft } from 'd3-axis';
import { scaleLinear, scaleBand } from 'd3-scale';
import { select } from 'd3-selection';

import Translation from './Translation';

class HerdProfile extends Component {
  constructor(props) {
    super(props);
    this.createHerdProfile = this.createHerdProfile.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  componentDidMount() {
    this.createHerdProfile();
    window.addEventListener('resize', this.createHerdProfile);
  }

  createHerdProfile() {
    select('.tooltip').remove();
    const selectAnimal = this.props.selectAnimal; //.bind(this)
    const selectedAnimal = this.props.selected_animal;
    const self = this;
    const chart = document.getElementById('HerdProfileSVG');
    if (!chart) {
      return;
    }

    document.getElementById('HerdProfileSVG').innerHTML = '';

    // Get height and width
    const h = chart.clientHeight;
    const w = chart.clientWidth;

    // Define margins
    const margin = {
      top: 25,
      right: 10,
      bottom: 20,
      left: 45
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    // Create SVG
    const svg = select('#HerdProfileSVG')
      .append('svg')
      .attr('width', w)
      .attr('height', h);

    // Append background rect
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', w)
      .attr('height', h)
      .attr('fill', '#ffffff00')
      .on('click', selectAnimal.bind(null));

    const measurement = this.props.displayed_measurement;
    const selectedAnimals = this.props.selectedAnimals;

    const dataset = [];
    for (let animal of selectedAnimals) {
      let weight = 0;
      for (let m of measurement.animalMeasurements) {
        if (m.animal_id === animal.id) {
          weight = m.weight;
          const d = {
            animal: animal,
            weight: weight,
            condition: m.condition,
            docility: m.docility
          };
          dataset.push(d);
          break;
        }
      }
    }

    dataset.sort(function(a, b) {
      return b.weight - a.weight;
    });

    const xScale = scaleBand()
      .range([0, width])
      .domain(
        dataset.map(d => {
          return d.animal.tag_no;
        })
      );
    const yScale = scaleLinear()
      .range([height, 0])
      .domain([
        0,
        max(dataset, d => {
          return d.weight * 9 / 8;
        })
      ]);

    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')')
      .call(
        axisLeft(yScale)
          .ticks(6)
          .tickSize(-width)
          .tickFormat('')
      );

    let barWidth;
    if (dataset.length > 1) {
      barWidth =
        xScale(dataset[1].animal.tag_no) - xScale(dataset[0].animal.tag_no);
    } else {
      barWidth = width / 2;
    }

    const bars = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')');
    bars
      .selectAll('rect')
      .data(dataset)
      .enter()
      .append('rect')
      .attr('x', d => {
        return barWidth * 0.1 + xScale(d.animal.tag_no);
      })
      .attr('y', d => {
        return yScale(d.weight);
      })
      .attr('width', barWidth * 0.8)
      .attr('height', d => {
        return height - yScale(d.weight);
      })
      .attr('class', d => {
        if (d.animal === selectedAnimal) {
          return 'WedgeBar active';
        }
        return 'WedgeBar';
      })
      .on('mouseover', (d, i) => {
        // Remove old tooltip
        select('.tooltip').remove();
        const tooltip = select('#HerdProfileSVG')
          .append('div')
          .attr('class', 'tooltip')
          .style(
            'left',
            margin.left + xScale(d.animal.tag_no) + barWidth / 2 - 100 + 'px'
          )
          .style('top', margin.top + yScale(d.weight) - 72 + 'px')
          .style('width', '200px')
          .html(
            self.trans('tag_number') +
              ': ' +
              d.animal.tag_no +
              '<br/>' +
              self.trans('weight') +
              ': ' +
              d.weight +
              ' kg'
            // ' kg<br/>Condition: ' +
            // d.animal.condition +
            // '<br/>Docility: ' +
            // d.animal.docility
          );
        tooltip
          .transition()
          .duration(250)
          .style('opacity', 1);
      })
      .on('mouseout', function() {
        // Fade out tooltip
        select('.tooltip')
          .transition()
          .duration(250)
          .style('opacity', 0)
          .remove();
      })
      .on('click', d => {
        return selectAnimal(d.animal);
      });

    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .call(axisLeft(yScale).ticks(6));

    svg
      .append('text')
      .attr('x', margin.left)
      .attr('y', margin.top - 10)
      .attr('class', 'LegendTitle')
      .text(this.trans('live_weight_kg_svg'))
      .attr('text-anchor', 'start');

    // Add heading
    // svg
    //   .append('text')
    //   .text(this.trans('herd_profile'))
    //   .attr('x', w / 2)
    //   .attr('y', margin.top - 10)
    //   .attr('class', 'ChartHeading')
    //   .attr('text-anchor', 'middle');
  }

  render() {
    this.createHerdProfile();
    return (
      <div
        style={{
          height: 'calc((100vh - 65px - 40px) / 3)',
          width: 'calc(100%)',
          position: 'relative'
        }}
        id="HerdProfileSVG"
      />
    );
  }
}

export default HerdProfile;
