import React, { Component } from 'react';
import './Body.css';

import Dashboard from './Dashboard';
import Weather from './Weather';
import Soil from './Soil';
import Map from './Map';

import Loading from './Loading';

class Body extends Component {
  render() {
    const disp = this.props.display;
    if (disp === 'grass_dashboard') {
      return (
        <div className={'Body'} id="Body">
          <Dashboard
            map_key={this.props.map_key}
            fertilisers={this.props.fertilisers}
            cultivars={this.props.cultivars}
            menu_shown={this.props.menu_shown}
            toggleMenu={this.props.toggleMenu}
            display={this.props.display}
            survey={this.props.survey}
            setPaddock={this.props.setPaddock}
            fm_paddock={this.props.fm_paddock}
            gw_paddock={this.props.gw_paddock}
            ph_paddock={this.props.ph_paddock}
            farm={this.props.farm}
            getDataset={this.props.getDataset}
            updateDatasetStatus={this.props.updateDatasetStatus}
            updateDatasetBlocks={this.props.updateDatasetBlocks}
            mobile={this.props.mobile}
          />
        </div>
      );
    }
    if (disp === 'weather') {
      return (
        <div className={'Body'} id="Body">
          <Weather />
        </div>
      );
    }
    if (disp === 'soil_analysis') {
      if (!this.props.soil_data_loaded) {
        this.props.loadSoilData();
        return <Loading text={'loading_soil_data'} />;
      }
      return (
        <div key={this.props.soil_key} className={'Body'} id="Body">
          <Soil
            dataset={this.props.farm}
            loadSoilData={this.props.loadSoilData}
            soil_data={this.props.soil_data}
            fertilisers={this.props.fertilisers}
          />
        </div>
      );
    }
    if (disp === 'map_editor') {
      return (
        <div className="Body" id="Body">
          <Map
            setAppState={this.props.setAppState}
            dataset={this.props.farm}
            getDataset={this.props.getDataset}
            appkey={this.props.appkey}
          />
        </div>
      );
    }
    return null;
  }
}

export default Body;
