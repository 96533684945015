import React, { Component } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import CardForm from './CardForm';
import Translation from './Translation';

class Stripe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ac_type: 1
    };
    this.trans = Translation.getInstance().translate;
  }
  acTypeChange(event) {
    this.props.setAcType(event.target.value);
    this.setState({ ac_type: event.target.value });
  }
  render() {
    const promotion = this.props.grasshopper ? (
      <div>
        <p>
          {this.trans('as_a_gt_customer_you_qualify_for')}<br />{this.trans('12_months_free_access_to_gt')}
        </p>
        <p>
          {this.trans('we_still_need_your_card_details_for')}
        </p>
      </div>
    ) : null;
    let text = '';
    if (this.props.tier === 'farmer') {
      text = (
        <div>
          <p>{this.trans('farmer_account_price')}</p>
          {promotion}
          <p>{this.trans('enter_card_details')}</p>
        </div>
      );
    }
    if (this.props.tier === 'advisor') {
      text = (
        <div>
          <p>{this.trans('advisor_account_price1')}</p>
          <p>{this.trans('advisor_account_price2')}</p>
          <p>{this.trans('advisor_account_price3')}</p>
          <label>{this.trans('account_required')}</label>
          <select
            value={this.state.ac_type}
            onChange={this.acTypeChange.bind(this)}
            className="FormInput"
          >
            <option value="1">{this.trans('advisor_base')}</option>
            <option value="2">{this.trans('advisor_std')}</option>
            <option value="3">{this.trans('advisor_plus')}</option>
          </select>
          <p>{this.trans('enter_card_details')}</p>
        </div>
      );
    }
    if (this.props.tier === 'contractor') {
      text = (
        <div>
          <p>{this.trans('contractor_account_price')}</p>
          <p>{this.trans('enter_card_details')}</p>
        </div>
      );
    }
    return (
      <div className="Login">
        <h3>{this.trans('payment_details')}</h3>
        {text}
        <StripeProvider apiKey="pk_live_0phW0M0AKGLwkrlCK0aRZQZI">
          <Elements>
            <CardForm
              setStripeToken={this.props.setStripeToken}
              btn_text={this.trans('create_account')}
            />
          </Elements>
        </StripeProvider>
        <div className="Button" onClick={this.props.goBack.bind(this)}>
          {this.trans('go_back')}
        </div>
      </div>
    );
  }
}

export default Stripe;
