import React, { Component } from 'react';
import Translation from './Translation';

import moment from 'moment-with-locales-es6';
import axios from 'axios';
import Drop from './img/Drop.svg';
import './Dashboard.css';
import UserController from './UserController';
import Spinner from './img/Spinner.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
class PaddockSilage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      entering: false,
      gotData: false,
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }
  setEntering(value) {
    this.setState({entering: value})
  }

  render() {
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('silage_cutting')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "10px 20px 10px"}} className="MetricsPanel">
          <div className="ToggleContainer">
            <div 
              onClick={this.setEntering.bind(this, false)} 
              className={this.state.entering ? "Toggle" : "Toggle Active"}>
                {this.trans('view_silage_cut_date')}
            </div>
            <div 
              onClick={this.setEntering.bind(this, true)}
              className={this.state.entering ? "Toggle Active" : "Toggle"}>
                {this.trans('input_silage_cut_date')}
            </div>
          </div>
          {this.props.paddock ? this.state.entering ? <SilageInput
            setEntering={this.setEntering.bind(this)}
            paddock={this.props.paddock}
          /> : <SilageList
            paddock={this.props.paddock}
          /> : <p>{this.trans('select_a_paddock_on_the_farm_map_or_grass_wedge')}</p>}
          
        </div>}
      </div>
    );
  }
}

export default PaddockSilage;

class SilageList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: null,
      silage_dates: [],
    };
    this.trans = Translation.getInstance().translate;
    this.fetchSilageDates = this.fetchSilageDates.bind(this)
  }

  componentDidMount(){
    this.fetchSilageDates(this.props.paddock.id)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.paddock.id !== this.props.paddock.id) {
      this.fetchSilageDates(nextProps.paddock.id)
    }
    return true
  }

  fetchSilageDates(padid) {
    this.setState({
      loaded: false,
      error: null,
      graze_dates: [],
    })
    const self = this;
    axios.post("https://staging.grasslandtools.ie/api/listsilagecutdates/", {
      sessionid: UserController.getSessionkey(),
      paddock: padid,
    }).then(function(response) {
      self.setState({
        loaded: true,
        error: null,
        silage_dates: response.data.silage_dates,
      })
    }).catch(function(error) {
      self.setState({
        loaded: true,
        error: "Error fetching silage dates for " + self.props.paddock.name,
        silage_dates: [],
      })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>{this.trans('loading___')}</p>
    }
    if (this.state.error) {
      return <p>{this.state.error}</p>
    }
    if (this.state.silage_dates.length == 0) {
      return <p>{this.trans('no_silage_cuts_recorded_for_')} {this.props.paddock.name}</p>
    }
    return <div style={{padding: "10px"}}>
      <table className="SelectedTable">
        <thead>
          <tr>
            <th>{this.trans('date')}</th>
            <th>{this.trans('pre_cut_cover')} (kg/ha)</th>
            <th>{this.trans('post_cut_cover')} (kg/ha)</th>
          </tr>
        </thead>
        <tbody>
          {this.state.silage_dates.map((d, i)=><tr key={i}>
            <td>{d.date}</td>
            <td>{d.pre_cover}</td>
            <td>{d.post_cover}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  }
}

class SilageInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      olddate: moment(),
      paddock: '',
      pre: '',
      post: '',
      nag: '',
      alloc: 17,
      sent: false
    };
    this.trans = Translation.getInstance().translate;
  }
  handleDateChange(d) {
    this.setState({ date: d });
  }
  handlePreChange(event) {
    this.setState({ pre: event.target.value });
  }
  handlePostChange(event) {
    this.setState({ post: event.target.value });
  }
  submit() {
    if (!this.props.paddock) {
      alert(this.trans('no_paddock_selected_submit'));
      return;
    }
    if (!this.state.date) {
      alert(this.trans('valid_date'));
      return;
    }
    const paddock = this.props.paddock;
    const date = this.state.date.format('YYYY-MM-DD');
    const pre = this.state.pre;
    const post = this.state.post;
    const self = this;
    this.setState({ sent: true });
    axios
      .post('https://staging.grasslandtools.ie/api/insertsilagecutdate/', {
        sessionid: UserController.getSessionkey(),
        date: date,
        paddock: paddock.id,
        pre: pre,
        post: post,
      })
      .then(function(response) {
        self.props.setEntering(false);
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }
  render() {
    if (this.state.sent) {
      return (
        <div
          className="InputDiv"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{this.trans('adding_silage_event')}...</div>
        </div>
      );
    }
    return (
      <div>
        <div className="MetricGroup">
          <div className="Metric">
            <label>
              {this.trans('date')}:
            </label>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              onChange={this.handleDateChange.bind(this)}
              selected={this.state.date}
              className="FormInput"
              placeholderText={this.trans('date')}
              style={{
                zIndex: '5'
              }}
            />
          </div>
          <div className="Metric">
            <label>
            {this.trans('pre_cut_cover')} (kg/ha):
            </label>
            <input
              value={this.state.pre}
              onChange={this.handlePreChange.bind(this)}
              className="FormInput"
              type="text"
              id="pre-cut"
              name="pre-cut"
              placeholder={this.trans('pre_cut_cover')}
            />
          </div>
          <div className="Metric">
            <label>
            {this.trans('post_cut_cover')} (kg/ha):
            </label>
            <input
              value={this.state.post}
              onChange={this.handlePostChange.bind(this)}
              className="FormInput"
              type="text"
              id="post-cut"
              name="post-cut"
              placeholder={this.trans('post_cut_cover')}
            />
          </div>
          <div className="Metric">
            <label style={{opacity: 0}}>
            {this.trans('submit')}
            </label>
            <div className="Button Action" onClick={this.submit.bind(this)}>
              {this.trans('submit')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}