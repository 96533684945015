import React, { Component } from 'react';
import Translation from './Translation';

class WRGettingStarted extends Component {
  constructor(props) {
    super(props);
    this.trans = Translation.getInstance().translate;
  }

  refresh() {
    
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 65px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <div className="LoginContainer">
          <div className="Login">
            <h3>{this.trans('herd_created_successfully_')}</h3>
            <hr
              style={{
                marginBottom: '15px'
              }}
            />
            <h3 style={{fontWeight: "normal"}}>{this.trans('herd_name_')}</h3>
            <h3>{this.props.herd_name}</h3>
            <hr
              style={{
                marginBottom: '15px'
              }}
            />
            <h3 style={{fontWeight: "normal"}}>{this.trans('herd_id_')}</h3>
            <h3>{this.props.herd_id}</h3>
            <hr
              style={{
                marginBottom: '15px'
              }}
            />
            <h3 style={{fontWeight: "normal"}}>{this.trans('wr_quickstart_guide')}</h3>
          </div>
        </div>
      </div>
    );
  }
}

export default WRGettingStarted;
