import React, { Component } from 'react';
// import './Login.css';
import axios from 'axios';
import UserController from './UserController';

import EmailValidator from 'email-validator';
import Spinner from './img/Spinner.png';
import Translation from './Translation';
import countries from './json/countries.json';

import Stripe from './Stripe';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      cpassword: '',
      email: '',
      fname: '',
      lname: '',
      phone: '',
      country: '',
      county: '',
      town: '',
      townland: '',
      addressline2: '',
      postcode: '',
      account_type: 'farmer',
      survey_method: '1',
      ghid: '',
      error: null,
      payment: false,
      sent: false,
      stripe_token: null,
      ac_type: 1,
      survey_methods: []
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmChange = this.handleConfirmChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleFnameChange = this.handleFnameChange.bind(this);
    this.handleLnameChange = this.handleLnameChange.bind(this);
    this.handlePhoneChange = this.handlePhoneChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleCountyChange = this.handleCountyChange.bind(this);
    this.handleTownChange = this.handleTownChange.bind(this);
    this.handleTownlandChange = this.handleTownlandChange.bind(this);
    this.handleAddressLine2Change = this.handleAddressLine2Change.bind(this);
    this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
    this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
    this.handleSurveyMethodChange = this.handleSurveyMethodChange.bind(this);
    this.handleGhIdChange = this.handleGhIdChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.doUpload = this.doUpload.bind(this);
    this.setAppState = this.props.setAppState.bind(this);
    this.trans = Translation.getInstance().translate;
  }
  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleConfirmChange(event) {
    this.setState({ cpassword: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handleFnameChange(event) {
    this.setState({ fname: event.target.value });
  }
  handleLnameChange(event) {
    this.setState({ lname: event.target.value });
  }
  handlePhoneChange(event) {
    this.setState({ phone: event.target.value });
  }
  handleCountryChange(event) {
    this.setState({ country: event.target.value });
  }
  handleCountyChange(event) {
    this.setState({ county: event.target.value });
  }
  handleTownChange(event) {
    this.setState({ town: event.target.value });
  }
  handleTownlandChange(event) {
    this.setState({ townland: event.target.value });
  }
  handleAddressLine2Change(event) {
    this.setState({ addressline2: event.target.value });
  }
  handlePostcodeChange(event) {
    this.setState({ postcode: event.target.value });
  }
  handleAccountTypeChange(event) {
    this.setState({ account_type: event.target.value });
  }
  handleSurveyMethodChange(event) {
    this.setState({ survey_method: event.target.value });
  }
  handleGhIdChange(event) {
    this.setState({ ghid: event.target.value });
  }
  setStripeToken(token) {
    this.doUpload(token);
  }
  handleInput() {
    const self = this;
    if (!this.state.username) {
      self.setState({ error: this.trans('input_username')});
      return;
    }
    if (!this.state.password) {
      self.setState({ error: this.trans('input_password') });
      return;
    }
    if (!this.state.cpassword) {
      self.setState({ error: this.trans('confirm_password')});
      return;
    }
    if (this.state.password !== this.state.cpassword) {
      self.setState({ error: this.trans('password_and_confirm_dont_match') });
      return;
    }
    if (!this.state.email) {
      self.setState({ error: this.trans('input_email_address') });
      return;
    }
    if (!EmailValidator.validate(this.state.email)) {
      self.setState({
        error: "'" + this.state.email + this.trans('_is_not_a_valid_email_address')
      });
      return;
    }
    if (
      this.state.account_type === 'farmer' &&
      this.state.survey_method === '1' &&
      !this.state.ghid
    ) {
      self.setState({ error: this.trans('input_grasshopper_id') });
      return;
    }
    if (!this.state.fname) {
      self.setState({ error: this.trans('input_first_name') });
      return;
    }
    if (!this.state.lname) {
      self.setState({ error: this.trans('input_last_name') });
      return;
    }
    if (!this.state.phone) {
      self.setState({ error: this.trans('input_phone_number') });
      return;
    }
    if (!this.state.country) {
      self.setState({ error: this.trans('select_country') });
      return;
    }
    if (!this.state.county) {
      self.setState({ error: this.trans('input_country') });
      return;
    }
    if (!this.state.town) {
      self.setState({ error: this.trans('input_town') });
      return;
    }
    if (this.state.account_type === 'advisor'|| this.state.account_type === 'contractor') {
      self.setState({ error: null, payment: true });
    } else {
      this.doUpload(null);
    }
  }

  doUpload(tok) {
    const self = this;
    self.setState({ sent: true, error: null, payment: false });
    const username = this.state.username;
    const password = this.state.password;
    const email = this.state.email;
    const fname = this.state.fname;
    const lname = this.state.lname;
    const phone = this.state.phone;
    const country = this.state.country;
    const county = this.state.county;
    const town = this.state.town;
    const townland = this.state.townland;
    const addressline2 = this.state.addressline2;
    const postcode = this.state.postcode;
    const account_type = this.state.account_type;
    const survey_method = this.state.survey_method;
    const ghid = this.state.ghid;
    const stripe_token = tok;
    const ac_type = this.state.ac_type;

    const setAppState = this.setAppState;
    axios
      .post('https://staging.grasslandtools.ie/api/signup1/', {
        username: username,
        password: password,
        email: email,
        fname: fname,
        lname: lname,
        phone: phone,
        country: country,
        county: county,
        town: town,
        townland: townland,
        addressline2: addressline2,
        postcode: postcode,
        origin: "IE",
        account_type: account_type,
        survey_method: survey_method,
        ghid: ghid,
        stripe_token: stripe_token,
        advisor_ac_level: ac_type
      })
      .then(function(response) {
        self.setState({ sent: false });
        if (response.data.sessionid) {
          UserController.setSessionkey(response.data.sessionid);
          UserController.setEmail(response.data.email);
          UserController.setWhitelist(response.data.whitelist);
          localStorage.setItem('sessionid', response.data.sessionid);
          self.props.getDataset();
          setAppState({ userLoggedIn: true });
        } else if (response.data.error) {
          self.setState({ error: response.data.error });
        }
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }
  goBack() {
    this.setState({ payment: false });
  }
  setAcType(ac_type) {
    this.setState({ ac_type: ac_type });
  }
  componentDidMount() {
    const self = this;
    axios
      .get('https://staging.grasslandtools.ie/api/surveymethods/')
      .then(function(response) {
        self.setState({ survey_methods: response.data.surveymethods });
      });
  }
  render() {
    const surveymethods = this.state.survey_methods.map(function(method, i) {
      return (
        <option key={i} value={method.id}>
          {method.name}
        </option>
      );
    });
    if (this.state.sent) {
      return (
        <div
          className="Login"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{this.trans('creating_an_account')}...</div>
        </div>
      );
    }
    if (this.state.payment) {
      return (
        <Stripe
          tier={this.state.account_type}
          grasshopper={this.state.survey_method === '1'}
          setStripeToken={this.setStripeToken.bind(this)}
          goBack={this.goBack.bind(this)}
          setAcType={this.setAcType.bind(this)}
        />
      );
    }
    const button =
      this.state.account_type === 'farmer' ? (
        <div
          className="Button"
          id="button"
          value="Submit"
          onClick={this.handleInput}
        >
          {this.trans('signup')}
        </div>
      ) : (
        <div
          className="Button"
          id="button"
          value="Submit"
          onClick={this.handleInput}
        >
          {this.trans('next')}
        </div>
      );
    const countryOptions = [<option key={0} value="" />].concat(
      countries.countries.map((c, i) => {
        return (
          <option
            key={i + 1}
            value={c.code}
            disabled={c.code === '' ? true : null}
          >
            {c.name}
          </option>
        );
      })
    );
    return (
      <div className="Login">
        <form>
          <h3>{this.trans('account_create_details')}</h3>
          <p
            style={{
              color: 'red'
            }}
          >
            {this.state.error}
          </p>
          <label>{this.trans('username')}</label>
          <input
            className="FormInput"
            type="text"
            required
            id="username"
            value={this.state.username}
            onChange={this.handleUsernameChange}
            name="username"
            placeholder={this.trans('username')}
          />
          <p />
          <label>{this.trans('password')}</label>
          <input
            className="FormInput"
            type="password"
            required
            value={this.state.password}
            onChange={this.handlePasswordChange}
            id="password"
            name="password"
            placeholder={this.trans('password')}
          />
          <p />
          <label>{this.trans('password_confirm')}</label>
          <input
            className="FormInput"
            type="password"
            required
            value={this.state.cpassword}
            onChange={this.handleConfirmChange}
            id="cpassword"
            name="cpassword"
            placeholder={this.trans('password_confirm')}
          />
          <p />
          <label>{this.trans('email')}</label>
          <input
            className="FormInput"
            type="email"
            required
            value={this.state.email}
            onChange={this.handleEmailChange}
            id="email"
            name="email"
            placeholder={this.trans('email')}
          />
          <p />
          <label>{this.trans('account_type')}</label>
          <select
            className="FormInput"
            value={this.state.account_type}
            onChange={this.handleAccountTypeChange}
          >
            <option value="farmer">{this.trans('farmer')}</option>
            <option value="advisor">{this.trans('advisor')}</option>
            <option value="contractor">{this.trans('contractor')}</option>
          </select>
          <p />
          <div
            style={
              this.state.account_type === 'farmer'
                ? null
                : {
                    display: 'none'
                  }
            }
          >
            <label>{this.trans('survey_method')}</label>
            <select
              className="FormInput"
              value={this.state.survey_method}
              onChange={this.handleSurveyMethodChange}
            >
              {surveymethods}
            </select>
            <p />
          </div>
          <div
            style={
              this.state.account_type === 'farmer' &&
              this.state.survey_method === '1'
                ? null
                : {
                    display: 'none'
                  }
            }
          >
            <label>{this.trans('grasshopper_id') + ' (eg. A2C4)'}</label>
            <input
              className="FormInput"
              type="text"
              value={this.state.ghid}
              onChange={this.handleGhIdChange}
              id="ghid"
              name="ghid"
              placeholder={this.trans('grasshopper_id')}
            />
            <p />
          </div>
          <label>{this.trans('first_name')}</label>
          <input
            className="FormInput"
            type="text"
            value={this.state.fname}
            onChange={this.handleFnameChange}
            id="fname"
            name="fname"
            placeholder={this.trans('first_name')}
          />
          <p />
          <label>{this.trans('last_name')}</label>
          <input
            className="FormInput"
            type="text"
            value={this.state.lname}
            onChange={this.handleLnameChange}
            id="lname"
            name="lname"
            placeholder={this.trans('last_name')}
          />
          <p />
          <label>{this.trans('phone_number')}</label>
          <input
            className="FormInput"
            type="text"
            value={this.state.phone}
            onChange={this.handlePhoneChange}
            id="phone"
            name="phone"
            placeholder={this.trans('phone_number')}
          />
          <p />
          <label>{this.trans('country')}</label>
          <select
            className="FormInput"
            value={this.state.country}
            onChange={this.handleCountryChange}
          >
            {countryOptions}
          </select>
          <p />
          <label>
            {this.trans('addressline1') + ' / ' + this.trans('townland')}
          </label>
          <input
            className="FormInput"
            type="text"
            value={this.state.townland}
            onChange={this.handleTownlandChange}
            id="townl"
            name="townl"
            placeholder={this.trans('addressline1')}
          />
          <p />
          <label>{this.trans('addressline2')}</label>
          <input
            className="FormInput"
            type="text"
            value={this.state.addressline2}
            onChange={this.handleAddressLine2Change}
            id="townl"
            name="townl"
            placeholder={this.trans('addressline2')}
          />
          <p />
          <label>{this.trans('town')}</label>
          <input
            className="FormInput"
            type="text"
            value={this.state.town}
            onChange={this.handleTownChange}
            id="town"
            name="town"
            placeholder={this.trans('town')}
          />
          <p />
          <label>{this.trans('county')}</label>
          <input
            className="FormInput"
            type="text"
            value={this.state.county}
            onChange={this.handleCountyChange}
            id="county"
            name="county"
            placeholder={this.trans('county')}
          />
          <p />
          <label>{this.trans('postcode')}</label>
          <input
            className="FormInput"
            type="text"
            value={this.state.postcode}
            onChange={this.handlePostcodeChange}
            id="postcode"
            name="postcode"
            placeholder={this.trans('postcode')}
          />
          <p
            style={{
              color: 'red'
            }}
          >
            {this.state.error}
          </p>
          {button}
        </form>
        <p />
      </div>
    );
  }
}

export default Signup;
