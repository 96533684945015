import React, { Component } from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import CardForm from './CardForm';
import Translation from './Translation';
import UserController from './UserController';
import axios from 'axios';
import Loading from './Loading';

class Stripe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sent: false
    };
    this.trans = Translation.getInstance().translate;
  }
  UpdateDetails(token) {
    const self = this;
    self.setState({ sent: true });
    axios
      .post('https://staging.grasslandtools.ie/api/updatepayment/', {
        sessionid: UserController.getSessionkey(),
        stripe_token: token
      })
      .then(function(response) {
        if (response.data.error) {
          self.setState({
            sent: false,
            error: self.trans(response.data.error)
          });
          return;
        }
        self.setState({ sent: false });
        self.props.getDataset(null, 'Overview', null);
        self.props.setAppState({ display: 'farm_selector' });
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }
  render() {
    if (this.state.sent) {
      return (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 100px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <div className="LoginContainer">
            <div className="Login">
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <Loading text={'updating_your_details'} />
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <div className="LoginContainer">
          <div className="Login">
            <h3>{this.trans('payment_details')}</h3>
            <StripeProvider apiKey="pk_live_0phW0M0AKGLwkrlCK0aRZQZI">
              <Elements>
                <CardForm
                  setStripeToken={this.UpdateDetails.bind(this)}
                  btn_text={this.trans('update_details')}
                />
              </Elements>
            </StripeProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default Stripe;
