import React, { Component } from 'react';
import InputAnimals from './InputAnimals';
import Loading from './Loading';
import moment from 'moment-with-locales-es6';
import Translation from './Translation';
import CreateHerd from './CreateHerd';
import WRGettingStarted from './WRGettingStarted';

moment.locale(Translation.getInstance().langCode());

class HerdSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: null,
      herd_name: null,
      herd_id: null,
      animals: null,
    };
    this.trans = Translation.getInstance().translate;
    this.loadingMessage = this.loadingMessage.bind(this);
    this.herdCreated = this.herdCreated.bind(this);
    this.animalsAdded = this.animalsAdded.bind(this);
  }

  loadingMessage(message) {
    this.setState({loading: message});
  }
  herdCreated(herd_name, herd_id) {
    this.setState({loading: null, herd_name: herd_name, herd_id: herd_id});
  }
  animalsAdded(animals) {
    this.setState({loading: null, animals: animals});
  }

  render() {
    if (this.state.loading) {
      return <Loading text={this.state.loading} />;
    }
    if (!this.state.herd_id) {
      return <CreateHerd herdCreated={this.herdCreated} loadingMessage={this.loadingMessage}/>
    }
    if (!this.state.animals) {
      // TODO dont import invalid rows, maybe zero pad shorter tag numbers if jumbo onplay, remove spaces
      return <InputAnimals herd_id={this.state.herd_id} animalsAdded={this.animalsAdded} loadingMessage={this.loadingMessage}/>
    }
    return <WRGettingStarted herd_name={this.state.herd_name} herd_id={this.state.herd_id} />
  }
}

export default HerdSetup;
