import React, { Component } from 'react';
import Translation from './Translation';

import moment from 'moment-with-locales-es6';
import axios from 'axios';
import Drop from './img/Drop.svg';
import './Dashboard.css';
import UserController from './UserController';
import Spinner from './img/Spinner.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
class PaddockReseed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      entering: false,
      gotData: false,
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }
  setEntering(value) {
    this.setState({entering: value})
  }

  render() {
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('reseeding')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "10px 20px 10px"}} className="MetricsPanel">
          <div className="ToggleContainer">
            <div 
              onClick={this.setEntering.bind(this, false)} 
              className={this.state.entering ? "Toggle" : "Toggle Active"}>
                {this.trans('view_reseed_events')}
            </div>
            <div 
              onClick={this.setEntering.bind(this, true)}
              className={this.state.entering ? "Toggle Active" : "Toggle"}>
                {this.trans('input_reseed_events')}
            </div>
          </div>
          {this.props.paddock ? this.state.entering ? <ReseedInput
            cultivars={this.props.cultivars}
            setEntering={this.setEntering.bind(this)}
            paddock={this.props.paddock}
          /> : <ReseedList
            paddock={this.props.paddock}
          /> : <p>{this.trans('select_a_paddock_on_the_farm_map_or_grass_wedge')}</p>}
          
        </div>}
      </div>
    );
  }
}

export default PaddockReseed;


class ReseedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: null,
      reseed_events: [],
    };
    this.trans = Translation.getInstance().translate;
    this.fetchReseedEvents = this.fetchReseedEvents.bind(this)
  }

  componentDidMount(){
    this.fetchReseedEvents(this.props.paddock.id)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.paddock.id !== this.props.paddock.id) {
      this.fetchReseedEvents(nextProps.paddock.id)
    }
    return true
  }

  fetchReseedEvents(padid) {
    this.setState({
      loaded: false,
      error: null,
      reseed_events: [],
    })
    const self = this;
    axios.post("https://staging.grasslandtools.ie/api/listreseedevents/", {
      sessionid: UserController.getSessionkey(),
      paddock: padid,
    }).then(function(response) {
      self.setState({
        loaded: true,
        error: null,
        reseed_events: response.data.reseed_events,
      })
    }).catch(function(error) {
      self.setState({
        loaded: true,
        error: this.trans('error_fetching_reseed_events_for_') + self.props.paddock.name,
        reseed_events: [],
      })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>{this.trans('loading___')}</p>
    }
    if (this.state.error) {
      return <p>{this.state.error}</p>
    }
    if (this.state.reseed_events.length == 0) {
      return <p>{this.trans('no_reseed_events_recorded_for_')} {this.props.paddock.name}</p>
    }
    return <div style={{padding: "10px"}}>
      <table className="SelectedTable">
        <thead>
          <tr>
            <th>{this.trans('date')}</th>
            <th>{this.trans('paddocks')}</th>
            <th>{this.trans('cultivars')}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.reseed_events.map((d, i)=><tr key={i}>
            <td>{d.date}</td>
            <td>{d.paddocks.join(", ")}</td>
            <td>{d.cultivars.map((c)=>c.cultivar + " (" + c.amount + " kg)").join(", ")}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  }
}


class ReseedInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cultivars: [
        {
          cultivar: 0,
          amount: 0
        }
      ],
      date: moment(),
      method: 1,
      sent: false
    };
    this.trans = Translation.getInstance().translate;
  }
  handleDateChange(d) {
    this.setState({ date: d });
  }

  handleCultivarChange(i, event) {
    let cultivars = this.state.cultivars.slice();
    cultivars[i].cultivar = event.target.value;
    let blankCount = 0;
    cultivars = cultivars.filter((c,i) => c.cultivar != 0 || i == cultivars.length-1)
    for (let c of cultivars) {
      if (c.cultivar == 0) {
        blankCount ++;
      }
    }
    if (blankCount == 0) {
      cultivars.push({ cultivar: 0, amount: 0 });
    }
    this.setState({ cultivars: cultivars });
  }

  handleAmountChange(i, event) {
    const cultivars = this.state.cultivars.slice();
    cultivars[i].amount = event.target.value;
    this.setState({ cultivars: cultivars });
  }

  handleMethodChange(event) {
    this.setState({ method: event.target.value });
  }

  submit() {
    if (!this.props.paddock) {
      alert(this.trans('no_paddock_selected_submit'));
      return;
    }
    if (!this.state.date) {
      alert(this.trans('valid_date'));
      return;
    }
    const self = this;
    const paddock = this.props.paddock;
    this.setState({ sent: true });
    axios
      .post('https://staging.grasslandtools.ie/api/insertreseedevent/', {
        sessionid: UserController.getSessionkey(),
        date: this.state.date.format('YYYY-MM-DD'),
        paddocks: paddock.id.toString(),
        cultivars: this.state.cultivars,
        method: this.state.method
      })
      .then(function(response) {
        self.props.setEntering(false);
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }
  render() {
    if (this.state.sent) {
      return (
        <div
          className="InputDiv"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{this.trans('adding_reseed_event')}...</div>
        </div>
      );
    }

    const self = this;
    const cultivar_list = this.props.cultivars.map(function(c) {
      return (
        <option key={c.id} value={c.id}>
          {c.name}
        </option>
      );
    });

    const cult_selectors = this.state.cultivars.map(function(c, i) {
      return (
        <div key={i} className="Metric">
          <div className="fg-split" style={{width: "100%"}}>
            <div className="fg-l">
              <label>
                {"Cultivar " + (i+1)}:
              </label>
              <select
                value={c.cultivar}
                onChange={self.handleCultivarChange.bind(self, i)}
                className="FormInput"
                name="cultivar"
              >
                <option /> {cultivar_list}
              </select>
            </div>
            <div className="fg-s">
              <label>
                kgs:
              </label>
            <input
              value={c.amount}
              onChange={self.handleAmountChange.bind(self, i)}
              className="FormInput"
              type="number"
              name="amount"
            />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className="MetricGroup">

          <div className="Metric">
            <label>
              {this.trans('date')}:
            </label>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              onChange={this.handleDateChange.bind(this)}
              selected={this.state.date}
              className="FormInput"
              placeholderText={this.trans('date')}
              style={{
                zIndex: '5'
              }}
            />
          </div>
          <div className="Metric">
            <label>
            {this.trans('method')}:
            </label>
            
            <select
              value={this.state.method}
              onChange={this.handleMethodChange.bind(this)}
              className="FormInput"
              name="method"
            >
              <option value="1">{this.trans('plough')}</option>
              <option value="2">{this.trans('disc')}</option>
              <option value="3">{this.trans('one_pass')}</option>
              <option value="4">{this.trans('direct_drill')}</option>
            </select>
          </div>
          {cult_selectors}
          <div className="Metric">
            <label style={{opacity: 0}}>
            {this.trans('submit')}
            </label>
            <div className="Button Action" onClick={this.submit.bind(this)}>
              {this.trans('submit')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
