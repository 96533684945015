import React, { Component } from 'react';
import { min, max } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { scaleLinear, scaleTime } from 'd3-scale';
import { select } from 'd3-selection';
import { line, curveMonotoneX } from 'd3-shape';
import { timeFormat, timeFormatDefaultLocale, timeParse } from 'd3-time-format';
import moment from 'moment-with-locales-es6';

import Translation from './Translation';
import './Dashboard.css';

moment.locale(Translation.getInstance().langCode());

class HistorySVG extends Component {
  constructor(props) {
    super(props);
    this.createPaddockHistory = this.createPaddockHistory.bind(this);

    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
  }

  componentDidMount() {
    const chart = document.getElementById('PaddockHistory');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockHistory();
    window.addEventListener('resize', this.createPaddockHistory);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createPaddockHistory);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const chart = document.getElementById('PaddockHistory');
    if (this.w !== chart.clientWidth || this.h !== chart.clientHeight) {
      return true;
    }
    if (this.props.paddock !== nextProps.paddock) {
      return true;
    }
    if (this.props.display !== nextProps.display) {
      return true;
    }
    if (this.props.survey !== nextProps.survey) {
      return true;
    }
    return true;
  }

  componentDidUpdate() {
    const chart = document.getElementById('PaddockHistory');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createPaddockHistory();
  }

  createPaddockHistory() {
    // Remove old tooltip
    select('.tooltip').remove();
    const self = this;
    const setPaddock = this.props.setPaddock;
    const chart = document.getElementById('PaddockHistory');
    const w = chart.clientWidth;
    const h = chart.clientHeight;

    const paddock = this.props.paddock;
    const disp_dates = this.props.disp_dates;
    let disp_date = disp_dates[0];
    for (let d of disp_dates) {
      if (d.isAfter(disp_date)) {
        disp_date = d;
      }
    }

    let survey;
    if (this.props.survey) {
      survey = this.props.survey.data;
    } else {
      survey = null;
    }

    document.getElementById('PaddockHistorySVG').innerHTML = '';

    const svg = select('#PaddockHistorySVG')
      .append('svg')
      .attr('width', w)
      .attr('height', h);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', w)
      .attr('height', h)
      .attr('fill', '#ffffff00')
      .on('click', function() {
        return setPaddock(paddock, null);
      });

    const margin = {
      top: 35,
      right: 15,
      bottom: 30,
      left: 45
    };
    // TODO
    const lang = {};
    if (lang.lang_code === 'fr') {
      timeFormatDefaultLocale({
        decimal: '.',
        thousands: ',',
        grouping: [3],
        periods: ['AM', 'PM'],
        days: [
          'Diamanche',
          'Lundi',
          'Mardi',
          'Mercredi',
          'Jeudi',
          'Venredi',
          'Samedi'
        ],
        shortDays: ['Dia', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        months: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre'
        ],
        shortMonths: [
          'Jan',
          'Fév',
          'Mar',
          'Avr',
          'Mai',
          'Juin',
          'Jul',
          'Août',
          'Sep',
          'Oct',
          'Nov',
          'Déc'
        ]
      });
    } else {
      timeFormatDefaultLocale({
        decimal: '.',
        thousands: ',',
        grouping: [3],
        periods: ['AM', 'PM'],
        days: [
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday'
        ],
        shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        shortMonths: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'June',
          'Jul',
          'Aug',
          'Sep',
          'Oct',
          'Nov',
          'Dec'
        ]
      });
    }

    const covers = this.props.farm.covers;

    let dataset = [];
    for (let i = 0; i < covers.length; i++) {
      const cover = covers[i];
      const padCovers = cover.padCovers
        .slice()
        .sort((a, b) => a.survey_id - b.survey_id);
      for (let j = 0; j < padCovers.length; j++) {
        const survey = cover.padCovers[j];
        if (survey.paddock_id === paddock.id) {

          const cover_ha =
            survey.manualCover !== null && !isNaN(survey.manualCover)
              ? survey.manualCover
              : survey.dmHectare;
          const cover_pd =
            survey.manualCover !== null && !isNaN(survey.manualCover)
              ? survey.manualCover * paddock.area / 10000
              : survey.dmPaddock;
          const s = {
            date: cover.date,
            cover_ha: cover_ha,
            cover_pd: cover_pd,
            survey: survey
          };
          dataset.push(s);
        }
      }
    }

    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    if (dataset.length === 0) {
      svg
        .append('text')
        .text(this.trans('no_surveys_found_paddock'))
        .attr('class', 'svgMessage')
        .attr('x', w / 2)
        .attr('y', margin.top + height / 2);

      svg
        .append('text')
        .text(this.trans('paddock_history'))
        .attr('x', margin.left + width / 2)
        .attr('y', margin.top - 5)
        .attr('class', 'ChartHeading')
        .attr('text-anchor', 'middle');

      return;
    }

    const dParse = timeParse('%Y-%m-%dT%H:%M:%S');

    let dMin = min(dataset, function(s) {
      return moment(s.date);
    });
    let dMax = max(dataset, function(s) {
      return moment(s.date);
    });

    if (dMin.valueOf() === dMax.valueOf()) {
      dMin.date(dMin.date() - 1);
      dMax.date(dMax.date() + 1);
    }

    const xScale = scaleTime()
      .domain([dMin, dMax])
      .rangeRound([0, width])
      .nice();
    const yScale = scaleLinear()
      .domain([
        this.props.coverType=='1' ? 0 : 1000,
        Math.ceil(
          max(dataset, function(s) {
            return s.cover_ha;
          }) / 500
        ) * 500
      ])
      .rangeRound([height, 0]);

    function make_y_gridlines() {
      return axisLeft(yScale).ticks(4);
    }
    let xpos = margin.left;
    let ypos = margin.top;

    // Add Y gridlines
    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(' + xpos + ',' + ypos + ')')
      .call(
        make_y_gridlines()
          .tickSize(-width)
          .tickFormat('')
      );
    svg
      .append('line')
      .attr('x1', margin.left + xScale(disp_date))
      .attr('y1', margin.top)
      .attr('x2', margin.left + xScale(disp_date))
      .attr('y2', margin.top + height)
      .attr('stroke', '#51534a')
      .attr('stroke-width', '3px')
      .style('pointer-events', 'none');

    const HistoryLine = line()
      .x(function(d) {
        return xScale(moment(d.date));
      })
      .y(function(d) {
        return yScale(Math.max(d.cover_ha, 0));
      })
      .curve(curveMonotoneX);
    svg
      .append('path')
      .attr('d', HistoryLine(dataset))
      .attr('class', 'HistoryLine')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    svg
      .selectAll('dot')
      .data(dataset)
      .enter()
      .append('circle')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .attr('r', 5 + 'px')
      .attr('cx', function(d) {
        return xScale(moment(d.date));
      })
      .attr('cy', function(d) {
        return yScale(Math.max(d.cover_ha, 0));
      })
      .attr('class', function(d) {
        if (d.survey === survey) {
          return 'Point active';
        } else {
          return 'Point';
        }
      })
      .on('mouseover', function(d) {
        // Remove old tooltip
        select('.tooltip').remove();
        const date = moment(d.date); //.locale([lang.lang_code, 'en']);
        // Show tooltip with transition
        const tooltip = select('#PaddockHistory')
          .append('div')
          .attr('class', 'tooltip')
          .style('left', margin.left + 
          document.getElementById('FarmMap').clientWidth + 3 + xScale(dParse(d.date)) - 100 + 'px')
          .style(
            'top',
            Math.min(
              margin.top + yScale(d.cover_ha) - 100,
              height + margin.top - 100
            ) + 'px'
          )
          .style('width', '200px')
          .html(
            self.trans('paddock_cover') +
              ': ' +
              Math.round(d.cover_pd) +
              ' kg<br/>' +
              self.trans('hectare_cover') +
              ': ' +
              Math.round(d.cover_ha) +
              ' kg/ha<br/>' +
              self.trans('average_height') +
              ': ' +
              d.survey.avgHeight +
              ' mm<br/>' +
              self.trans('measured') +
              ': ' +
              date.format('L')
          );
        tooltip
          .transition()
          .duration(250)
          .style('opacity', 1);
      })
      .on('mouseout', function() {
        // Fade out tooltip
        select('.tooltip')
          .transition()
          .duration(250)
          .style('opacity', 0)
          .remove();
      })
      .on('click', function(d) {
        self.props.setDate(moment(d.date), false);
        setPaddock(paddock, d.survey);
      })
      .style('cursor', 'pointer');

    ypos = margin.top;
    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr('transform', 'translate(' + xpos + ',' + ypos + ')')
      .call(axisLeft(yScale).ticks(4));

    ypos = h - margin.bottom;
    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr('transform', 'translate(' + xpos + ',' + ypos + ')')
      .call(axisBottom(xScale).tickFormat(timeFormat('%b')));

    // Add heading
    svg
      .append('text')
      .text(this.trans('paddock_history'))
      .attr('x', margin.left + width / 2)
      .attr('y', margin.top - 5)
      .attr('class', 'ChartHeading')
      .attr('text-anchor', 'middle');

  }

  render() {
    return (
      <div
        id="PaddockHistorySVG"
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    );
  }
}

class PaddockHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover_toggled: false
    };
  }

  togglePopover() {
    this.setState({
      popover_toggled: !this.state.popover_toggled
    });
  }

  render() {
    return (
      <div id="PaddockHistory" className={this.props.className}>
        <HistorySVG
          farm={this.props.farm}
          setPaddock={(d, s) => this.props.setPaddock(d, s, 'paddock_history')}
          display={this.props.display}
          disp_dates={this.props.disp_dates}
          setDate={this.props.setDate}
          paddock={this.props.paddock}
          survey={this.props.survey}
          coverType={this.props.coverType}
        />
      </div>
    );
  }
}

export default PaddockHistory;
