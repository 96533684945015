import React, { Component } from 'react';
import './Login.css';
import Login from './Login';
import Signup from './Signup';
// import logo from './img/GrasslandToolsTNBlue.png';
import logo from './img/GrasslandToolsLogoNewGG.png';
// import logo from './img/IDELE INSIDE.png';
import Flags from './Flags';
import moment from 'moment-with-locales-es6';

import './Header.css';
import Translation from './Translation';
class LoginController extends Component {
  componentWillMount() {
    this.props.setDisplay('login');
  }
  render() {
    const trans = Translation.getInstance().translate;
    const display = this.props.display;
    let form;
    if (display === 'login') {
      form = (
        <Login
          setAppState={this.props.setAppState}
          getDataset={this.props.getDataset}
        />
      );
    } else if (display === 'signup') {
      form = (
        <Signup
          setAppState={this.props.setAppState}
          getDataset={this.props.getDataset}
        />
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100%'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center'
          }}
        >
          <img src={logo} alt="GrasslandTools" className="LoginLogoLarge" />
          <div className="LoginWrapper">
            <div className="LoginNav">
              <div
                className={
                  this.props.display === 'login'
                    ? 'LoginButton Active'
                    : 'LoginButton Left'
                }
                onClick={this.props.setDisplay.bind(this, 'login')}
              >
                {trans('login')}
              </div>
              <div
                className={
                  this.props.display === 'signup'
                    ? 'LoginButton Active'
                    : 'LoginButton Right'
                }
                onClick={this.props.setDisplay.bind(this, 'signup')}
              >
                {trans('signup')}
              </div>
            </div>
            <div className="LoginContainer">{form}</div>
          </div>
        </div>
        <div className="Footer">
          <span
            style={{
              paddingLeft: '10px'
            }}
          >
            {trans('copyright') + ' © 2017 - ' + moment().format('YYYY')} True North
            Technologies Ltd.
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              float: 'right'
            }}
          >
            <Flags incrementKey={this.props.incrementKey} />{' '}
            {this.props.userLoggedIn ? (
              <div
                style={{
                  cursor: 'pointer'
                }}
                className="Logout"
                onClick={this.props.logout.bind(this)}
              >
                {trans('logout')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default LoginController;
