import React, { Component } from 'react';

import axios from 'axios';
import UserController from './UserController';
import Translation from './Translation';
import moment from 'moment';

class InputAnimals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input_string: "",
      animal_info_array: [],
      col_count: 0,
      col_values: [],
    };
    this.trans = Translation.getInstance().translate;
    this.fieldOptions  =["", "tag_no", "brd", "sex", "dob"];
  }

  handleInputStringChange(event) {
    this.setState({input_string: event.target.value})
  }
  readString() {
    const arr = [];
    for (let line of this.state.input_string.split("\n")) {
      const line_arr = [];
      for (let field of line.split("\t")) {
        line_arr.push(field)
      }
      if (line_arr.length>1){
        arr.push(line_arr)
      }
    }
    
    let col_count = 0;
    for (let row of arr) {
      if (row.length > col_count) {
        col_count = row.length;
      }
    }
    this.setState({animal_info_array: arr, col_count: col_count, col_values: [...Array(col_count).keys()].map(i=>"")});
  }

  icbfImport() {
    localStorage.setItem("icbfLinkHerdID", this.props.herd_id);
    window.location = "https://api.icbf.com/oauth/authorize?response_type=code&client_id=true_north_client&redirect_uri=https://staging.grasslandtools.ie/api/v2/icbf/authentication/&state=1";
  }
  
  handleColValue(i, event) {
    let col_values = this.state.col_values.slice()
    col_values[i] = event.target.value
    this.setState({
      col_values: col_values
    })
  }

  import() {
    this.props.loadingMessage('adding_animals');
    const output = []
    for (let a of this.state.animal_info_array) {
      const animal = {}
      for (let field of this.fieldOptions.slice(1)) {
        const index = this.state.col_values.indexOf(field)
        if (index >= 0) {
          if (field==="tag_no") {
            animal[field] = a[index].split(".")[0]
          }
          else if (field==="dob") {
            var parsedDate = moment(a[index], "DD/MM/YYYY")
            if (parsedDate.isValid()) {
              animal[field] = parsedDate.format("YYYY-MM-DD")
            } else {
              animal[field] = null
            }
          } else {
            animal[field] = a[index]
          }
        }
      }
      if (animal["tag_no"]) {
        output.push(animal)
      }
    }
    const self = this;
    axios
      .post('https://staging.grasslandtools.ie/api/v2/addanimals/', {
        sessionid: UserController.getSessionkey(),
        herd_id: this.props.herd_id,
        animals: JSON.stringify(output),
      })
      .then(response=>{
        self.props.animalsAdded(output);
      })
  }

  render() {
    if (this.state.animal_info_array.length <= 0) {
      return (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 65px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <div className="LoginContainer">
            <div className="Login">
              <form>
              <h3>{this.trans('create_herd')}</h3>
                <hr
                  style={{
                    marginBottom: '15px'
                  }}
                />
                <h3>{this.trans('auto_import_from_icbf')}</h3>
                <div
                  className="Button"
                  id="button"
                  value="Submit"
                  onClick={this.icbfImport.bind(this)}
                >
                  {this.trans('pull_from_icbf')}
                </div>
                <hr
                  style={{
                    marginBottom: '15px'
                  }}
                />
                <h3>{this.trans('manual_import')}</h3>
                <ol>
                <li>{this.trans('open_up_your_herd_profile_in_a_spreadsheet')}</li>
                <li>{this.trans('select_all_the_rows_containing_animal_information')}</li>
                <li>{this.trans('right_click_and_copy_on_the_spreadsheet')}</li>
                <li>{this.trans('finally_click_read_string_below')}</li>
                </ol>
                <textarea
                  className="FormInput"
                  type="text"
                  value={this.state.input_string}
                  onChange={this.handleInputStringChange.bind(this)}
                  placeholder={this.trans('right_click_and_paste_here')}
                />
                <p />
                <div
                  className="Button"
                  id="button"
                  value="Submit"
                  onClick={this.readString.bind(this)}
                >
                  {this.trans('read_string')}
                </div>
              </form>
              <p />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 65px)',
        }}
      >
        <div>
        <h3>{this.trans('use_the_dropdown_boxes_at_the_top_of_each_column')}</h3>
        <h3>{this.trans('when_you_are_done_click_import_below')}</h3>
        <div
          className="Button"
          id="button"
          value="Submit"
          onClick={this.import.bind(this)}
        >
          {this.trans('import')}
        </div>
        </div>
        <div style={{
          width: '100%',
          height: 'calc(100vh - 65px - 60px - 76px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          overflow: 'scroll'
        }}>
          <table>
            <thead>
              <tr>
              {this.state.col_values.map((v, i) => {
                return (
                  <th key={i}>
                    <select
                      className="FormInput"
                      value={v}
                      onChange={this.handleColValue.bind(this, i)}
                    >
                      {this.fieldOptions.map((s, j) => {
                        return (
                          <option key={j} value={s}>
                            {this.trans(s)}
                          </option>
                        )
                      })}
                    </select>
                  </th>
                )
              })}
              </tr>
            </thead>
            <tbody>
              {this.state.animal_info_array.map((animal, i) => {
                return (
                  <tr key={i}>
                    {animal.map((field, j) => {
                      return (
                        <td key={j}>{field}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default InputAnimals;
