import React, { Component } from 'react';

import moment from 'moment-with-locales-es6';

import './Calendar.css';
import Translation from './Translation';

const trans = Translation.getInstance().translate;

moment.locale(Translation.getInstance().langCode());
class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: moment(this.props.dispDates[0]),
      multipleDates: false
    };
  }

  toggleMultipleDates() {
    this.setState({
      multipleDates: !this.state.multipleDates
    });
  }

  newWeek() {
    let w = [];
    for (let i = 0; i < 7; i++) {
      w.push({ day: '', survey: false });
    }
    return w;
  }

  decrementMonth() {
    this.setState({
      month: this.state.month.subtract(1, 'month')
    });
  }

  incrementMonth() {
    this.setState({
      month: this.state.month.add(1, 'month')
    });
  }

  render() {
    let week = this.newWeek();
    let weeks = [];
    let m = this.state.month;

    // Array of dates with surveys for current month
    let surveyDates = this.props.surveyDates
      .slice()
      .filter(date => moment(date).isSame(m, 'Month'))
      .map(date => moment(date))
      .sort((a, b) => a.diff(b, 'days'));
    let c = 0; // Counter to iterate through surveyDates
    const dispDates = this.props.dispDates; // Date displayed on wedge/map
    const startDay = m.startOf('month').day();
    const endDate = m.endOf('month').date();
    for (let i = 1; i <= endDate; i++) {
      let survey = false;
      // Check if this date is a survey date
      if (c < surveyDates.length && surveyDates[c].date() === i) {
        survey = true;
        c++;
      }
      // Current day of week
      let rem = (i + startDay - 1) % 7;
      week[rem] = {
        day: i,
        survey: survey
      };
      // If at end of week add to weeks array and reset
      if (rem === 6) {
        weeks.push(week);
        week = this.newWeek();
      } else if (i === endDate) {
        // If at end of month add last week to array
        weeks.push(week);
      }
    }
    let tbody = [];
    for (let i = 0; i < weeks.length; i++) {
      week = weeks[i];
      let trow = [];
      for (let j = 0; j < 7; j++) {
        const d = moment([m.year(), m.month(), week[j].day]);
        let tdClassName = '';
        let divClassName = '';
        let onClick = null;
        if (j === 0 || j === 6) {
          tdClassName = 'CalWeekend';
        }
        let inDispDates = false;
        for (let dispDate of dispDates) {
          if (d.isSame(dispDate, 'day')) {
            tdClassName += ' CalDisp';
            onClick = this.props.setDate.bind(this, d);
            onClick = (event)=>{
              event.stopPropagation();
              if (event.ctrlKey || event.metaKey) {
                this.props.setDate(d, true)
              } else {
                this.props.setDate(d, false)
              }
            }
            inDispDates = true;
          }
        }
        if (week[j].survey && !inDispDates) {
          tdClassName += ' CalSurvey';
          onClick = this.props.setDate.bind(this, d);
          onClick = (event)=>{
            event.stopPropagation();
            if (event.ctrlKey || event.metaKey) {
              this.props.setDate(d, true)
            } else {
              this.props.setDate(d, false)
            }
          }
        }
        trow.push(
          <td key={j} className={tdClassName} onClick={onClick}>
            <div className={divClassName}>{week[j].day}</div>
          </td>
        );
      }
      tbody.push(<tr key={i}>{trow}</tr>);
    }

    return (
      <div id="Calendar" className={this.props.className + ' Calendar'}>
        <div className="CalContainer">
          <div className="CalHeader">
            <div
              className="ChartHeading CalNav"
              onClick={this.decrementMonth.bind(this)}
            >
              {'<<'}
            </div>
            <div className="ChartHeading">{trans(m.format('MMMM'))} {m.format('YYYY')}</div>{' '}
            {/*NOT-Translated , NEEDS REFACTORING!!!!!!!!!!!!*/}
            <div
              className="ChartHeading CalNav"
              onClick={this.incrementMonth.bind(this)}
            >
              {'>>'}
            </div>
          </div>
          <div className="CalBody">
            <table>
              <thead>
                <tr>
                  <th>{trans('day_sunday_short')}</th>
                  <th>{trans('day_monday_short')}</th>
                  <th>{trans('day_tuesday_short')}</th>
                  <th>{trans('day_wednesday_short')}</th>
                  <th>{trans('day_thursday_short')}</th>
                  <th>{trans('day_friday_short')}</th>
                  <th>{trans('day_saturday_short')}</th>
                </tr>
              </thead>
              <tbody>{tbody}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Calendar;
