import React, { Component } from "react";
import moment from "moment-with-locales-es6";
import axios from "axios";
import { StripeProvider, Elements } from "react-stripe-elements";

import Flags from "./Flags";
import Icon from "./img/GrasslandToolsLogoNewGW.png";
import Field from "./img/Field.png";

import Translation from "./Translation";

import "./LandingPage.css";
import CardForm from "./CardForm";
import UserController from "./UserController";

class SellingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      domain: null,
      enabled: ["Grass", "Weighing", "Environment"],
      selected: null,
      action: "Login",
      extra_farms:
        this.props.farmCount > 5 ? (this.props.farmCount - 5).toString() : "",
      extra_herds:
        this.props.herdCount > 5 ? (this.props.herdCount - 5).toString() : "",
      account_type:
        UserController.getUserType() === "Farmer"
          ? ""
          : UserController.getUserType().toLowerCase(),
      package: "",
      period: "",
      error: null,
      payment: false,
      sent: false,
      stripe_token: "FRANCE_DEMO_SITE",
      ac_type: 1,
      survey_methods: []
    };
    this.handleAccountTypeChange = this.handleAccountTypeChange.bind(this);
    this.handlePackageChange = this.handlePackageChange.bind(this);
    this.handlePeriodChange = this.handlePeriodChange.bind(this);
    this.handleExtraFarmsChange = this.handleExtraFarmsChange.bind(this);
    this.handleExtraHerdsChange = this.handleExtraHerdsChange.bind(this);
    this.setAppState = this.props.setAppState.bind(this);
    this.trans = Translation.getInstance().translate;
  }
  logoClick() {
    window.open("https://www.stripe.com");
  }
  setAction(x) {
    this.setState({ action: x, error: "" });
  }
  setStripeToken(x) {
    this.setState({ stripe_token: x });
  }
  handleAccountTypeChange(event) {
    this.setState({ account_type: event.target.value, error: "" });
  }
  handlePackageChange(event) {
    this.setState({ package: event.target.value, error: "" });
  }
  handlePeriodChange(event) {
    this.setState({ period: event.target.value, error: "" });
  }

  handleExtraFarmsChange(event) {
    const regexp = new RegExp(`^[0-9]*$`);
    const newValue = event.target.value;
    if (regexp.test(newValue)) {
      this.setState({ extra_farms: newValue });
    }
  }

  handleExtraHerdsChange(event) {
    const regexp = new RegExp(`^[0-9]*$`);
    const newValue = event.target.value;
    if (regexp.test(newValue)) {
      this.setState({ extra_herds: newValue });
    }
  }

  selectPackage(p) {
    this.setState({ package: p, error: "" });
    window.location = "#Select-Package";
  }

  submit() {
    const self = this;
    this.setState({ error: "" });
    if (!this.state.package) {
      this.setState({ error: this.trans("please_select_a_package_above") });
      return;
    }
    if (!this.state.account_type) {
      this.setState({ error: this.trans("please_select_account_type_above") });
      return;
    }
    this.setState({ loading: true });
    axios
      .post("https://staging.grasslandtools.ie/api/v2/updatepaymentstatusfr/", {
        sessionid: UserController.getSessionkey(),
        package: this.state.package,
        account_type: this.state.account_type,
        extra_farms: this.state.extra_farms
          ? parseInt(this.state.extra_farms)
          : 0,
        extra_herds: this.state.extra_herds
          ? parseInt(this.state.extra_herds)
          : 0,
        stripe_token: this.state.stripe_token
      })
      .then(response => {
        self.setState({ loading: false });
        console.log(response);
        localStorage.setItem("sessionid", response.data.sessionid);
        UserController.setSessionkey(response.data.sessionid);
        self.props.getDataset();
      })
      .catch(error => {
        self.setState({ loading: false });
      });
  }

  render() {
    let packagePrice = 0;
    let includeGrass = false;
    let includeWeighing = false;
    if (this.state.package && this.state.period) {
      if (this.state.package === "grass") {
        packagePrice = 60;
        includeGrass = true;
      } else if (this.state.package === "weighing") {
        packagePrice = 60;
        includeWeighing = true;
      } else if (this.state.package === "all") {
        packagePrice = 100;
        includeGrass = true;
        includeWeighing = true;
      }
      if (this.state.period === "monthly") {
        packagePrice /= 10;
      }
    }

    let extraFarmsPrice = 0;
    if (this.state.extra_farms) {
      if (includeGrass) {
        extraFarmsPrice = parseInt(this.state.extra_farms) * 5;
      } else {
        this.state.extra_farms =
          this.props.farmCount > 5 ? (this.props.farmCount - 5).toString() : "";
      }
    }

    let extraHerdsPrice = 0;
    if (this.state.extra_herds) {
      if (includeWeighing) {
        extraHerdsPrice = parseInt(this.state.extra_herds) * 5;
      } else {
        this.state.extra_herds =
          this.props.herdCount > 5 ? (this.props.herdCount - 5).toString() : "";
      }
    }

    const totalCost = packagePrice + extraFarmsPrice + extraHerdsPrice;

    return (
      <div className="LandingPage">
        <div className="LandingHeader">
          <div className="IconDiv" onClick={this.props.goHome}>
            <img className="Icon" src={Icon} alt="Icon" />
            <div className="LandingPageFlagsContainer">
              <Flags incrementKey={this.props.incrementKey} />
            </div>
          </div>
        </div>
        <div className="LandingPageLoginContainer">
          <div
            style={{
              cursor: "pointer"
            }}
            className="HeaderTab"
            onClick={this.props.logout.bind(this)}
          >
            {this.trans("logout")}
          </div>
        </div>
        <div className="SellingPackagesContainer">
          <div
            id="Grass"
            className="LandingPagePackageThird Grass"
            onClick={this.selectPackage.bind(this, "grass")}
            style={{ cursor: "pointer" }}
          >
            <div className="LandingPagePackageHeader">
              <div>
                <h1>{this.trans("grass")}</h1>
                <h2>{this.trans("grass_management_system")}</h2>
              </div>
              <div>
                <img className="FieldIcon" src={Field} alt="Logo" />
              </div>
            </div>
            <div className="LandingPagePackageBody">
              <ul>
                <li>{this.trans("map_your_farm")}</li>
                <li>{this.trans("grass_wedge")}</li>
                <li>{this.trans("avg_farm_cover")}</li>
                <li>{this.trans("manage_paddocks")}</li>
                <li>{this.trans("weekly_reports")}</li>
                <li>{this.trans("rotation_planning")}</li>
                <li>{this.trans("derogation_compliance")}</li>
              </ul>
              <div className="LandingPagePrice">
                <h1>{this.trans("month_price_1")}</h1>
                <h2>{this.trans("or")}</h2>
                <h1>{this.trans("year_price_1")}</h1>
              </div>
            </div>
          </div>
          <div
            id="Weighing"
            className="LandingPagePackageThird Weighing"
            onClick={this.selectPackage.bind(this, "weighing")}
            style={{ cursor: "pointer" }}
          >
            <div className="LandingPagePackageHeader">
              <div>
                <h1>{this.trans("weighing")}</h1>
                <h2>{this.trans("livestock_weighing_system")}</h2>
              </div>
              <div>
                <img className="FieldIcon" src={Field} alt="Logo" />
              </div>
            </div>
            <div className="LandingPagePackageBody">
              <ul>
                <li>{this.trans("aims_icbf_sync")}</li>
                <li>{this.trans("real_time_results")}</li>
                <li>{this.trans("paperless_recording")}</li>
                <li>{this.trans("avg_daily_gain")}</li>
                <li>{this.trans("body_condition_scoring")}</li>
                <li>{this.trans("drafting_output")}</li>
                <li>{this.trans("eid_input")}</li>
              </ul>
              <div className="LandingPagePrice">
                <h1>{this.trans("month_price_1")}</h1>
                <h2>{this.trans("or")}</h2>
                <h1>{this.trans("year_price_1")}</h1>
              </div>
            </div>
          </div>
          <div id="Environment" className="LandingPagePackageThird Environment">
            <div className="LandingPagePackageHeader">
              <div>
                <h1>{this.trans("environment")}</h1>
                <h2>{this.trans("live_data_from_your_farm")}</h2>
              </div>
              <div>
                <img className="FieldIcon" src={Field} alt="Logo" />
              </div>
            </div>
            <div className="LandingPagePackageBody">
              <ul>
                <li>{this.trans("predict_grass_growth")}</li>
                <li>{this.trans("soil_temperature")}</li>
                <li>{this.trans("soil_moisture")}</li>
                {/* <li>{this.trans("ambient_temperature")}</li> */}
                <li>{this.trans("moisture_deficit_alerts")}</li>
                {/* <li>{this.trans("historical_data_review")}</li> */}
                <li>{this.trans("met_data_integration")}</li>
              </ul>
              <div className="LandingPagePrice">
                <h1>{this.trans("coming_soon")}</h1>
              </div>
            </div>
          </div>
          <div
            id="All"
            className="LandingPagePackageAll"
            onClick={this.selectPackage.bind(this, "all")}
            style={{ cursor: "pointer" }}
          >
            <div className="LandingPagePackageHeader">
              <div>
                <h1>{this.trans("complete_gt_package")}</h1>
                <h2>{this.trans("includes_all_new_modules_for_year")}</h2>
              </div>
              <div>
                <img className="FieldIcon" src={Field} alt="Logo" />
              </div>
            </div>
            <div className="LandingPagePackageBody">
              <div className="LandingPagePrice Light">
                <h1>{this.trans("month_price_2")}</h1>
                <h2>{this.trans("or")}</h2>
                <h1>{this.trans("year_price_2")}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="LandingPageLoginContainer">
          {this.state.loading ? (
            <div className="LandingPageLoginBody" style={{ height: "unset" }}>
              <h1 style={{ color: "white" }}>{this.trans("loading___")}</h1>
            </div>
          ) : (
            <div className="LandingPageLoginBody" style={{ height: "unset" }}>
              <label>{this.trans("choose_package")}</label>
              <select
                id="Select-Package"
                className="FormInput"
                value={this.state.package}
                onChange={this.handlePackageChange}
                style={this.state.package === "" ? { color: "#51534a99" } : {}}
              >
                <option value="">{this.trans("choose_package")}</option>
                <option value="grass">{this.trans("grass")}</option>
                <option value="weighing">{this.trans("weighing")}</option>
                <option value="all">{this.trans("full_package")}</option>
              </select>
              <label>{this.trans("billing_period")}</label>
              <select
                id="Select-Period"
                className="FormInput"
                value={this.state.period}
                onChange={this.handlePeriodChange}
                style={this.state.period === "" ? { color: "#51534a99" } : {}}
              >
                <option value="">{this.trans("billing_period")}</option>
                <option value="monthly">{this.trans("monthly")}</option>
                <option value="yearly">{this.trans("yearly")}</option>
              </select>
              <label className="Price">
                {packagePrice ? "€" + packagePrice.toFixed(2) : ""}
              </label>
              <label
                style={
                  UserController.getUserType() !== "Farmer"
                    ? { display: "none" }
                    : null
                }
              >
                {this.trans("account_type")}
              </label>
              <select
                className="FormInput"
                value={this.state.account_type}
                onChange={this.handleAccountTypeChange}
                style={
                  UserController.getUserType() !== "Farmer"
                    ? { display: "none" }
                    : this.state.account_type === ""
                    ? { color: "#51534a99" }
                    : {}
                }
              >
                <option value="">{this.trans("account_type")}</option>
                <option value="farmer">{this.trans("farmer")}</option>
                <option value="advisor">{this.trans("advisor")}</option>
                <option value="contractor">{this.trans("contractor")}</option>
              </select>
              {/* {includeGrass ? <label>{this.trans("extra_farms")}</label> : null}
              {includeGrass ? (
                <input
                  className="FormInput"
                  type="text"
                  required
                  id="extra_farms"
                  value={this.state.extra_farms}
                  onChange={this.handleExtraFarmsChange}
                  onKeyPress={this.handleKeyPress}
                  name="extra_farms"
                  placeholder={this.trans("extra_farms")}
                />
              ) : null}
              {includeGrass ? (
                <label className="Price">
                  {this.state.extra_farms
                    ? "€" + extraFarmsPrice.toFixed(2)
                    : ""}
                </label>
              ) : null}
              {includeWeighing ? (
                <label>{this.trans("extra_herds")}</label>
              ) : null}
              {includeWeighing ? (
                <input
                  className="FormInput"
                  type="text"
                  required
                  id="extra_herds"
                  value={this.state.extra_herds}
                  onChange={this.handleExtraHerdsChange}
                  onKeyPress={this.handleKeyPress}
                  name="extra_herds"
                  placeholder={this.trans("extra_herds")}
                />
              ) : null}
              {includeWeighing ? (
                <label className="Price">
                  {this.state.extra_herds
                    ? "€" + extraHerdsPrice.toFixed(2)
                    : ""}
                </label>
              ) : null} */}
              {/* <h3>Total Annual Cost:</h3>
              <label className="Price Total">
                {"€" + totalCost.toFixed(2)}
              </label> */}
              {this.state.stripe_token === "FRANCE_DEMO_SITE" ? (
                <div>
                  <h2>Credit card form skipped for demo site</h2>
                  <div
                    className="Button"
                    style={{ width: "unset" }}
                    onClick={this.submit.bind(this)}
                  >
                    {this.trans("join_grasslandtools_now")}
                  </div>
                </div>
              ) : this.state.stripe_token === null ? (
                <StripeProvider apiKey="pk_live_0phW0M0AKGLwkrlCK0aRZQZI">
                  <Elements>
                    <CardForm
                      setStripeToken={this.setStripeToken.bind(this)}
                      stripe_token={this.state.stripe_token}
                      onSubmit={this.submit.bind(this)}
                    />
                  </Elements>
                </StripeProvider>
              ) : (
                <div
                  className="Button"
                  style={{ width: "unset" }}
                  onClick={this.submit.bind(this)}
                >
                  {this.trans("join_grasslandtools_now")}
                </div>
              )}{" "}
              {this.state.error ? (
                <p style={{ gridRow: "unset" }} className="Error">
                  {this.state.error}
                </p>
              ) : null}
            </div>
          )}
        </div>
        <div className="LandingPageFooter">
          <span>
            {this.trans("copyright") + " "} © 2017 - {moment().format("YYYY")}{" "}
            True North Technologies Ltd.
          </span>
        </div>
      </div>
    );
  }
}

export default SellingPage;
