import React, { Component } from 'react';
import { GoogleMap, LoadScript, DrawingManager, Polygon, Polyline, Marker, Circle } from '@react-google-maps/api';
import './Map.css';

import axios from 'axios';
import UserController from './UserController';
import Translation from './Translation';

import Drop from './img/Drop.svg';
import Edit from './img/Edit.svg';
import Delete from './img/Delete.svg';
import Done from './img/Done.svg';
import moment from 'moment-with-locales-es6';
import { v4 as uuidv4 } from 'uuid';

const libraries = ["drawing"]
class PaddockTable extends Component {
  render() {
    const self = this;
    const trans = Translation.getInstance().translate;
    const pRows = this.props.paddockArray.map(function(p, i) {
      return (
        <tr
          className={p.uuid === self.props.selected ? 'active' : null}
          key={p.name}
          onClick={self.props.select.bind(self, p.uuid)}
        >
          <td>{p.name}</td>
          <td>{p.lastSurvey ? moment(p.lastSurvey).format('MMM Do YYYY') : "~"}</td>
          <td>{Math.round(p.area / 100) / 100}</td>
        </tr>
      );
    });
    const bRows = this.props.buildingArray.map(function(p, i) {
      return (
        <tr
          className={p.uuid === self.props.selected ? 'active' : null}
          key={p.name}
          onClick={self.props.select.bind(self, p.uuid)}
        >
          <td>{p.name}</td>
          <td />
          <td>{Math.round(p.area / 100) / 100}</td>
        </tr>
      );
    });
    const yRows = this.props.yardArray.map(function(p, i) {
      return (
        <tr
          className={p.uuid === self.props.selected ? 'active' : null}
          key={p.name}
          onClick={self.props.select.bind(self, p.uuid)}
        >
          <td>{p.name}</td>
          <td />
          <td>{Math.round(p.area / 100) / 100}</td>
        </tr>
      );
    });
    const rRows = this.props.roadArray.map(function(p, i) {
      return (
        <tr
          className={p.uuid === self.props.selected ? 'active' : null}
          key={p.name}
          onClick={self.props.select.bind(self, p.uuid)}
        >
          <td>{p.name}</td>
          <td />
          <td>{"~"}</td>
        </tr>
      );
    });
    const fRows = this.props.forestArray.map(function(p, i) {
      return (
        <tr
          className={p.uuid === self.props.selected ? 'active' : null}
          key={p.name}
          onClick={self.props.select.bind(self, p.uuid)}
        >
          <td>{p.name}</td>
          <td />
          <td>{Math.round(p.area / 100) / 100}</td>
        </tr>
      );
    });
    let pArea = 0;
    for (let i = 0; i < this.props.paddockArray.length; i++) {
      pArea += Math.round(this.props.paddockArray[i].area / 100) / 100;
    }
    let bArea = 0;
    for (let i = 0; i < this.props.buildingArray.length; i++) {
      bArea += Math.round(this.props.buildingArray[i].area / 100) / 100;
    }
    let yArea = 0;
    for (let i = 0; i < this.props.yardArray.length; i++) {
      yArea += Math.round(this.props.yardArray[i].area / 100) / 100;
    }
    let fArea = 0;
    for (let i = 0; i < this.props.forestArray.length; i++) {
      fArea += Math.round(this.props.forestArray[i].area / 100) / 100;
    }
    return (
      <div
        id="info"
        style={{
          width: '300px',
          height: 'calc(100vh - 65px)'
        }}
      >
        <div className="MapTableContainer">
          <table className="MapTable">
            <thead>
              <tr>
                <th colSpan="3" className="MapTableHeader">
                  {trans('mapped_features')}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{trans('name')}</th>
                <th>{trans('last_survey')}</th>
                <th>{trans('area_ha')}</th>
              </tr>
              <tr>
                <th colSpan="3">{trans('paddocks')}</th>
              </tr>
              {pRows}
              <tr>
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {trans('total_area')}
                </td>
                <td />
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {Math.round(pArea * 100) / 100}
                </td>
              </tr>
              <tr>
                <th colSpan="3">{trans('buildings')}</th>
              </tr>
              {bRows}
              <tr>
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {trans('total_area')}
                </td>
                <td />
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {Math.round(bArea * 100) / 100}
                </td>
              </tr>
              <tr>
                <th colSpan="3">{trans('yards')}</th>
              </tr>
              {yRows}
              <tr>
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {trans('total_area')}
                </td>
                <td />
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {Math.round(yArea * 100) / 100}
                </td>
              </tr>
              <tr>
                <th colSpan="3">{trans('forests')}</th>
              </tr>
              {fRows}
              <tr>
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {trans('total_area')}
                </td>
                <td />
                <td
                  style={{
                    fontWeight: '700'
                  }}
                >
                  {Math.round(fArea * 100) / 100}
                </td>
              </tr>
              <tr>
                <th colSpan="3">{trans('roads')}</th>
              </tr>
              {rRows}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

class Map extends Component {
  
  toggleFeatureList() {
    this.setState({
      featurelistvisible: !this.state.featurelistvisible
    });
  }

  addFeature(type) {
    let mode;
    switch (type) {
      case "road":
        mode = "polyline";
        break;
      case "trough":
        mode = "marker";
        break;
      default:
        mode = "polygon";
        break;
    }
    this.select(null);
    this.setState({
      drawingMode: mode,
      featureType: type,
      featurelistvisible: false
    });
  }

  renameFeature() {
    this.setState({ featurelistvisible: false });
    if (!this.state.selected) {
      return;
    }
    const polygon = this.state.polygons[this.state.selected];
    const paddockArray = this.state.paddockArray;
    var index = paddockArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      const paddock = paddockArray[index];
      var name = '';
      while (!name) {
        name = window.prompt(this.trans("paddock_name"));
        if (name === null) {
          return;
        } else if (name === "") {
          alert(this.trans('name_cannot_be_empty'));
        } else {
          for (let p of paddockArray) {
            if (name === p.name) {
              alert(this.trans('name_already_in_use'));
              name = '';
            }
          }
        }
      }
      paddock.name = name;
      paddockArray[index] = paddock;
      this.setState({paddockArray: paddockArray})
      this.props.setAppState({ map_has_unsaved_changes: true });
      return;
    }
    
    const buildingArray = this.state.buildingArray;
    var index = buildingArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      const building = buildingArray[index];
      var name = '';
      while (!name) {
        name = window.prompt(this.trans("building_name"));
        if (name === null) {
          return;
        } else if (name === "") {
          alert(this.trans('name_cannot_be_empty'));
        } else {
          for (let p of buildingArray) {
            if (name === p.name) {
              alert(this.trans('name_already_in_use'));
              name = '';
            }
          }
        }
      }
      building.name = name;
      buildingArray[index] = building;
      this.setState({buildingArray: buildingArray})
      return;
    }
    
    const forestArray = this.state.forestArray;
    var index = forestArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      const forest = forestArray[index];
      var name = '';
      while (!name) {
        name = window.prompt(this.trans("forest_name"));
        if (name === null) {
          return;
        } else if (name === "") {
          alert(this.trans('name_cannot_be_empty'));
        } else {
          for (let p of forestArray) {
            if (name === p.name) {
              alert(this.trans('name_already_in_use'));
              name = '';
            }
          }
        }
      }
      forest.name = name;
      forestArray[index] = forest;
      this.setState({forestArray: forestArray})
      return;
    }
    
    const yardArray = this.state.yardArray;
    var index = yardArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      const yard = yardArray[index];
      var name = '';
      while (!name) {
        name = window.prompt(this.trans("yard_name"));
        if (name === null) {
          return;
        } else if (name === "") {
          alert(this.trans('name_cannot_be_empty'));
        } else {
          for (let p of yardArray) {
            if (name === p.name) {
              alert(this.trans('name_already_in_use'));
              name = '';
            }
          }
        }
      }
      yard.name = name;
      yardArray[index] = yard;
      this.setState({yardArray: yardArray})
      return;
    }
    
    const roadArray = this.state.roadArray;
    var index = roadArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      const road = roadArray[index];
      var name = '';
      while (!name) {
        name = window.prompt(this.trans("road_name"));
        if (name === null) {
          return;
        } else if (name === "") {
          alert(this.trans('name_cannot_be_empty'));
        } else {
          for (let p of roadArray) {
            if (name === p.name) {
              alert(this.trans('name_already_in_use'));
              name = '';
            }
          }
        }
      }
      road.name = name;
      roadArray[index] = road;
      this.setState({roadArray: roadArray})
      return;
    }
  }

  deleteFeature() {
    this.setState({ featurelistvisible: false });
    if (!this.state.selected) {
      return;
    }

    const polygon = this.state.polygons[this.state.selected];
    const paddockArray = this.state.paddockArray;
    var index = paddockArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      let confirmed = window.confirm(this.trans('are_you_sure_delete_paddock'));
      if (!confirmed) {
        return;
      }
      paddockArray.splice(index, 1);
      this.props.setAppState({ map_has_unsaved_changes: true });
      this.setState({paddockArray: paddockArray})
      return;
    }
    
    const buildingArray = this.state.buildingArray;
    var index = buildingArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      let confirmed = window.confirm(this.trans('are_you_sure_delete'));
      if (!confirmed) {
        return;
      }
      buildingArray.splice(index, 1);
      this.props.setAppState({ map_has_unsaved_changes: true });
      this.setState({buildingArray: buildingArray})
      return;
    }
    
    const forestArray = this.state.forestArray;
    var index = forestArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      let confirmed = window.confirm(this.trans('are_you_sure_delete'));
      if (!confirmed) {
        return;
      }
      forestArray.splice(index, 1);
      this.props.setAppState({ map_has_unsaved_changes: true });
      this.setState({forestArray: forestArray})
      return;
    }
    
    const yardArray = this.state.yardArray;
    var index = yardArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      let confirmed = window.confirm(this.trans('are_you_sure_delete'));
      if (!confirmed) {
        return;
      }
      yardArray.splice(index, 1);
      this.props.setAppState({ map_has_unsaved_changes: true });
      this.setState({yardArray: yardArray})
      return;
    }
    
    const roadArray = this.state.roadArray;
    var index = roadArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      let confirmed = window.confirm(this.trans('are_you_sure_delete'));
      if (!confirmed) {
        return;
      }
      roadArray.splice(index, 1);
      this.props.setAppState({ map_has_unsaved_changes: true });
      this.setState({roadArray: roadArray})
      return;
    }
    
    const troughArray = this.state.troughArray;
    var index = troughArray.findIndex((p)=>p.uuid==this.state.selected)
    if (index>=0) {
      let confirmed = window.confirm(this.trans('are_you_sure_delete'));
      if (!confirmed) {
        return;
      }
      troughArray.splice(index, 1);
      this.props.setAppState({ map_has_unsaved_changes: true });
      this.setState({troughArray: troughArray})
      return;
    }
  }

  saveChanges() {
    this.setState({ featurelistvisible: false });
    const self = this;
    axios
      .post('https://staging.grasslandtools.ie/api/updatemap2/', {
        sessionid: UserController.getSessionkey(),
        farmid: UserController.getFarmid(),
        paddocks: this.state.paddockArray.map(function(paddock) {
          return {
            name: paddock.name,
            uuid: paddock.uuid,
            area: paddock.area,
            coordinates: paddock.coords.map((c)=>{return {lat: c.lat(), long: c.lng()}})
          };
        }),
        buildings: this.state.buildingArray.map(function(building) {
          return {
            name: building.name,
            uuid: building.uuid,
            area: building.area,
            coordinates: building.coords.map((c)=>{return {lat: c.lat(), long: c.lng()}})
          };
        }),
        roads: this.state.roadArray.map(function(road) {
          return {
            name: road.name,
            uuid: road.uuid,
            area: road.area,
            coordinates: road.coords.map((c)=>{return {lat: c.lat(), long: c.lng()}})
          };
        }),
        yards: this.state.yardArray.map(function(yard) {
          return {
            name: yard.name,
            uuid: yard.uuid,
            area: yard.area,
            coordinates: yard.coords.map((c)=>{return {lat: c.lat(), long: c.lng()}})
          };
        }),
        troughs: this.state.troughArray.map(function(trough) {
          return {
            uuid: trough.uuid,
            name: trough.name,
            coordinates: {lat: trough.coords.lat(), long: trough.coords.lng()}
          };
        }),
        forests: this.state.forestArray.map(function(forest) {
          return {
            name: forest.name,
            uuid: forest.uuid,
            area: forest.area,
            coordinates: forest.coords.map((c)=>{return {lat: c.lat(), long: c.lng()}})
          };
        })
      })
      .then(function(response) {
        self.setState({
          key: self.state.key + 1,
          mode: 'paddock'
        });
        self.props.setAppState({ map_has_unsaved_changes: false });
        self.props.getDataset(UserController.getFarmid(), 'Toolset', null);
      })
      .catch(function(error) {});
  }


  constructor(props) {
    super(props);
    this.state = {
      paddockArray: [],
      buildingArray: [],
      forestArray: [],
      yardArray: [],
      roadArray: [],
      troughArray: [],
      polygons: {},
      selected: null,
      drawingMode: null,
      key: this.props.appkey
    };
    this.drawingManager = null;
    this.trans = Translation.getInstance().translate;
    this.select = this.select.bind(this)
    this.onPolygonComplete = this.onPolygonComplete.bind(this)
    this.onPolylineComplete = this.onPolylineComplete.bind(this)
    this.onMarkerComplete = this.onMarkerComplete.bind(this)
    this.onDrawingManagerLoaded = this.onDrawingManagerLoaded.bind(this)
  }

  onMapLoaded(map){
    const self = this;

    let fitBounds = false;
    const bounds = new window.google.maps.LatLngBounds();
    axios
      .get('https://staging.grasslandtools.ie/api/getmapdata/', {
        params: {
          sessionid: UserController.getSessionkey(),
          farmid: UserController.getFarmid(),
          includeTroughs: true,
        }
      })
      .then(function(response) {
        const paddocks = response.data.paddocks;
        const paddockArray = paddocks.map(function(paddock) {
          const id = paddock.id;
          const name = paddock.name;
          const area = paddock.area;
          const surveyCount = paddock.survey_count;
          const lastSurvey = paddock.last_survey;
          const coords = paddock.coords.map(function(coord) {
            const point = new window.google.maps.LatLng(
              coord.latitude,
              coord.longitude
            );
            bounds.extend(point);
            fitBounds = true;

            return point;
          });
          return {
            name: name,
            coords: coords,
            uuid: paddock.uuid || uuidv4(),
            area: area,
            surveyCount: surveyCount,
            lastSurvey: lastSurvey,
            id: id,
            type: 'paddock'
          };
        });
        const buildings = response.data.buildings;
        const buildingArray = buildings.map(function(e) {
          const id = e.id;
          const name = e.name;
          const area = e.area;
          const surveyCount = e.survey_count;
          const lastSurvey = e.last_survey;

          const coords = e.coords.map(function(coord) {
            const point = new window.google.maps.LatLng(
              coord.latitude,
              coord.longitude
            );
            bounds.extend(point);
            fitBounds = true;

            return point;
          });
          return {
            name: name,
            coords: coords,
            uuid: e.uuid || uuidv4(),
            area: area,
            surveyCount: surveyCount,
            lastSurvey: lastSurvey,
            id: id,
            type: 'building'
          };
        });
        const forests = response.data.forests;
        const forestArray = forests.map(function(e) {
          const id = e.id;
          const name = e.name;
          const area = e.area;
          const surveyCount = e.survey_count;
          const lastSurvey = e.last_survey;

          const coords = e.coords.map(function(coord) {
            const point = new window.google.maps.LatLng(
              coord.latitude,
              coord.longitude
            );
            bounds.extend(point);
            fitBounds = true;

            return point;
          });
          return {
            name: name,
            coords: coords,
            uuid: e.uuid || uuidv4(),
            area: area,
            surveyCount: surveyCount,
            lastSurvey: lastSurvey,
            id: id,
            type: 'forest'
          };
        });
        const yards = response.data.yards;
        const yardArray = yards.map(function(e) {
          const id = e.id;
          const name = e.name;
          const area = e.area;
          const surveyCount = e.survey_count;
          const lastSurvey = e.last_survey;

          const coords = e.coords.map(function(coord) {
            const point = new window.google.maps.LatLng(
              coord.latitude,
              coord.longitude
            );
            bounds.extend(point);
            fitBounds = true;

            return point;
          });
          return {
            name: name,
            coords: coords,
            uuid: e.uuid || uuidv4(),
            area: area,
            surveyCount: surveyCount,
            lastSurvey: lastSurvey,
            id: id,
            type: 'yard'
          };
        });
        const roads = response.data.roads;
        const roadArray = roads.map(function(e) {
          const id = e.id;
          const name = e.name;
          const area = e.area;
          const surveyCount = e.survey_count;
          const lastSurvey = e.last_survey;

          const coords = e.coords.map(function(coord) {
            const point = new window.google.maps.LatLng(
              coord.latitude,
              coord.longitude
            );
            bounds.extend(point);
            fitBounds = true;

            return point;
          });
          return {
            name: name,
            coords: coords,
            uuid: e.uuid || uuidv4(),
            area: area,
            surveyCount: surveyCount,
            lastSurvey: lastSurvey,
            id: id,
            type: 'road'
          };
        });
        const troughs = response.data.troughs;
        const troughArray = troughs.map(function(e) {
          const id = e.id;
          const name = e.name;

          const coords = new window.google.maps.LatLng(
            e.latitude,
            e.longitude
          );
          bounds.extend(coords);
          fitBounds = true;

          return {
            name: name,
            coords: coords,
            uuid: e.uuid || uuidv4(),
            id: id,
            type: 'marker'
          };
        });
        self.setState({
          paddockArray: paddockArray,
          buildingArray: buildingArray,
          forestArray: forestArray,
          yardArray: yardArray,
          roadArray: roadArray,
          troughArray: troughArray,
        });
        map.fitBounds(bounds)
      });
  }

  onDrawingManagerLoaded(drawingManager){
    this.drawingManager=drawingManager;
  }

  onPolygonComplete (polygon){
    const area =
        window.google.maps.geometry.spherical.computeArea(polygon.getPath()) * 0.9978;
    switch (this.state.featureType) {
      case "paddock":
        const paddockArray = this.state.paddockArray;
        var name = '';
        while (!name) {
          name = window.prompt(this.trans("paddock_name"));
          if (name === null) {
            polygon.setMap(null);
            this.setState({
              drawingMode: null, 
              type: null,
            })
            return;
          } else if (name === "") {
            alert(this.trans('name_cannot_be_empty'));
          } else {
            for (let p of paddockArray) {
              if (name === p.name) {
                alert(this.trans('name_already_in_use'));
                name = '';
              }
            }
          }
        }
        paddockArray.push({
          name: name,
          coords: polygon.getPath().getArray(),
          uuid: uuidv4(),
          area: area,
          type: 'paddock'
        })
        this.setState({
          drawingMode: null, 
          type: null,
          paddockArray: paddockArray
        })
        this.props.setAppState({ map_has_unsaved_changes: true });
        break;

      case "building":
        const buildingArray = this.state.buildingArray;
        var name = '';
        while (!name) {
          name = window.prompt(this.trans("building_name"));
          if (name === null) {
            polygon.setMap(null);
            this.setState({
              drawingMode: null, 
              type: null,
            })
            return;
          } else if (name === "") {
            alert(this.trans('name_cannot_be_empty'));
          } else {
            for (let p of buildingArray) {
              if (name === p.name) {
                alert(this.trans('name_already_in_use'));
                name = '';
              }
            }
          }
        }
        buildingArray.push({
          name: name,
          coords: polygon.getPath().getArray(),
          uuid: uuidv4(),
          area: area,
          type: 'building'
        })
        this.setState({
          drawingMode: null, 
          type: null,
          buildingArray: buildingArray
        })
        this.props.setAppState({ map_has_unsaved_changes: true });
        break;

      case "yard":
        const yardArray = this.state.yardArray;
        var name = '';
        while (!name) {
          name = window.prompt(this.trans("yard_name"));
          if (name === null) {
            polygon.setMap(null);
            this.setState({
              drawingMode: null, 
              type: null,
            })
            return;
          } else if (name === "") {
            alert(this.trans('name_cannot_be_empty'));
          } else {
            for (let p of yardArray) {
              if (name === p.name) {
                alert(this.trans('name_already_in_use'));
                name = '';
              }
            }
          }
        }
        yardArray.push({
          name: name,
          coords: polygon.getPath().getArray(),
          uuid: uuidv4(),
          area: area,
          type: 'yard'
        })
        this.setState({
          drawingMode: null, 
          type: null,
          yardArray: yardArray
        })
        this.props.setAppState({ map_has_unsaved_changes: true });
        break;
      case "forest":
        const forestArray = this.state.forestArray;
        var name = '';
        while (!name) {
          name = window.prompt(this.trans("forest_name"));
          if (name === null) {
            polygon.setMap(null);
            this.setState({
              drawingMode: null, 
              type: null,
            })
            return;
          } else if (name === "") {
            alert(this.trans('name_cannot_be_empty'));
          } else {
            for (let p of forestArray) {
              if (name === p.name) {
                alert(this.trans('name_already_in_use'));
                name = '';
              }
            }
          }
        }
        forestArray.push({
          name: name,
          coords: polygon.getPath().getArray(),
          uuid: uuidv4(),
          area: area,
          type: 'forest'
        })
        this.setState({
          drawingMode: null, 
          type: null,
          forestArray: forestArray
        })
        this.props.setAppState({ map_has_unsaved_changes: true });
        break;
    }
    polygon.setMap(null);
  }

  onPolylineComplete (polyline){
    const roadArray = this.state.roadArray;
    var name = '';
    while (!name) {
      name = window.prompt(this.trans("road_name"));
      if (name === null) {
        polyline.setMap(null);
        this.setState({
          drawingMode: null, 
          type: null,
        })
        return;
      } else if (name === "") {
        alert(this.trans('name_cannot_be_empty'));
      } else {
        for (let p of roadArray) {
          if (name === p.name) {
            alert(this.trans('name_already_in_use'));
            name = '';
          }
        }
      }
    }
    roadArray.push({
      name: name,
      coords: polyline.getPath().getArray(),
      uuid: uuidv4(),
      type: 'road'
    })
    this.setState({
      drawingMode: null, 
      type: null,
      roadArray: roadArray
    })
    this.props.setAppState({ map_has_unsaved_changes: true });
    polyline.setMap(null);
  }

  onMarkerComplete (marker){
    const troughArray = this.state.troughArray;
    troughArray.push({
      coords: marker.getPosition(),
      uuid: uuidv4(),
      type: 'trough'
    })
    this.setState({
      drawingMode: null, 
      type: null,
      troughArray: troughArray
    })
    this.props.setAppState({ map_has_unsaved_changes: true });
    marker.setMap(null);
  }

  onEdit(uuid, event) {
    const polygon = this.state.polygons[uuid];
        
    const paddockArray = this.state.paddockArray;
    var index = paddockArray.findIndex((p)=>p.uuid==uuid)
    if (index>=0) {
      const paddock = paddockArray[index];
      const coords = polygon.getPath().getArray()
      paddock.coords = coords;
      paddock.area = window.google.maps.geometry.spherical.computeArea(polygon.getPath()) * 0.9978;
      paddockArray[index] = paddock;
      this.setState({paddockArray: paddockArray})
      this.props.setAppState({ map_has_unsaved_changes: true });
      return;
    }
    const buildingArray = this.state.buildingArray;
    var index = buildingArray.findIndex((f)=>f.uuid==uuid)
    if (index>=0) {
      const building = buildingArray[index];
      const coords = polygon.getPath().getArray()
      building.coords = coords;
      building.area = window.google.maps.geometry.spherical.computeArea(polygon.getPath()) * 0.9978;
      buildingArray[index] = building;
      this.setState({buildingArray: buildingArray})
      this.props.setAppState({ map_has_unsaved_changes: true });
      return;
    }
    const forestArray = this.state.forestArray;
    var index = forestArray.findIndex((f)=>f.uuid==uuid)
    if (index>=0) {
      const forest = forestArray[index];
      const coords = polygon.getPath().getArray()
      forest.coords = coords;
      forest.area = window.google.maps.geometry.spherical.computeArea(polygon.getPath()) * 0.9978;
      forestArray[index] = forest;
      this.setState({forestArray: forestArray})
      this.props.setAppState({ map_has_unsaved_changes: true });
      return;
    }
    const yardArray = this.state.yardArray;
    var index = yardArray.findIndex((f)=>f.uuid==uuid)
    if (index>=0) {
      const yard = yardArray[index];
      const coords = polygon.getPath().getArray()
      yard.coords = coords;
      yard.area = window.google.maps.geometry.spherical.computeArea(polygon.getPath()) * 0.9978;
      yardArray[index] = yard;
      this.setState({yardArray: yardArray})
      this.props.setAppState({ map_has_unsaved_changes: true });
      return;
    }
    const roadArray = this.state.roadArray;
    var index = roadArray.findIndex((f)=>f.uuid==uuid)
    if (index>=0) {
      const road = roadArray[index];
      const coords = polygon.getPath().getArray()
      road.coords = coords;
      roadArray[index] = road;
      this.setState({roadArray: roadArray})
      this.props.setAppState({ map_has_unsaved_changes: true });
      return;
    }
    const troughArray = this.state.troughArray;
    var index = troughArray.findIndex((f)=>f.uuid==uuid)
    if (index>=0) {
      const trough = troughArray[index];
      const coords = polygon.center
      trough.coords = coords;
      troughArray[index] = trough;
      this.setState({troughArray: troughArray})
      this.props.setAppState({ map_has_unsaved_changes: true });
      return;
    }
  }

  onLoad(uuid, polygon) {
    const polygons = this.state.polygons;
    polygons[uuid] = polygon;
    this.setState({polygons: polygons})
  }

  select(uuid, polygon) {
    this.setState({selected: uuid})
  }
  
  onUnmount(uuid, polygon) {
    const polygons = this.state.polygons;
    delete polygons[uuid];
    this.setState({polygons: polygons})
  }

  render() {
    const bootstrapURLKeys = {
      key: 'AIzaSyCX8KvNyCqv0lrpBpbLbVh0hwXNU87HwRs',
      libraries: 'drawing'
    };
    const containerStyle = {
      width: 'calc(100vw - 300px)',
      height: 'calc(100vh - 65px - 30px)'
    }
    
    const center = {
      lat: -3.745,
      lng: -38.523
    };

    return (
      <div
        key={this.state.key}
        className="Map"
        style={{
          display: 'flex'
        }}
      >
        <div>
          <div
            style={{
              height: '30px',
              display: 'flex'
            }}
          >
            <div
              style={{
                width: '16%',
                flexGrow: '1'
              }}
              className={
                this.state.featurelistvisible
                  ? 'MapButtonAddContainer Show'
                  : 'MapButtonAddContainer'
              }
            >
              <div
                className="MapButtonAdd"
                onClick={this.toggleFeatureList.bind(this)}
              >
                {this.trans('add')}
                <img className="MapButtonIcon" src={Drop} alt="" />
              </div>
              <div
                className="Feature"
                onClick={this.addFeature.bind(this, 'paddock')}
              >
                {this.trans('paddock')}
              </div>
              <div
                className="Feature"
                onClick={this.addFeature.bind(this, 'building')}
              >
                {this.trans('building')}
              </div>
              <div
                className="Feature"
                onClick={this.addFeature.bind(this, 'yard')}
              >
                {this.trans('yard')}
              </div>
              <div
                className="Feature"
                onClick={this.addFeature.bind(this, 'road')}
              >
                {this.trans('road')}
              </div>
              <div
                className="Feature"
                onClick={this.addFeature.bind(this, 'forest')}
              >
                {this.trans('forest')}
              </div>
              <div
                className="Feature"
                onClick={this.addFeature.bind(this, 'trough')}
              >
                {this.trans('trough')}
              </div>
            </div>
            <div
              style={{
                width: '16%',
                flexGrow: '1'
              }}
              className="MapButton"
              onClick={this.renameFeature.bind(this)}
            >
              {this.trans('rename')}
              <img className="MapButtonIcon" src={Edit} alt="" />
            </div>
            <div
              style={{
                width: '16%',
                flexGrow: '1'
              }}
              className="MapButton"
              onClick={this.deleteFeature.bind(this)}
            >
              {this.trans('delete')}
              <img className="MapButtonIcon" src={Delete} alt="" />
            </div>
            <div
              style={{
                width: '16%',
                flexGrow: '1'
              }}
              className="MapButton"
              onClick={this.saveChanges.bind(this)}
            >
              {this.trans('save_changes')}
              <img className="MapButtonIcon" src={Done} alt="" />
            </div>
          </div>
          
      <LoadScript
        googleMapsApiKey="AIzaSyCX8KvNyCqv0lrpBpbLbVh0hwXNU87HwRs"
        libraries={libraries}
      >
        <GoogleMap
          mapContainerStyle={containerStyle}
          // center={center}
          // zoom={5}
          onLoad={this.onMapLoaded.bind(this)}
          onClick={this.select.bind(this, null)}
          options={{
            disableDefaultUI: true,
            mapTypeId: "hybrid"
          }}
        >
        <DrawingManager
          onLoad={this.onDrawingManagerLoaded}
          onPolygonComplete={this.onPolygonComplete}
          onPolylineComplete={this.onPolylineComplete}
          onMarkerComplete={this.onMarkerComplete}
          options={{
            drawingMode: this.state.drawingMode,
            drawingControlOptions: {
              drawingModes: ['marker', 'polyline', 'polygon']
            },
            drawingControl: false,
            polygonOptions: {
              fillColor: '#ffffff',
              fillOpacity: 0.5,
              strokeWeight: 1,
              strokeColor: '#ffffff',
              editable: true,
              clickable: true
            },
            markerOptions: {
              clickable: true,
              draggable: true
            }
          }}
        />
          {this.state.yardArray.map((p, i)=>{
            return <Polygon
              key={i}
              paths={p.coords}
              onMouseUp={this.state.selected==p.uuid?this.onEdit.bind(this, p.uuid):null}
              onLoad={this.onLoad.bind(this, p.uuid)}
              onUnmount={this.onUnmount.bind(this, p.uuid)}
              onClick={this.select.bind(this, p.uuid)}
              editable={this.state.selected==p.uuid}
              zIndex={44}
              options={
                {
                  fillColor: '#bf3f21',
                  fillOpacity: 0.5,
                  strokeWeight: this.state.selected==p.uuid?4:2,
                  strokeColor: this.state.selected==p.uuid?'#cccccc':'#bf3f21',
                }
              }
            />;
          })}
          {this.state.forestArray.map((p, i)=>{
            return <Polygon
              key={i}
              paths={p.coords}
              onMouseUp={this.state.selected==p.uuid?this.onEdit.bind(this, p.uuid):null}
              onLoad={this.onLoad.bind(this, p.uuid)}
              onUnmount={this.onUnmount.bind(this, p.uuid)}
              onClick={this.select.bind(this, p.uuid)}
              editable={this.state.selected==p.uuid}
              zIndex={55}
              options={
                {
                  fillColor: '#21bf3f',
                  fillOpacity: 0.5,
                  strokeWeight: this.state.selected==p.uuid?4:2,
                  strokeColor: this.state.selected==p.uuid?'#cccccc':'#21bf3f',
                }
              }
            />;
          })}
          {this.state.paddockArray.map((p, i)=>{
            return <Polygon
              key={i}
              paths={p.coords}
              onMouseUp={this.state.selected==p.uuid?this.onEdit.bind(this, p.uuid):null}
              onLoad={this.onLoad.bind(this, p.uuid)}
              onUnmount={this.onUnmount.bind(this, p.uuid)}
              onClick={this.select.bind(this, p.uuid)}
              editable={this.state.selected==p.uuid}
              zIndex={66}
              options={
                {
                  fillColor: 'rgb(120,190,32)',
                  fillOpacity: 0.5,
                  strokeWeight: this.state.selected==p.uuid?4:2,
                  strokeColor: this.state.selected==p.uuid?'#cccccc':'rgb(120,190,32)',
                }
              }
            />;
          })}
          {this.state.roadArray.map((p, i)=>{
            return <Polyline
              key={i}
              path={p.coords}
              onMouseUp={this.state.selected==p.uuid?this.onEdit.bind(this, p.uuid):null}
              onLoad={this.onLoad.bind(this, p.uuid)}
              onUnmount={this.onUnmount.bind(this, p.uuid)}
              onClick={this.select.bind(this, p.uuid)}
              editable={this.state.selected==p.uuid}
              zIndex={77}
              options={
                {
                  strokeColor: this.state.selected==p.uuid?'#cccccc':'#bf8f21',
                  strokeOpacity: 0.8,
                  strokeWeight: 8,
                }
              }
            />;
          })}
          {this.state.buildingArray.map((p, i)=>{
            return <Polygon
              key={i}
              paths={p.coords}
              onMouseUp={this.state.selected==p.uuid?this.onEdit.bind(this, p.uuid):null}
              onLoad={this.onLoad.bind(this, p.uuid)}
              onUnmount={this.onUnmount.bind(this, p.uuid)}
              onClick={this.select.bind(this, p.uuid)}
              editable={this.state.selected==p.uuid}
              zIndex={88}
              options={
                {
                  fillColor: '#21bfb6',
                  fillOpacity: 0.5,
                  strokeWeight: this.state.selected==p.uuid?4:2,
                  strokeColor: this.state.selected==p.uuid?'#cccccc':'#21bfb6',
                }
              }
            />;
          })}
          {this.state.troughArray.map((p, i)=>{
            return <Circle
              key={i}
              center={p.coords}
              radius={4}
              onLoad={this.onLoad.bind(this, p.uuid)}
              onUnmount={this.onUnmount.bind(this, p.uuid)}
              onClick={this.select.bind(this, p.uuid)}
              onDragEnd={this.state.selected==p.uuid?this.onEdit.bind(this, p.uuid):null}
              draggable={this.state.selected==p.uuid}
              zIndex={99}
              options={
                {
                  fillColor: '#2179bf',
                  fillOpacity: 1,
                  strokeWeight: 2,
                  strokeColor: this.state.selected==p.uuid?'#cccccc':'#2179bf',
                }
              }
            />;
          })}
        </GoogleMap>
      </LoadScript>
        </div>

        <PaddockTable
          active={this.state.active}
          select={this.select}
          selected={this.state.selected}
          troughArray={this.state.troughArray}
          paddockArray={this.state.paddockArray}
          buildingArray={this.state.buildingArray}
          forestArray={this.state.forestArray}
          roadArray={this.state.roadArray}
          yardArray={this.state.yardArray}
          saveChanges={this.saveChanges.bind(this)}
          mode={this.state.mode}
          key={this.state.key}
        />
      </div>
    );
  }
}

export default Map;
