import React, { Component } from 'react';

import Translation from './Translation';
import Flags from './Flags';

import packageJson from '../package.json';

// import Icon from './img/GrasslandToolsTNBlue.png';
import Icon from './img/GrasslandToolsLogoNewGW.png';
// import Icon from './img/GrasslandToolsLogoNew.png';
// import Icon from './img/WierdLogo.png';
import './Header.css';

class Link extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    return (
      <li
        className={
          this.props.display === this.props.title
            ? 'HeaderTab active'
            : "HeaderTab"
        }
        onClick={
          this.props.onClick
            ? this.props.onClick.bind(this)
            : this.props.setDisplay.bind(this, this.props.title)
        }
      >
        {trans(this.props.title)}
      </li>
    );
  }
}
class Header extends Component {
  render() {
    const self = this;
    const trans = Translation.getInstance().translate;
    const neutralLinks = this.props.links.filter(function(link) {
      return link.className.search('Item') > -1;
    });
    const changeLinks = this.props.links.filter(function(link) {
      return link.className.search('Change') > -1;
    });
    return (
      <div className="Header">
        <div className="IconDiv" onClick={this.props.goHome} title={packageJson.version}>
          <img className="Icon" src={Icon} alt="Icon" />
        </div>
        
        <div className="NavDiv">
          <div className="Header_group">
            <div className="HeaderLinks">
              <ul>
                {neutralLinks.map(function(link, i) {
                  return (
                    <Link
                      key={Math.random()}
                      className={link.className}
                      onClick={link.onClick}
                      title={link.title}
                      setDisplay={self.props.setDisplay}
                      display={self.props.display}
                    />
                  );
                })}
              </ul>
            </div>
            <div className="HeaderActions">
              <ul>
              {/* {changeLinks.length > 0 ? <ul> */}
                {changeLinks.map(function(link, i) {
                  return (
                    <Link
                      key={Math.random()}
                      className={link.className}
                      onClick={link.onClick}
                      title={link.title}
                      setDisplay={self.props.setDisplay}
                      display={self.props.display}
                    />
                  );
                })}
              {/* </ul> : null} */}
              {/* {!this.props.mobile ? <Flags incrementKey={this.props.incrementKey} /> : null}{' '} */}
              {this.props.userLoggedIn ? (
                <div
                  style={{
                    cursor: 'pointer'
                  }}
                  className="HeaderTab"
                  onClick={this.props.logout.bind(this)}
                >
                  {trans('logout')}
                </div>
              ) : null}</ul>
            </div>
          </div>
        </div>
        <Flags incrementKey={this.props.incrementKey} />
      </div>
    );
  }
}

export default Header;
