import React, { Component } from "react";

import axios from "axios";
import { select } from "d3-selection";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import UserController from "./UserController";
import { RiDeleteBin6Line } from "react-icons/ri";
import Translation from './Translation';
import HerdImage from "./img/cowLineDrawing.png";

import "./FarmBrowser.css";

class HerdIcon extends Component {
  constructor(props) {
    super(props);
    this.createHerdIcon = this.createHerdIcon.bind(this);
    this.trans = Translation.getInstance().translate;

    this.w = 0;
    this.h = 0;
  }

  componentDidMount() {
    const chart = document.getElementById(this.props.id);
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createHerdIcon();
    window.addEventListener('resize', this.createFarmIcon);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createFarmIcon);
  }

  componentDidUpdate() {
    const chart = document.getElementById(this.props.id);
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createHerdIcon();
  }
  createHerdIcon() {
    if (this.props.herd.herd_name) {
      const chart = document.getElementById(this.props.id);
      const w = chart.clientWidth;
      const h = chart.clientHeight;

      document.getElementById(this.props.id).innerHTML = '';

      const svg = select('#' + this.props.id)
        .append('svg')
        .attr('width', w)
        .attr('height', h);

      const size = this.props.size;
      const farm_name = this.props.herd.herd_name;
      const font_size = Math.min(size / 8, size / farm_name.length);
      // Define margins
      const margin = {
        top: size / 20,
        right: size / 20,
        bottom: size / 20 + font_size,
        left: size / 20
      };

      // Add map group
      const map = svg.append('g');

      map
        .append('image')
        .attr('xlink:href', HerdImage)
        .attr('width', size*.8)
        .attr('height', size*.8)
        .attr('x', size*.1)
        .attr('y', size*.05);

      map
        .append('text')
        .text(farm_name)
        .attr('x', w / 2)
        .attr('y', h - font_size / 2)
        .attr('class', 'FarmIconTitle')
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none')
        .style('font-size', font_size + 'px');
    }
  }
  async delete ()  {
    // console.log(this.props.herd)
    var confirmed = window.confirm(this.trans("are_you_sure_remove_herd_from_account"));
    if (confirmed) {
      const response = await axios.get(
        'https://staging.grasslandtools.ie/api/deleteherd/',
        {
          params: {
            herdid: this.props.herd.herd_id,
            sessionid: UserController.getSessionkey(),
          }
        }
      );
      if (response.status === 200) {
        this.props.reloadIcons();
      }
    }
  }
  render() {
    return (
      <div>
        <ContextMenuTrigger id={"delete_herd_menu"+this.props.id}>
      <div
        id={this.props.id}
        onClick={this.props.enabled ?this.props.onClick : null}
        className={"FarmIcon " + (this.props.enabled ? "" : "Disabled")}
        style={{
          width: this.props.size + 'px',
          height: this.props.size + 'px',
          borderRadius: this.props.size / 10 + 'px',
          margin: this.props.margin + 'px',
        }}
      />
      </ContextMenuTrigger>
      <ContextMenu id={"delete_herd_menu"+this.props.id}>
        <MenuItem onClick={this.delete.bind(this)}>
          <RiDeleteBin6Line className="delete"/>
          <span>Delete Herd</span>
        </MenuItem>
      </ContextMenu>
    </div>
    );
  }
}

class HalfHeightHerdBrowser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      w: null,
      h: null,
      herds: [],
      killAsync: false,
      letter: "#",
      page: 0
    };
    this._ismounted = false;
    this.updateDimensions = this.updateDimensions.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  fitSquares(w, h, n) {
    // if (n < 3) { n = 3 }
    n = 4;
    let sx;
    let sy;

    const px = Math.ceil(Math.sqrt((n * w) / h));
    if (Math.floor((px * h) / w) * px < n) {
      sx = h / Math.ceil((px * h) / w);
    } else {
      sx = w / px;
    }

    const py = Math.ceil(Math.sqrt((n * h) / w));
    if (Math.floor((py * w) / h) * py < n) {
      sy = w / Math.ceil((py * w) / h);
    } else {
      sy = h / py;
    }

    return Math.min(Math.max(sx, sy, 290 / 0.9), 360);
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    const self = this;
    this.getHerds = async function getHerds(hIds) {
      let herds = [];
      self.setState({ herds: [] });
      const letter = this.state.letter;
      for (const id of hIds) {
        if (self._ismounted && self.state.letter === letter) {
          const herd = await axios.get(
            'https://staging.grasslandtools.ie/api/getherdoverview/',
            {
              params: {
                herd_id: id
              }
            }
          ); 
          // console.log(herd);
          if (self._ismounted && self.state.letter === letter) {
            herds.push(herd.data);
            self.setState({ herds: herds });
          } else {
            break;
          }
        } else {
          break;
        }
      }
    };
    this._ismounted = true;
    this.getHerds(this.props.herds);
  }

  componentWillUnmount() {
    this._ismounted = false;
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let div = document.getElementById("HalfHeightFarmBrowser");
    this.setState({
      w: div.clientWidth,
      h: div.clientHeight
    });
  }
  reloadIcons() {
    this.props.getDataset(null, "Overview", null);
  }

  incrementPage(increment) {
    this.setState({ page: this.state.page + increment });
  }

  render() {
    const self = this;
    let herdIcons = [];
    let container_width = 0;
    let w = this.state.w;
    let h = this.state.h;
    let n = 1;
    let size = this.state.size;
    let margin = w*0.005;
    let iconSize = w*0.14;
    if (this.state.herds && this.state.herds.length > 0) {
      let herds = this.state.herds;
      n = herds.length;
      size = Math.min(this.fitSquares(w, h, n), Math.max(w / 2.5, h / 2.3));
      herdIcons = herds.map(function(herd, i) {
        return (
          <HerdIcon
            key={i}
            id={'herdicon' + i}
            herd={herd}
            size={iconSize}
            margin={margin}
            onClick={self.props.getDataset.bind(self, null, null, herd.herd_id)}
            reloadIcons={self.reloadIcons.bind(self)}
            enabled={i<self.props.maxHerds}
          />
        );
      });
    }

    let firstPage = this.state.page === 0;
    let lastPage = this.state.page === Math.floor((herdIcons.length) / 6) || herdIcons.length < 6;

    herdIcons.unshift(
      <div
        key={-1}
        className="FarmIcon"
        onClick={this.props.setDisplay.bind(this, "create_herd")}
        style={{
          width: iconSize,
          height: iconSize,
          borderRadius: iconSize/10,
          margin: margin,
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center"
        }}
      >
      <h1
        style={{
          fontSize: iconSize/3
        }}
      >
        +
      </h1>
      <h1
        style={{
          fontSize: iconSize / 7
        }}
      >
        {this.trans("add_herd")}
      </h1>
    </div>
    );
    const displayedIcons = herdIcons.slice(
      this.state.page * 6,
      this.state.page * 6 + 6
    );
    return (
      <div id="HalfHeightFarmBrowser" className="HomePageFarmBrowser">
        <div className="BrowserIconContainer">
          <div
            className="FarmBrowserNav"
            style={{
              opacity: firstPage ? 0 : 1,
              pointerEvents: firstPage ? "none" : "all"
            }}
            onClick={this.incrementPage.bind(this, -1)}
          >
            {"<"}
          </div>
          <div className="BrowserIconRow">{displayedIcons}</div>
          <div
            className="FarmBrowserNav"
            style={{
              opacity: lastPage ? 0 : 1,
              pointerEvents: lastPage ? "none" : "all"
            }}
            onClick={this.incrementPage.bind(this, +1)}
          >
            {">"}
          </div>
        </div>
      </div>
    );
  }
}

export default HalfHeightHerdBrowser;
