import React, { Component } from "react";
import Translation from "./Translation";
import UserController from "./UserController";
import axios from "axios";

import Drop from "./img/Drop.svg";
import Derogation from "./img/Derogation.png";
import GrassWedge from "./img/GrassWedge.png";
import FarmMap from "./img/FarmMap.jpg";
import PastureBase from "./img/PastureBase.jpg";
import "./Dashboard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-with-locales-es6";
class OutputsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      action: null,
      farm_name: this.props.farm.name,
      file_name: "Farm Map",
      paddock_labels: "2",
      block: "0",
      inc_planner: true,
      sent: false,
      success: false,
      response: [],

      report_file_name: this.props.farm.name + " Walk Report",
      walk_date: this.props.disp_dates[0]
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({ collapsed: !this.state.collapsed });
  }

  selectAction(action) {
    this.setState({ action: action });
  }

  handleFarmNameChange(event) {
    this.setState({ farm_name: event.target.value });
  }
  handleFileNameChange(event) {
    this.setState({ file_name: event.target.value });
  }
  handleBlockChange(event) {
    this.setState({ block: event.target.value });
  }
  handlePaddockLabelsChange(event) {
    this.setState({ paddock_labels: event.target.value });
  }
  handlePlannerChange(event) {
    this.setState({ inc_planner: event.target.checked });
  }
  mapDownload() {
    document.forms["mapDownload"].submit();
    this.selectAction(null);
  }

  handleReportFileNameChange(event) {
    this.setState({ report_file_name: event.target.value });
  }
  handleWalkDateChange(date) {
    this.setState({ walk_date: date });
  }
  reportDownload() {
    document.forms["reportDownload"].submit();
    this.selectAction(null);
  }

  pbiExport() {
    this.setState({ sent: true });
    const self = this;
    var walk_data = JSON.stringify({
      date: moment(this.props.cover.date).format("YYYYMMDD"),
      paddocks: this.props.cover.padCovers.map(p => {
        return {
          name: p.paddockName,
          area: p.paddockArea,
          dmHectare: Math.round(p.dmHectare)
        };
      })
    });
    axios
      .post("https://staging.grasslandtools.ie/api/grasslandtoolspbiexport/", {
        sessionid: UserController.getSessionkey(),
        farm_id: this.props.farm.farm_id,
        walk_data: walk_data
      })
      .then(function(response) {
        self.setState({
          sent: false,
          success: response.data.Status === "SUCCESS",
          response: response.data.Message
        });
      })
      .catch(function(error) {
        self.setState({
          sent: false,
          success: false,
          response: [this.trans('error_reaching_pasturebase')]
        });
      });
  }

  closePBIExport() {
    this.setState({
      sent: false,
      response: [],
      action: null
    });
  }

  render() {
    const blockoptions = this.props.farm.blocks.map(function(block, i) {
      return (
        <option key={i} value={i + 1}>
          {block}
        </option>
      );
    });
    return (
      <div
        className="ScrollPanelItem"
        style={{ height: "auto", minHeight: "auto" }}
      >
        <div
          className={
            this.state.collapsed
              ? "ScrollPanelItemHeader Collapsed"
              : "ScrollPanelItemHeader"
          }
          onClick={this.toggleCollapsed.bind(this)}
        >
          <h3 style={{ margin: "0" }}>{this.trans('farm_map_reports_pbi_export')}</h3>
          <img
            className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"}
            src={Drop}
            alt=""
          />
        </div>
        {this.state.collapsed ? null : (
          <div style={{ padding: "0 20px 10px" }}>
            {this.state.action == null ? null : (
              <div className="MetricGroupHeader">
                {this.state.action == "farmMap"
                  ? this.trans('download_pdf_map')
                  : this.state.action == "walkReport"
                  ? this.trans('download_post_walk_report')
                  : this.state.action == "derogation"
                  ? this.trans('download_derogation_compliance_report')
                  : this.state.action == "pbiExport"
                  ? this.trans('send_to_pbi')
                  : ""}
              </div>
            )}
            {this.state.action == null ? (
              <div className="MetricGroup">
                <div className="OptionButton">
                  <div
                    className="Button Action"
                    style={{ flexDirection: "column", height: "auto" }}
                    onClick={this.selectAction.bind(this, "farmMap")}
                  >
                    <div
                      style={{
                        borderRadius: "4px 4px 0 0",
                        overflow: "hidden"
                      }}
                    >
                      <img src={FarmMap} alt="" style={{ width: "100%" }} />
                    </div>
                    <span style={{ padding: "5px", fontSize: "20px" }}>
                      {this.trans("Download PDF Map")}
                    </span>
                  </div>
                </div>
                <div className="OptionButton">
                  <div
                    className="Button Action"
                    style={{
                      flexDirection: "column",
                      height: "auto",
                      fontSize: "20px"
                    }}
                    onClick={this.selectAction.bind(this, "walkReport")}
                  >
                    <div
                      style={{
                        borderRadius: "4px 4px 0 0",
                        overflow: "hidden"
                      }}
                    >
                      <img src={GrassWedge} alt="" style={{ width: "100%" }} />
                    </div>
                    <span style={{ padding: "5px", fontSize: "20px" }}>
                      {this.trans("Download Walk Report")}
                    </span>
                  </div>
                </div>
                <div className="OptionButton">
                  <div
                    className="Button Action"
                    style={{
                      flexDirection: "column",
                      height: "auto",
                      fontSize: "20px"
                    }}
                    onClick={this.selectAction.bind(this, "derogation")}
                  >
                    <div
                      style={{
                        borderRadius: "4px 4px 0 0",
                        overflow: "hidden"
                      }}
                    >
                      <img src={Derogation} alt="" style={{ width: "100%" }} />
                    </div>
                    <span style={{ padding: "5px", fontSize: "20px" }}>
                      {this.trans("Derogation Report")}
                    </span>
                  </div>
                </div>
                <div className="OptionButton">
                  <div
                    className="Button Action"
                    style={{
                      flexDirection: "column",
                      height: "auto",
                      fontSize: "20px"
                    }}
                    onClick={this.selectAction.bind(this, "pbiExport")}
                  >
                    <div
                      style={{
                        borderRadius: "4px 4px 0 0",
                        overflow: "hidden",
                        backgroundColor: "white"
                      }}
                    >
                      <img src={PastureBase} alt="" style={{ width: "100%" }} />
                    </div>
                    <span style={{ padding: "5px", fontSize: "20px" }}>
                      {this.trans("Send to PBI")}
                    </span>
                  </div>
                </div>
              </div>
            ) : this.state.action == "farmMap" ? (
              <form
                method="POST"
                action="https://staging.grasslandtools.ie/api/downloadpdfmap/"
                name="mapDownload"
              >
                <input
                  type="hidden"
                  name="sessionid"
                  value={UserController.getSessionkey()}
                />
                <input type="hidden" name="new_roads" value={true} />
                <input
                  type="hidden"
                  name="farm_id"
                  value={this.props.farm.farm_id}
                />
                <div className="MetricGroup">
                  <div className="Metric">
                    <label>{this.trans("farm_name")}:</label>
                    <input
                      className="FormInput"
                      type="text"
                      name="farm_name"
                      value={this.state.farm_name}
                      onChange={this.handleFarmNameChange.bind(this)}
                      placeholder={this.trans('farm_name_on_pdf')}
                    />
                  </div>
                  <div className="Metric">
                    <label>{this.trans("file_name")}:</label>
                    <input
                      className="FormInput"
                      type="text"
                      name="file_name"
                      value={this.state.file_name}
                      onChange={this.handleFileNameChange.bind(this)}
                      placeholder={this.trans('pdf_file_name')}
                    />
                  </div>
                  <div className="Metric">
                    <label>{this.trans("block_on_map")}:</label>
                    <select
                      className="FormInput"
                      name="block"
                      value={this.state.block}
                      onChange={this.handleBlockChange.bind(this)}
                    >
                      <option value={0}>{this.trans("whole_farm")}</option>
                      {blockoptions}
                    </select>
                  </div>
                  <div className="Metric">
                    <label>{this.trans("paddock_labels")}:</label>
                    <select
                      className="FormInput"
                      name="paddock_labels"
                      value={this.state.paddock_labels}
                      onChange={this.handlePaddockLabelsChange.bind(this)}
                    >
                      <option value="2">{this.trans("name_and_area")}</option>
                      <option value="1">{this.trans("name_only")}</option>
                      <option value="0">{this.trans("no_labels")}</option>
                    </select>
                  </div>
                  <div className="Metric">
                    <label>{this.trans("include_rotation_planner")}:</label>
                    <select
                      className="FormInput"
                      name="inc_planner"
                      value={this.state.inc_planner}
                      onChange={this.handlePlannerChange.bind(this)}
                    >
                      <option value={true}>
                        {this.trans("include_planner")}
                      </option>
                      <option value={false}>
                        {this.trans("do_not_include")}
                      </option>
                    </select>
                  </div>
                  <div className="Metric"></div>
                  <div className="Metric">
                    <label style={{ opacity: 0 }}>Cancel</label>
                    <div
                      className="Button Action"
                      onClick={this.selectAction.bind(this, null)}
                    >
                      {this.trans("Cancel")}
                    </div>
                  </div>
                  <div className="Metric">
                    <label style={{ opacity: 0 }}>Download</label>
                    <div
                      className="Button Action"
                      onClick={this.mapDownload.bind(this)}
                    >
                      {this.trans("download_map")}
                    </div>
                  </div>
                </div>
              </form>
            ) : this.state.action == "walkReport" ? (
              <form
                method="POST"
                action="https://staging.grasslandtools.ie/api/downloadwalkreport/" //derogationreport/"
                name="reportDownload"
              >
                <input
                  type="hidden"
                  name="sessionid"
                  value={UserController.getSessionkey()}
                />
                <input
                  type="hidden"
                  name="farm_id"
                  value={this.props.farm.farm_id}
                />
                <div className="MetricGroup">
                  <div className="Metric">
                    <label>{this.trans("file_name")}:</label>
                    <input
                      className="FormInput"
                      type="text"
                      name="file_name"
                      value={this.state.report_file_name}
                      onChange={this.handleReportFileNameChange.bind(this)}
                      placeholder={this.trans('pdf_file_name')}
                    />
                  </div>
                  <div className="Metric">
                    <label>{this.trans("walk_date")}</label>
                    <DatePicker
                      name="walk_date"
                      dateFormat="DD/MM/YYYY"
                      onChange={this.handleWalkDateChange.bind(this)}
                      selected={this.state.walk_date}
                      className="FormInput"
                      placeholderText={this.trans("date")}
                      includeDates={this.props.farm.survey_dates.map(d => {
                        return moment(d);
                      })}
                      style={{
                        zIndex: "5"
                      }}
                    />
                  </div>
                  <div className="Metric">
                    <label style={{ opacity: 0 }}>Cancel</label>
                    <div
                      className="Button Action"
                      onClick={this.selectAction.bind(this, null)}
                    >
                      {this.trans("Cancel")}
                    </div>
                  </div>
                  <div className="Metric">
                    <label style={{ opacity: 0 }}>Download</label>
                    <div
                      className="Button Action"
                      onClick={this.reportDownload.bind(this, null)}
                    >
                      {this.trans("download_report")}
                    </div>
                  </div>
                </div>
              </form>
            ) : this.state.action == "derogation" ? (
              <form
                method="POST"
                action="https://staging.grasslandtools.ie/api/derogationreport/" //derogationreport/"
                name="reportDownload"
              >
                <input
                  type="hidden"
                  name="sessionid"
                  value={UserController.getSessionkey()}
                />
                <input
                  type="hidden"
                  name="farm_id"
                  value={this.props.farm.farm_id}
                />
                <div className="MetricGroup">
                  <div className="Metric">
                    <label style={{ opacity: 0 }}>Cancel</label>
                    <div
                      className="Button Action"
                      onClick={this.selectAction.bind(this, null)}
                    >
                      {this.trans("Cancel")}
                    </div>
                  </div>
                  <div className="Metric">
                    <label style={{ opacity: 0 }}>Download</label>
                    <div
                      className="Button Action"
                      onClick={this.reportDownload.bind(this, null)}
                    >
                      {this.trans("download_report")}
                    </div>
                  </div>
                </div>
              </form>
            ) : this.state.action == "pbiExport" ? (
              this.state.sent ? (
                <div>
                  <div class="PBIExportTitle">{this.trans('uploading_to_pbi___')}</div>
                  <div class="PBIExportContent">
                    {this.trans('this_could_take_a_few_mins_')}
                  </div>
                </div>
              ) : this.state.response.length > 0 ? (
                <div>
                  <div class="PBIExportTitle">
                    {this.state.success ? "Upload Completed." : "Upload Failed"}
                  </div>
                  <div class="PBIExportContent">
                    {this.state.response.map(r => (
                      <div>{r}</div>
                    ))}
                  </div>
                  <div
                    className="Button Action"
                    onClick={this.closePBIExport.bind(this)}
                  >
                    {this.trans("close")}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="MetricGroup">
                    <div className="Metric">
                      <label>{this.trans("walk_date")}</label>
                      <div className="FormInput">{this.props.cover.date}</div>
                    </div>
                    <div className="Metric">
                      <label>{this.trans("paddocks_measured")}</label>
                      <div className="FormInput">
                        {this.props.cover.padCovers.length}
                      </div>
                    </div>
                    <div className="Metric">
                      <label style={{ opacity: 0 }}>Cancel</label>
                      <div
                        className="Button Action"
                        onClick={this.selectAction.bind(this, null)}
                      >
                        {this.trans("Cancel")}
                      </div>
                    </div>
                    <div className="Metric">
                      <label style={{ opacity: 0 }}>Download</label>
                      <div
                        className="Button Action"
                        onClick={this.pbiExport.bind(this, null)}
                      >
                        {this.trans("send_to_pbi")}
                      </div>
                    </div>
                  </div>
                  <div class="PBIExportContent">
                    {this.trans("pbi_export_warning_1")}
                  </div>
                  <div class="PBIExportContent">
                    {this.trans("pbi_export_warning_2")}
                  </div>
                </div>
              )
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default OutputsPanel;  
