import React, { Component } from 'react';
import Spinner from './img/Spinner.png';
import Translation from './Translation';

class Loading extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    return (
      <div className={'Body'}>
        <div className="Loading">
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{trans(this.props.text)}...</div>
        </div>
      </div>
    );
  }
}

export default Loading;
