import React, { Component } from 'react';
import { min, max } from 'd3-array';
import { axisLeft, axisBottom } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import Translation from './Translation';
import OverviewWedges from './OverviewWedges';

// import moment from 'moment-with-locales-es6';

import Formulae from './Formulae';

import './Dashboard.css';
class WedgeSVG extends Component {
  constructor(props) {
    super(props);
    this.createGrassWedge = this.createGrassWedge.bind(this);
    this.state = {
      popover_toggled: false
    };

    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
  }

  togglePopover() {
    this.setState({
      popover_toggled: !this.state.popover_toggled
    });
  }

  componentDidMount() {
    const chart = document.getElementById('GrassWedge');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createGrassWedge();
    window.addEventListener('resize', this.createGrassWedge);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createFarmMap);
  }

  createGrassWedge() {
    // Remove old tooltip
    select('.tooltip').remove();
    const setPaddock = this.props.setPaddock;

    const chart = document.getElementById('GrassWedge');
    if (!chart) {
      return;
    }
    const w = chart.clientWidth;
    const h = chart.clientHeight;

    // Define margins
    const margin = {
      top: 35,
      right: 30,
      bottom: 50,
      left: 55
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    if (!document.getElementById('GrassWedgeSVG')) {
      return;
    }
    document.getElementById('GrassWedgeSVG').innerHTML = '';

    const svg = select('#GrassWedgeSVG')
      .append('svg')
      .attr('width', w)
      .attr('height', h);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', w)
      .attr('height', h)
      .attr('fill', '#ffffff00')
      .on('click', function() {
        setPaddock(null);
      });

    const COVER_TYPE = this.props.coverType;
    const DEMAND = this.props.demand;
    const TARGET_START_COVER = parseFloat(this.props.entryCover);
    const TARGET_RESIDUAL = this.props.residual;
    const TARGET_EXIT_COVER =
      COVER_TYPE === '2'
        ? 1500
        : 0;
    const ROTATION_LENGTH = this.props.rotLength;

    const n_days = this.props.n_days;

    const group = this.props.group;
    const tol = group === 1 || group === 5 ? 3 : 0;
    const cover = this.props.cover;

    let padCovers;
    if (cover) {
      padCovers = cover.padCovers
        .slice()
        .sort((a, b) => a.survey_id - b.survey_id);
      // .reverse();
    } else {
      padCovers = [];
    }

    // Get paddocks
    const paddocks = this.props.paddocks;

    const pad = this.props.paddock;

    let dataset = [];
    let unmeasured = [];
    let pad_count = 0;
    let whole_farm_area = 0;
    let block_area = 0;
    for (let i = 0; i < paddocks.length; i++) {
      let paddock = paddocks[i];
      if ((paddock.status >= group && paddock.status <= group + tol) || (group == 1 && paddock.status == 7)) {
        whole_farm_area += paddock.area;
        if (
          this.props.displayBlock <= 0 ||
          paddock.block === this.props.displayBlock
        ) {
          block_area += paddock.area;
          let p = null;
          for (let j = 0; j < padCovers.length; j++) {
            if (padCovers[j].paddock_id === paddock.id) {
              const survey = padCovers[j];
              const area = Math.max(paddock.area, 1);
              // const cover_ha = (survey.manualCover !== null && !isNaN(survey.manualCover))
              //   ? survey.manualCover
              //   : COVER_TYPE === '2' ? Formulae.totalCover(Math.max(survey.avgHeight, 0), 0, survey.dmPercentage)
              //     : Formulae.availableCover(Math.max(survey.avgHeight, 0), TARGET_RESIDUAL, survey.dmPercentage);
              // const cover_pd = cover_ha * area / 10000;

              const cover_ha =
                survey.manualCover !== null && !isNaN(survey.manualCover)
                  ? survey.manualCover
                  : survey.dmHectare;
              const cover_pd =
                survey.manualCover !== null && !isNaN(survey.manualCover)
                  ? survey.manualCover * area / 10000
                  : survey.dmPaddock;
              if (!isNaN(cover_ha) && !isNaN(cover_pd)) {
                p = {
                  name: paddock.name,
                  area: area,
                  survey: {
                    cover_proj: (paddock.status == 7 ? TARGET_EXIT_COVER : cover_ha) + n_days * cover.hectareGrowth,
                    cover_ha: (paddock.status == 7 ? TARGET_EXIT_COVER : cover_ha),
                    cover_pd: (paddock.status == 7 ? Math.round(TARGET_EXIT_COVER*area/10000) : cover_pd),
                    survey_date: cover.date,
                    avg_height:
                      survey.manualCover !== null ? 'N/A' : survey.avgHeight
                  }
                };
              }
              break;
            }
          }
          if (p !== null) {
            dataset.push(p);
          } else {
            unmeasured.push({
              name: paddock.name,
              area: Math.max(paddock.area, 0.01),
              survey: {
                cover_ha: TARGET_EXIT_COVER,
                cover_pd: 0
              }
            });
          }
          pad_count++;
        }
      }
    }

    const block_ratio = whole_farm_area > 0 ? block_area / whole_farm_area : 0;

    if (pad_count === 0) {
      svg
        .append('text')
        .text(this.trans('no_paddocks_found_in_selected_group_wedge'))
        .attr('class', 'svgMessage')
        .attr('x', w / 2)
        .attr('y', margin.top + height / 2);

      svg
        .append('text')
        .text(this.trans('grass_wedge'))
        .attr('x', w / 2)
        .attr('y', margin.top - 5)
        .attr('class', 'ChartHeading')
        .attr('text-anchor', 'middle');
      return;
    }

    dataset.sort(function(a, b) {
      return (
        b.survey.cover_ha +
        n_days * cover.hectareGrowth -
        (a.survey.cover_ha + n_days * cover.hectareGrowth)
      );
    });

    for (let i = 0; i < unmeasured.length; i++) {
      dataset.push(unmeasured[i]);
    }

    let totalArea = 0;
    let measuredArea = 0;
    let xArray = [];
    for (let paddock of dataset) {
      xArray.push(totalArea);
      totalArea += paddock.area;
      if (paddock.survey.survey_date) {
        measuredArea += paddock.area;
      }
    }
    if (measuredArea === 0) {
      measuredArea = 1;
    }
    xArray.push(totalArea);

    const DEMAND_COVER =
      (DEMAND * ROTATION_LENGTH / totalArea * 10000 +
        TARGET_EXIT_COVER) *
      block_ratio;

    const maxCover = max(dataset, function(d) {
      if (d.survey.survey_date) {
        return d.survey.cover_ha;
      } else {
        return 1000;
      }
    });

    const mxCover = dataset[0].survey.cover_ha;
    const mnCover = dataset[dataset.length - 1].survey.cover_ha;
    const delta = (mxCover - mnCover) / 2;
    let totalCover = 0;
    for (let i = 0; i < dataset.length; i++) {
      const d = dataset[i];
      if (!isNaN(d.survey.cover_pd)) {
        totalCover += d.survey.cover_pd;
      }
    }
    const averageCover = totalCover / measuredArea * 10000;

    const minY =
      COVER_TYPE === '2'
        ? 0
        : Formulae.availableCover(
            0,
            TARGET_RESIDUAL,
            padCovers.length > 0 ? padCovers[0].dmPercentage : 17
          );

    let maxLine = group == 1 ? Math.max(DEMAND_COVER, averageCover + delta) : averageCover + delta;

    const padScaleMagic = (x, px) => {
      const c1 = px / height;
      return (c1 * minY - x) / (c1 - 1);
    };

    maxLine = Math.max(
      padScaleMagic(maxLine, 15),
      padScaleMagic(TARGET_START_COVER, 15)
    );

    let maxY = Math.max(maxCover, maxLine);

    const xScale = scaleLinear()
      .range([0, width])
      .domain([0, totalArea]);

    const yScale = scaleLinear()
      .range([height, 0])
      .domain([minY, maxY]);

    svg
      .append('g')
      .attr('class', 'grid')
      .attr('transform', 'translate(' + margin.left + ', ' + margin.top + ')')
      .call(
        axisLeft(yScale)
          .tickSize(-width)
          .tickFormat('')
      );

    const minPaddockWidth = min(dataset, d => {
      return xScale(d.area);
    });
    const paddingWidth = Math.min(3, width / 80);

    // if (cover) {
    //   svg
    //     .selectAll('proj')
    //     .data(dataset)
    //     .enter()
    //     .append('rect')
    //     .attr('x', (d, i) => {
    //       return margin.left + xScale(xArray[i]) + paddingWidth;
    //     })
    //     .attr('y', d => {
    //       return margin.top + yScale(d.survey.cover_proj);
    //     })
    //     .attr('width', d => {
    //       return xScale(d.area) - paddingWidth;
    //     })
    //     .attr('height', d => {
    //       return Math.max(0, height - yScale(d.survey.cover_proj));
    //     })
    //     .attr('class', d => {
    //       if (d.survey.survey_date) {
    //         return 'WedgeProj';
    //       } else {
    //         return 'WedgeProj unmeasured';
    //       }
    //     });
    // }
    svg
      .selectAll('current')
      .data(dataset)
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        return margin.left + xScale(xArray[i]) + paddingWidth;
      })
      .attr('y', d => {
        return margin.top + yScale(d.survey.cover_ha);
      })
      .attr('width', d => {
        return xScale(d.area) - paddingWidth;
      })
      .attr('height', d => {
        return Math.max(0, height - yScale(d.survey.cover_ha));
      })
      .attr('class', d => {
        let str = 'WedgeBar';
        if (!d.survey.survey_date) {
          str += ' unmeasured';
        }
        if (pad && d.name === pad.name) {
          str += ' active';
        }
        return str;
      })
      // .style('fill', function(d) {   if (d.survey.survey_date) {     if (pad &&
      // d.name === pad.name) {       return null;     } else {       return
      // color(d.survey.dm_hectare);     }   } })
      .on('mouseover', (d, i) => {
        // Remove old tooltip
        select('.tooltip').remove();
        if (!d.survey.survey_date) {
          const tooltip = select('#GrassWedge')
            .append('div')
            .attr('class', 'tooltip')
            .style(
              'left',
              margin.left + xScale((xArray[i] + xArray[i + 1]) / 2) - 55 + 'px'
            )
            .style('top', margin.top + yScale(d.survey.cover_ha) - 52 + 'px')
            .style('width', '110px')
            .html(this.trans('paddock_not_measured'));
          tooltip
            .transition()
            .duration(250)
            .style('opacity', 1);
          return;
        }
        // Show tooltip with transition
        const tooltip = select('#GrassWedge')
          .append('div')
          .attr('class', 'tooltip')
          .style(
            'left',
            margin.left + xScale((xArray[i] + xArray[i + 1]) / 2) - 100 + 'px'
          )
          .style('top', margin.top + yScale(d.survey.cover_ha) - 91 + 'px')
          .style('width', '200px')
          .html(
            this.trans('days_grazing') +
              ': ' +
              Math.round(
                d.survey.cover_pd / (DEMAND) * 100
              ) /
                100 +
              ' <br/>' +
              this.trans('paddock_cover') +
              ': ' +
              Math.round(d.survey.cover_pd) +
              ' kg<br/>' +
              this.trans('hectare_cover') +
              ': ' +
              Math.round(d.survey.cover_ha) +
              ' kg/ha<br/>' +
              this.trans('average_height') +
              ': ' +
              d.survey.avg_height +
              ' mm'
          );
        tooltip
          .transition()
          .duration(250)
          .style('opacity', 1);
      })
      .on('mouseout', function() {
        // Fade out tooltip
        select('.tooltip')
          .transition()
          .duration(250)
          .style('opacity', 0)
          .remove();
      })
      .on('click', d => {
        paddocks.forEach(p => {
          if (p.name === d.name) {
            if (pad && d.name === pad.name) {
              setPaddock(null);
            } else {
              setPaddock(p);
            }
          }
        });
      });
    // svg
    //   .selectAll('padding')
    //   .data(dataset)
    //   .enter()
    //   .append('line')
    //   .attr('x1', (d, i) => {
    //     return margin.left + xScale(xArray[i] + d.area);
    //   })
    //   .attr('y1', d => {
    //     return margin.top + yScale(d.survey.cover_proj);
    //   })
    //   .attr('x2', (d, i) => {
    //     return margin.left + xScale(xArray[i] + d.area);
    //   })
    //   .attr('y2', height + margin.top)
    //   .attr('class', 'WedgePadding')
    //   .style('stroke-width', paddingWidth);

    svg
      .append('line')
      .attr('x1', margin.left + paddingWidth/2)
      .attr('y1', margin.top + yScale(dataset[0].survey.cover_proj))
      .attr('x2', margin.left+paddingWidth/2)
      .attr('y2', height + margin.top)
      .attr('class', 'WedgePadding')
      .style('stroke-width', paddingWidth);

    const font_size = width/45;//Math.min(minPaddockWidth * 1.3, width / 45);
    svg
      .selectAll('labels')
      .data(dataset)
      .enter()
      .append('text')
      .style('font-size', function(d, i) {
        return (Math.min(xScale((xArray[i+1] - xArray[i])*1.2), font_size) + 'px');
      })
      .attr('transform', 'rotate(-90)')
      .attr('x', -h + margin.bottom + 4)
      .attr('y', function(d, i) {
        return (
          margin.left +
          xScale((xArray[i] + xArray[i + 1]) / 2) +
          paddingWidth / 2 +
          Math.min(xScale((xArray[i+1] - xArray[i])*1.2), font_size) * 0.34
        );
      })
      .text(function(d) {
        return d.name;
      })
      .attr('class', function(d) {
        if (pad && d.name === pad.name) {
          return 'WedgeLabel active';
        } else {
          return 'WedgeLabel';
        }
      });

    const textBackdrops = svg.append('g');
    const targetLines = svg.append('g');
    if (group == 1) {
    svg
      .append('polyline')
      .attr(
        'points',
        0 +
          margin.left +
          ' ' +
          (margin.top + yScale(averageCover + delta)) +
          ',' +
          (0 + margin.left) +
          ' ' +
          (margin.top + yScale(DEMAND_COVER)) +
          ',' +
          (width + margin.left) +
          ' ' +
          (margin.top + yScale(TARGET_EXIT_COVER)) +
          ',' +
          (width + margin.left) +
          ' ' +
          (margin.top + yScale(averageCover - delta))
      )
      .attr('class', 'Difference')
      .style('pointer-events', 'none');
    }
    // Average Cover
    svg
      .append('line')
      .attr('x1', 0 + margin.left)
      .attr('y1', margin.top + yScale(averageCover + delta))
      .attr('x2', width + margin.left)
      .attr('y2', margin.top + yScale(averageCover - delta))
      .attr('stroke', '#51534a')
      .attr('stroke-width', '2px')
      .style('stroke-dasharray', '6, 6')
      .style('pointer-events', 'none');
    // Demand Line
    if (group == 1) {
    svg
      .append('line')
      .attr('x1', 0 + margin.left)
      .attr('y1', margin.top + yScale(DEMAND_COVER))
      .attr('x2', width + margin.left)
      .attr('y2', margin.top + yScale(TARGET_EXIT_COVER))
      .attr('stroke', '#c00d1e')
      .attr('stroke-width', '3px')
      .style('pointer-events', 'none');
    }

    const textBoxPadding = 8;
    let bbox;
    if (COVER_TYPE === '1') {
      svg
        .append('text')
        .attr('x', margin.left + textBoxPadding)
        .attr('y', margin.top + yScale(TARGET_EXIT_COVER) + 4)
        .attr('class', 'LegendTitle')
        .attr('id', 'target_residual_wedge_text')
        .text(
          this.trans('target_residual') +
            ' - ' +
            Math.round(TARGET_RESIDUAL * 100) / 100 +
            ' mm'
        )
        .style('pointer-events', 'none')
        .attr('text-anchor', 'start');
      bbox = select('#target_residual_wedge_text')
        .node()
        .getBBox();
        textBackdrops
          .append('rect')
          .attr('x', bbox.x - textBoxPadding / 2)
          .attr('y', margin.top + yScale(TARGET_EXIT_COVER) - 12)
          .attr('width', bbox.width + textBoxPadding)
          .attr('height', 24)
          .attr('fill', '#ffffff88');
          textBackdrops
            .append('rect')
            .attr('x', bbox.x - textBoxPadding / 2)
            .attr('y', margin.top + yScale(TARGET_EXIT_COVER) - 12)
            .attr('width', bbox.width + textBoxPadding)
            .attr('height', 24)
            .attr('class', 'TextBackdrop');

      // Exit Cover
      targetLines
        .append('line')
        .attr('x1', width + margin.left)
        .attr('x2', bbox.x + bbox.width + textBoxPadding / 2)
        .attr('y1', margin.top + yScale(TARGET_EXIT_COVER))
        .attr('y2', margin.top + yScale(TARGET_EXIT_COVER))
        .attr('stroke', '#51534a')
        .attr('stroke-width', '2px')
        .style('stroke-dasharray', '8, 8')
        .style('pointer-events', 'none');
    }
    svg
      .append('text')
      .attr('x', w - margin.right - textBoxPadding)
      .attr('y', margin.top + yScale(TARGET_START_COVER) + 4)
      .attr('class', 'LegendTitle')
      .attr('id', 'target_start_cover_wedge_text')
      .text(
        this.trans('entry_cover_svg') +
          ' - ' +
          Math.round(TARGET_START_COVER) +
          ' kg/ha'
      )
      .style('pointer-events', 'none')
      .attr('text-anchor', 'end');

    bbox = select('#target_start_cover_wedge_text')
      .node()
      .getBBox();
    textBackdrops
      .append('rect')
      .attr('id', 'startCoverBackdrop')
      .attr('x', bbox.x - textBoxPadding / 2)
      .attr('y', margin.top + yScale(TARGET_START_COVER) - 12)
      .attr('width', bbox.width + textBoxPadding)
      .attr('height', 24)
      .attr('class', 'TextBackdrop');

    // Start Cover
    targetLines
      .append('line')
      .attr('x1', 0 + margin.left)
      .attr('y1', margin.top + yScale(TARGET_START_COVER))
      .attr('x2', bbox.x - textBoxPadding / 2)
      .attr('y2', margin.top + yScale(TARGET_START_COVER))
      .attr('stroke', '#51534a')
      .attr('stroke-width', '2px')
      .style('stroke-dasharray', '8, 8')
      .style('pointer-events', 'none');

    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .call(axisLeft(yScale));

    svg
      .append('text')
      .attr('x', margin.left)
      .attr('y', margin.top - 5)
      .attr('class', 'LegendTitle')
      .text(this.trans('cover_kg_per_ha_svg'))
      .attr('text-anchor', 'start');

    const rs1 = scaleLinear()
      .range([0, width * (7 / ROTATION_LENGTH)])
      .domain([0, 7]);

    const rs2 = scaleLinear()
      .range([width * (7 / ROTATION_LENGTH), width])
      .domain([7, ROTATION_LENGTH]);

    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr(
        'transform',
        'translate(' + margin.left + ',' + (margin.top + height) + ')'
      )
      .call(axisBottom(rs1).ticks(7));
    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr(
        'transform',
        'translate(' + margin.left + ',' + (margin.top + height) + ')'
      )
      .call(axisBottom(rs2));

    // Legend Demand Line
    svg
      .append('line')
      .attr('x1', margin.left)
      .attr('y1', h - 12)
      .attr('x2', 18 + margin.left)
      .attr('y2', h - 12)
      .attr('stroke', '#c00d1e')
      .attr('stroke-width', '3px')
      .style('pointer-events', 'none');
    svg
      .append('text')
      .text(this.trans('demand_line_svg'))
      .attr('id', 'demand_line_legend')
      .attr('x', 23 + margin.left)
      .attr('y', h - 7)
      .attr('class', 'LegendTitle')
      .attr('text-anchor', 'start');
    bbox = select('#demand_line_legend')
      .node()
      .getBBox();
    // Average Cover
    let shift = bbox.width + bbox.x + 15;
    svg
      .append('line')
      .attr('x1', shift)
      .attr('y1', h - 12)
      .attr('x2', shift + 18)
      .attr('y2', h - 12)
      .attr('stroke', '#407ec9')
      .attr('stroke-width', '2px')
      .style('stroke-dasharray', '6, 6')
      .style('pointer-events', 'none');
    svg
      .append('text')
      .text(this.trans('average_cover_svg'))
      .attr('x', 23 + shift)
      .attr('y', h - 7)
      .attr('class', 'LegendTitle')
      .attr('text-anchor', 'start');

    if (!this.props.mobile && group == 1) {
    // const avgBox = svg.append('g').style('fill', 'red');
    // const avgBoxBackdrop = avgBox.append('g');
    // avgBox
    //   .append('text')
    //   .text(this.trans('cover_per_animal_svg'))
    //   .attr('x', textBoxPadding / 2)
    //   .attr('y', 15)
    //   .attr('class', 'LegendTitle')
    //   .attr('text-anchor', 'start');

    // avgBox
    //   .append('text')
    //   .text(this.trans('days_ahead_svg'))
    //   .attr('x', textBoxPadding / 2)
    //   .attr('y', 30)
    //   .attr('class', 'LegendTitle')
    //   .attr('text-anchor', 'start');

    // avgBox
    //   .append('text')
    //   .text(
    //     Math.round(totalCover / N_ANIMALS * 100 / block_ratio) / 100 + ' kg'
    //   )
    //   .attr('x', textBoxPadding / 2 + 160)
    //   .attr('y', 15)
    //   .attr('class', 'LegendTitle')
    //   .attr('text-anchor', 'middle');

    // avgBox
    //   .append('text')
    //   .text(
    //     Math.round(totalCover / DEMAND * 100 / block_ratio) /
    //       100 +
    //       ' ' +
    //       this.trans('days')
    //   )
    //   .attr('x', textBoxPadding / 2 + 160)
    //   .attr('y', 30)
    //   .attr('class', 'LegendTitle')
    //   .attr('text-anchor', 'middle');

    // bbox = avgBox.node().getBBox();

    // avgBoxBackdrop
    //   .append('rect')
    //   .attr('x', bbox.x - textBoxPadding / 2)
    //   .attr('y', 0)
    //   .attr('width', bbox.width + textBoxPadding)
    //   .attr('height', 37)
    //   .attr('class', 'TextBackdrop');

    // const startCoverBBox = select('#startCoverBackdrop')
    //   .node()
    //   .getBBox();

    // avgBox.attr(
    //   'transform',
    //   'translate(' +
    //     (startCoverBBox.x +
    //       startCoverBBox.width -
    //       (bbox.width + bbox.x + textBoxPadding / 2)) +
    //     ',' +
    //     (margin.top + 4) +
    //     ')'
    // );
    }


    // Add heading.
    if (this.props.mobile) {
      svg
        .append('text')
        .text(this.trans('grass_wedge'))
        .attr('x', w -margin.right)
        .attr('y', margin.top - 5)
        .attr('class', 'ChartHeading')
        .attr('text-anchor', 'end')
        .style('pointer-events', 'none');
    } else {
      svg
        .append('text')
        .text(this.trans('grass_wedge'))
        .attr('x', w / 2)
        .attr('y', margin.top - 5)
        .attr('class', 'ChartHeading')
        .attr('text-anchor', 'middle')
        .style('pointer-events', 'none');
        // Axis title
        svg
          .append('text')
          .text(this.trans('rotation_length') + ' (' + this.trans('days') + ')')
          .attr('x', w - margin.right)
          .attr('y', h - 7)
          .attr('class', 'LegendTitle')
          .attr('text-anchor', 'end');

        // var downloadButtonSize = margin.top*.75;
        // svg.append('image')
        //   .attr('xlink:href', Download)
        //   .attr('width', downloadButtonSize)
        //   .attr('height', downloadButtonSize)
        //   .attr('x', w - downloadButtonSize/3 - downloadButtonSize)
        //   .attr('y', downloadButtonSize/3)
        //   .style("cursor","pointer")
        //   .on("click", async function() {
        //     const canvas = preset.createCanvas(w, h);
        //     const ctx = canvas.getContext('2d');
            
        //     const v = Canvg.fromString(ctx, document.getElementById('GrassWedgeSVG').innerHTML, preset);
  
        //     await v.render();
  
        //     const png = canvas.toDataURL();
  
        //     var image = new Image(w, h);
        //     image.src = png;
    
        //     var newWindow = window.open("");
        //     newWindow.document.write(image.outerHTML);
        //     newWindow.document.close();
        //   });
    }

    // if (!this.props.editingWedge) {
    //   svg
    //     .append('image')
    //     .attr('xlink:href', Gear)
    //     .attr('width', 20)
    //     .attr('height', 20)
    //     .attr('x', margin.left + width - 30)
    //     .attr('y', 30)
    //     .style('cursor', 'pointer')
    //     .on('click', this.props.setEditingWedge.bind(this));
    // }
  }

  render() {
    this.createGrassWedge();
    return (
      <div
        id="GrassWedgeSVG"
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    );
  }
}

class GrassWedge extends Component {
  render() {
    // const blockSet = new Set();
    // for (var p of this.props.paddocks) {
    //   blockSet.add(p.block);
    // }
    // const activeBlocks = this.props.farm.blocks
    //   .map((b, i) => {
    //     return {
    //       name: b,
    //       number: i + 1
    //     };
    //   })
    //   .filter((b, i) => blockSet.has(b.number));
    // activeBlocks.sort((a, b) => ('' + a.name).localeCompare(b.name));
    // const blocksTabs = activeBlocks.map(b => (
    //   <div
    //     onClick={this.props.handleBlockChange.bind(this, {
    //       target: { value: b.number }
    //     })}
    //     className={
    //       this.props.displayBlock === b.number ? 'WedgeTab active' : 'WedgeTab'
    //     }
    //     key={b.number}
    //   >
    //     {b.name}
    //   </div>
    // ));

    return (
      <div id="GrassWedge" className={this.props.className}>
        {this.props.displayBlock >= 0 ? (
          <WedgeSVG
            editingWedge={this.props.editingWedge}
            coverType={this.props.coverType}
            rotLength={this.props.rotLength}
            nAnimals={this.props.nAnimals}
            targetIntake={this.props.targetIntake}
            entryCover={this.props.entryCover}
            residual={this.props.residual}
            setEditingWedge={this.props.setEditingWedge}
            cover={this.props.cover}
            paddocks={this.props.paddocks}
            display={this.props.display}
            paddock={this.props.paddock}
            setPaddock={d => this.props.setPaddock(d, null, 'grass_wedge')}
            group={this.props.displayGroup}
            disp_date={this.props.disp_date}
            inc_days={this.props.inc_days}
            n_days={this.props.n_days}
            demand={this.props.demand}
            lu_count={this.props.lu_count}
            feed_cost={this.props.feed_cost}
            displayBlock={this.props.displayBlock}
            mobile={this.props.mobile}
          />
        ) : (
          <OverviewWedges
            farm={this.props.farm}
            editingWedge={this.props.editingWedge}
            coverType={this.props.coverType}
            rotLength={this.props.rotLength}
            nAnimals={this.props.nAnimals}
            targetIntake={this.props.targetIntake}
            entryCover={this.props.entryCover}
            residual={this.props.residual}
            setEditingWedge={this.props.setEditingWedge}
            cover={this.props.cover}
            paddocks={this.props.paddocks}
            display={this.props.display}
            paddock={this.props.paddock}
            setPaddock={d => this.props.setPaddock(d, null, 'grass_wedge')}
            displayGroup={this.props.displayGroup}
            disp_date={this.props.disp_date}
            inc_days={this.props.inc_days}
            n_days={this.props.n_days}
            demand={this.props.demand}
            lu_count={this.props.lu_count}
            feed_cost={this.props.feed_cost}
            displayBlock={this.props.displayBlock}
          />
        )}
        {/* <div
          style={{
            position: 'absolute',
            left: 55 - 2,
            top: 4,
            display: 'flex'
          }}
        >
          <div
            onClick={this.props.handleBlockChange.bind(this, {
              target: { value: 0 }
            })}
            className={
              this.props.displayBlock === 0 ? 'WedgeTab active' : 'WedgeTab'
            }
          >
            Whole Farm
          </div>
          <div
            onClick={this.props.handleBlockChange.bind(this, {
              target: { value: -1 }
            })}
            className={
              this.props.displayBlock === -1 ? 'WedgeTab active' : 'WedgeTab'
            }
          >
            Overview
          </div>
          {blocksTabs}
        </div>
        <div
          style={{
            position: 'absolute',
            right: 30 + 2,
            top: 27,
            display: 'flex'
          }}
        > */}
        {/* {this.props.mobile ? null : <div
          onClick={this.props.setEditingWedge.bind(this)}
          className="WedgeTab"
        >
          {'Export'}
        </div>}
        {this.props.mobile ? null : <div
            onClick={this.props.setEditingWedge.bind(this)}
            className="WedgeTab"
          >
            {this.props.editingWedge ? 'Close' : 'Edit Wedge'}
          </div>} */}
        {/* </div> */}
      </div>
    );
  }
}

export default GrassWedge;
