import React, { Component } from "react";
import axios from "axios";

import Translation from "./Translation";
import "./App.css";

import Header from "./Header";

import UserController from "./UserController";

import LoginController from "./LoginController";
import OverviewController from "./OverviewController";
import ToolsetController from "./ToolsetController";

import LoadingScreen from "./LoadingScreen";
import SplashPage from "./SplashPage";

import Loading from "./Loading";

import { detect } from "detect-browser";
import WeightController from "./WeightController";
import HomePageController from "./HomePageController";
import LandingPage from "./LandingPage";
import SellingPage from "./SellingPage";

class App extends Component {
  constructor() {
    super();
    this.state = {
      loadingScreen: false,
      loadingMessage: "",
      splashPage: true,
      userLoggedIn: false,
      herds: [],
      herd: null,
      farms: [],
      farm: null,
      user_type: null,
      user_data: null,
      view: null,
      menu_shown: false,
      display: "",
      dataset: {},
      soil_data: {},
      soil_data_loaded: false,
      cultivars: [],
      fertilisers: [],
      appkey: 0,
      map_has_unsaved_changes: false,
      mobile: null,
      getDatasetIndex: 0,
      paidUp: null,
      enabledPackages: [],
      maxFarms: 0,
      maxHerds: 0,
      farmCount: 0,
      herdCount: 0,
    };
    this.trans = Translation.getInstance().translate;
  }
  logout() {
    localStorage.removeItem("sessionid");
    localStorage.removeItem("farmid");
    localStorage.removeItem("herdid");
    UserController.setFarmid("");
    UserController.setPassword("");
    UserController.setSessionkey("");
    UserController.setUsername("");
    UserController.setUserType("");
    this.setState({
      userLoggedIn: false,
      farms: [],
      farm: null,
      user_type: null,
      view: null
    });
  }
  setDisplay(disp) {
    if (
      this.state.display === "map_editor" &&
      this.state.map_has_unsaved_changes
    ) {
      if (window.confirm(this.trans("map_editor_close_warning"))) {
        this.setState({
          display: disp,
          menu_shown: false,
          map_has_unsaved_changes: false
        });
      } else {
        return;
      }
    } else {
      this.setState({ display: disp, menu_shown: false });
    }
  }
  toggleMenu() {
    this.setState({
      menu_shown: !this.state.menu_shown
    });
  }
  setAppState(dict) {
    this.setState(dict);
  }
  componentWillMount() {
    var self = this;
    setTimeout(() => {
      self.setState({ splashPage: false });
    }, 1000);

    if (true && localStorage.getItem("sessionid")) {
      axios
        .post("https://staging.grasslandtools.ie/api/continuesession/", {
          sessionid: localStorage.getItem("sessionid")
        })
        .then(async function(response) {
          if (response.data.sessionid) {
            UserController.setSessionkey(response.data.sessionid);
            localStorage.setItem("sessionid", response.data.sessionid);
            self.setState({ userLoggedIn: true });

            const getParamaters = window.location.search.split("&");
            let access_token = "";
            let refresh_token = "";
            for (let param of getParamaters) {
              if (param.indexOf("access_token") >= 0) {
                access_token = param.split("=")[1];
              } else if (param.indexOf("refresh_token") >= 0) {
                refresh_token = param.split("=")[1];
              }
            }
            if (access_token && refresh_token) {
              axios
                .post("https://staging.grasslandtools.ie/api/v2/linkicbf/", {
                  sessionid: localStorage.getItem("sessionid"),
                  herdid: localStorage.getItem("icbfLinkHerdID"),
                  access_token: access_token,
                  refresh_token: refresh_token
                })
                .then(function(response) {
                  localStorage.removeItem("icbfLinkHerdID");
                  alert("Successfully linked this herd to your ICBF account.");
                  window.location.search = "";
                })
                .catch(function(error) {
                  localStorage.removeItem("icbfLinkHerdID");
                  alert("Error linking this herd to your ICBF account.");
                  window.location.search = "";
                });
            }
            self.getDataset();
          } else {
            self.setState({ userLoggedIn: false });
          }
        })
        .catch(function(error) {
          self.setState({ userLoggedIn: false });
        });
    } else {
      self.setState({ userLoggedIn: false });
    }
  }

  changeFarm() {
    this.getDataset(null, "Overview", null);
  }

  incrementKey() {
    this.setState({
      appkey: this.state.appkey + 1
    });
  }

  setFarms(farms) {
    this.setState({ farms: farms });
  }

  async getDataset(farmid, view, herdid) {
    // Remember last loaded farm on refresh - a bit hacky
    if (view !== "Overview") {
      if (!(farmid === undefined || farmid === null)) {
        localStorage.setItem("farmid", farmid);
      }
      if (
        (farmid === undefined || farmid === null) &&
        localStorage.getItem("farmid")
      ) {
        farmid = localStorage.getItem("farmid");
      }
    } else {
      localStorage.removeItem("farmid");
    }
    if (view !== "Overview") {
      if (!(herdid === undefined || herdid === null)) {
        localStorage.setItem("herdid", herdid);
      }
      if (
        (herdid === undefined || herdid === null) &&
        localStorage.getItem("herdid")
      ) {
        herdid = localStorage.getItem("herdid");
      }
    } else {
      localStorage.removeItem("herdid");
    }

    this.setState({
      loadingScreen: true,
      loadingMessage: this.trans("farm_data_loading")
    });
    var self = this;

    try {
      var paymentStatusResponse = await axios.post(
        "https://staging.grasslandtools.ie/api/v2/checkpaymentstatus/",
        {
          sessionid: UserController.getSessionkey()
        }
      );
      UserController.setUserType(paymentStatusResponse.data.user_type);
      // UserController.setUserType("Advisor");
      console.log(paymentStatusResponse.data);
      if (paymentStatusResponse.data.status == "ACTIVE") {
        const enabledPackages = [];
        if (paymentStatusResponse.data.packages.includes("ALL")) {
          enabledPackages.push("Grass");
          enabledPackages.push("Weighing");
        } else {
          if (paymentStatusResponse.data.packages.includes("GRASS")) {
            enabledPackages.push("Grass");
          }
          if (paymentStatusResponse.data.packages.includes("WEIGHING")) {
            enabledPackages.push("Weighing");
          }
        }
        this.setState({
          enabledPackages: enabledPackages,
          maxFarms: 5+paymentStatusResponse.data.extra_farms,
          maxHerds: 5+paymentStatusResponse.data.extra_herds,
          paidUp: true
        });
      } else if (paymentStatusResponse.data.status == "EXEMPT") {
        const enabledPackages = [];
        enabledPackages.push("Grass");
        enabledPackages.push("Weighing");
        this.setState({
          enabledPackages: enabledPackages,
          maxFarms: Number.MAX_SAFE_INTEGER,
          maxHerds: Number.MAX_SAFE_INTEGER,
          paidUp: true
        });
      } else {
        this.setState({
          paidUp: false,
          loadingScreen: false,
          farmCount: paymentStatusResponse.data.farm_count,
          herdCount: paymentStatusResponse.data.herd_count,
        });
        return;
      }
    } catch (error) {
      self.setState({ userLoggedIn: false, loadingScreen: false });
      return;
    }

    var index = this.state.getDatasetIndex + 1;
    self.setState({ getDatasetIndex: index });

    axios
      .get("https://staging.grasslandtools.ie/api/react/", {
        params: {
          sessionid: UserController.getSessionkey(),
          farmid: farmid,
          herdid: herdid,
          view: view,
          // last: true
        }
      })
      .then(function(response) {
        if (response.data.view === "Overview") {
          UserController.setUserType(response.data.user_type);
          if (response.data.user_type === "Farmer") {
            UserController.setSurveyMethod(response.data.survey_method);
          }
          UserController.setStripe(response.data.user_data.stripe);
          UserController.setFarmLimit(response.data.user_data.farmLimit);
          self.setState({
            loadingScreen: false,
            loadingMessage: "",
            farms: response.data.farms,
            herds: response.data.herds,
            user_type: response.data.user_type,
            user_data: response.data.user_data,
            view: "Overview"
          });
        } else if (response.data.view === "Toolset") {
          UserController.setUserType(response.data.user_type);
          if (response.data.user_type === "Farmer") {
            UserController.setSurveyMethod(response.data.survey_method);
          }
          UserController.setFarmid(response.data.farm.farm_id);
          UserController.setElectricityDevice(
            response.data.farm.electricity_device
          );
          self.setState({
            loadingScreen: false,
            loadingMessage: "",
            farm: response.data.farm,
            user_type: response.data.user_type,
            user_data: response.data.user_data,
            view: "Toolset"
          });
        } else if (response.data.view === "Weight") {
          UserController.setUserType(response.data.user_type);
          if (response.data.user_type === "Farmer") {
            UserController.setSurveyMethod(response.data.survey_method);
          }
          UserController.setHerdid(response.data.herd.herdid);
          self.setState({
            loadingScreen: false,
            loadingMessage: "",
            herd: response.data.herd,
            user_type: response.data.user_type,
            view: "Weight"
          });
        }
      })
      .catch(function(error) {
        self.setState({
          loadingScreen: true,
          loadingMessage: self.trans("server_error.")
        });
      });
  }

  render() {
    let sidebarLinks = [];
    let body = null;
    if (this.state.mobile == null) {
      const browser = detect();
      const availableOss = ["Mac OS", "Windows", "Linux"];
      let mobile = true;
      for (let os of availableOss) {
        if (browser.os.indexOf(os) !== -1) {
          mobile = false;
          break;
        }
      }
      if (mobile) {
        this.setState({ mobile: mobile });
      }
    }
    if (this.state.loadingScreen) {
      return (
        <div
          className="App"
          style={{
            background: "",
            height: "auto",
            minHeight: "100vh"
          }}
        >
          <LoadingScreen
            setAppState={this.setAppState.bind(this)}
            getDataset={this.getDataset.bind(this)}
            message={this.state.loadingMessage}
            setDisplay={this.setDisplay.bind(this)}
          />
        </div>
      );
    } else if (this.state.splashPage) {
      return (
        <div
          className="App"
          style={{
            background: "",
            height: "auto",
            minHeight: "100vh"
          }}
        >
          <SplashPage
            setAppState={this.setAppState.bind(this)}
            getDataset={this.getDataset.bind(this)}
            display={this.state.display}
            setDisplay={this.setDisplay.bind(this)}
          />
        </div>
      );
    } else if (!this.state.userLoggedIn) {
      return (
        <div
          className="App"
          style={{
            background: "",
            height: "auto",
            minHeight: "100vh"
          }}
        >
          <LandingPage
            incrementKey={this.incrementKey.bind(this)}
            setAppState={this.setAppState.bind(this)}
            getDataset={this.getDataset.bind(this)}
            display={this.state.display}
            setDisplay={this.setDisplay.bind(this)}
            logout={this.logout.bind(this)}
            userLoggedIn={this.state.userLoggedIn}
          />
        </div>
      );
    } else if (!this.state.paidUp) {
      return (
        <SellingPage
          incrementKey={this.incrementKey.bind(this)}
          setAppState={this.setAppState.bind(this)}
          getDataset={this.getDataset.bind(this)}
          display={this.state.display}
          setDisplay={this.setDisplay.bind(this)}
          logout={this.logout.bind(this)}
          userLoggedIn={this.state.userLoggedIn}
          farmCount={this.state.farmCount}
          herdCount={this.state.herdCount}
        />
      );
    } else {
      if (this.state.view === "Overview") {
        if (UserController.getUserType() === "Admin") {
          sidebarLinks = [
            {
              title: "farm_selector",
              className: "Major Item"
            },
            {
              title: "find_farm",
              className: "Major Item"
            },
            {
              title: "account_settings",
              className: "Major Item"
            }
          ];
          body = (
            <OverviewController
              setFarms={this.setFarms.bind(this)}
              display={this.state.display}
              farms={this.state.farms}
              herds={this.state.herds}
              user_type={this.state.user_type}
              setDisplay={this.setDisplay.bind(this)}
              getDataset={this.getDataset.bind(this)}
              setAppState={this.setAppState.bind(this)}
              user_data={this.state.user_data}
              mobile={this.state.mobile}
            />
          );
        } else if (UserController.getUserType() === "CountryAdmin") {
          sidebarLinks = [
            {
              title: "farm_selector",
              className: "Major Item"
            },
            {
              title: "account_settings",
              className: "Major Item"
            }
          ];
          body = (
            <OverviewController
              setFarms={this.setFarms.bind(this)}
              display={this.state.display}
              farms={this.state.farms}
              herds={this.state.herds}
              user_type={this.state.user_type}
              setDisplay={this.setDisplay.bind(this)}
              getDataset={this.getDataset.bind(this)}
              setAppState={this.setAppState.bind(this)}
              user_data={this.state.user_data}
              mobile={this.state.mobile}
            />
          );
        } else if (UserController.getUserType() === "Farmer") {
          sidebarLinks = [
            {
              title: "home",
              className: "Major Item"
            },
            {
              title: "account_settings",
              className: "Major Changeitem"
            }
          ];
          body = (
            <HomePageController
              setFarms={this.setFarms.bind(this)}
              display={this.state.display}
              farms={this.state.farms}
              herds={this.state.herds}
              user_type={this.state.user_type}
              setDisplay={this.setDisplay.bind(this)}
              getDataset={this.getDataset.bind(this)}
              setAppState={this.setAppState.bind(this)}
              user_data={this.state.user_data}
              mobile={this.state.mobile}
              enabledPackages={this.state.enabledPackages}
              maxFarms={this.state.maxFarms}
              maxHerds={this.state.maxHerds}
            />
          );
        } else {
          sidebarLinks = [
            {
              title: "home",
              className: "Major Item"
            },
            {
              title: "account_settings",
              className: "Major Changeitem"
            }
          ];
          body = (
            <HomePageController
              setFarms={this.setFarms.bind(this)}
              display={this.state.display}
              farms={this.state.farms}
              herds={this.state.herds}
              user_type={this.state.user_type}
              setDisplay={this.setDisplay.bind(this)}
              getDataset={this.getDataset.bind(this)}
              setAppState={this.setAppState.bind(this)}
              user_data={this.state.user_data}
              mobile={this.state.mobile}
              enabledPackages={this.state.enabledPackages}
              maxFarms={this.state.maxFarms}
              maxHerds={this.state.maxHerds}
            />
          );
        }
      } else if (this.state.view === "Toolset") {
        sidebarLinks = [
          {
            title: "grass_dashboard",
            className: "Major Item"
          },
          {
            title: "weather",
            className: "Major Item"
          },
          {
            title: "soil_analysis",
            className: "Major Item"
          },
          {
            title: "map_editor",
            className: "Major Item"
          },
          {
            title: "home",
            className: "Major Changeitem",
            onClick: this.changeFarm.bind(this)
          }
        ];
        body = (
          <ToolsetController
            menu_shown={this.state.menu_shown}
            toggleMenu={this.toggleMenu.bind(this)}
            setAppState={this.setAppState.bind(this)}
            farmid={this.state.farmid}
            logout={this.logout.bind(this)}
            getDataset={this.getDataset.bind(this)}
            farm={this.state.farm}
            appkey={this.state.appkey}
            user_type={this.state.user_type}
            display={this.state.display}
            setDisplay={this.setDisplay.bind(this)}
            mobile={this.state.mobile}
            user_data={this.state.user_data}
          />
        );
      } else if (this.state.view === "Weight") {
        sidebarLinks = [
          {
            title: "weight_dashboard",
            className: "Major Item"
          },
          {
            title: "home",
            className: "Major Changeitem",
            onClick: this.changeFarm.bind(this)
          }
        ];
        body = (
          <WeightController
            setAppState={this.setAppState.bind(this)}
            getDataset={this.getDataset.bind(this)}
            herd={this.state.herd}
            appkey={this.state.appkey}
            user_type={this.state.user_type}
            display={this.state.display}
            setDisplay={this.setDisplay.bind(this)}
          />
        );
      } else {
        body = <Loading text={"loading_farm_data"} />;
      }
    }
    return (
      <div key={this.state.appkey} className="App">
        <div className={this.state.mobile ? "Main mobile" : "Main"}>
          <Header
            incrementKey={this.incrementKey.bind(this)}
            setDisplay={this.setDisplay.bind(this)}
            display={this.state.display}
            toggleMenu={this.toggleMenu.bind(this)}
            logout={this.logout.bind(this)}
            userLoggedIn={this.state.userLoggedIn}
            mobile={this.state.mobile}
            links={sidebarLinks}
            goHome={this.changeFarm.bind(this)}
          />{" "}
          {body}
        </div>
      </div>
    );
  }
}

export default App;
