import React, { Component } from 'react';
import Translation from './Translation';

import moment from 'moment-with-locales-es6';
import axios from 'axios';
import Drop from './img/Drop.svg';
import './Dashboard.css';
import UserController from './UserController';
import Spinner from './img/Spinner.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
class PaddockFertiliser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      entering: false,
      gotData: false,
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }
  setEntering(value) {
    this.setState({entering: value})
  }

  render() {
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('fertiliser')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "10px 20px 10px"}} className="MetricsPanel">
          <div className="ToggleContainer">
            <div 
              onClick={this.setEntering.bind(this, false)} 
              className={this.state.entering ? "Toggle" : "Toggle Active"}>
                {this.trans('view_fertiliser_events')}
            </div>
            <div 
              onClick={this.setEntering.bind(this, true)}
              className={this.state.entering ? "Toggle Active" : "Toggle"}>
                {this.trans('input_fertiliser_event')}
            </div>
          </div>
          {this.props.paddock ? this.state.entering ? <FertiliserInput
            fertilisers={this.props.fertilisers}
            setEntering={this.setEntering.bind(this)}
            paddock={this.props.paddock}
          /> : <FertiliserList
            paddock={this.props.paddock}
          /> : <p>{this.trans('select_a_paddock_on_the_farm_map_or_grass_wedge')}</p>}
          
        </div>}
      </div>
    );
  }
}

export default PaddockFertiliser;


class FertiliserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: null,
      fertiliser_events: [],
    };
    this.trans = Translation.getInstance().translate;
    this.fetchFertiliserEvents = this.fetchFertiliserEvents.bind(this)
  }

  componentDidMount(){
    this.fetchFertiliserEvents(this.props.paddock.id)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.paddock.id !== this.props.paddock.id) {
      this.fetchFertiliserEvents(nextProps.paddock.id)
    }
    return true
  }

  fetchFertiliserEvents(padid) {
    this.setState({
      loaded: false,
      error: null,
      fertiliser_events: [],
    })
    const self = this;
    axios.post("https://staging.grasslandtools.ie/api/listfertiliserevents/", {
      sessionid: UserController.getSessionkey(),
      paddock: padid,
    }).then(function(response) {
      self.setState({
        loaded: true,
        error: null,
        fertiliser_events: response.data.fertiliser_events,
      })
    }).catch(function(error) {
      self.setState({
        loaded: true,
        error: this.trans('error_fetching_fertiliser_events_for_') + self.props.paddock.name,
        fertiliser_events: [],
      })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>{this.trans('loading___')}</p>
    }
    if (this.state.error) {
      return <p>{this.state.error}</p>
    }
    if (this.state.fertiliser_events.length == 0) {
      return <p>{this.trans('no_fertiliser_events_recorded_for_')} {this.props.paddock.name}</p>
    }
    return <div style={{padding: "10px"}}>
      <table className="SelectedTable">
        <thead>
          <tr>
            <th>{this.trans('date')}</th>
            <th>{this.trans('paddocks')}</th>
            <th>{this.trans('fertilisers')}</th>
          </tr>
        </thead>
        <tbody>
          {this.state.fertiliser_events.map((d, i)=><tr key={i}>
            <td>{d.date}</td>
            <td>{d.paddocks.join(", ")}</td>
            <td>{d.fertilisers.map((c)=>c.fertiliser + " (" + c.amount + " kg)").join(", ")}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  }
}


class FertiliserInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fertilisers: [
        {
          fertiliser: 0,
          amount: 0
        }
      ],
      date: moment(),
      sent: false
    };
    this.trans = Translation.getInstance().translate;
  }
  handleDateChange(d) {
    this.setState({ date: d });
  }

  handleFertiliserChange(i, event) {
    let fertilisers = this.state.fertilisers.slice();
    fertilisers[i].fertiliser = event.target.value;
    let blankCount = 0;
    fertilisers = fertilisers.filter((c,i) => c.fertiliser != 0 || i == fertilisers.length-1)
    for (let c of fertilisers) {
      if (c.fertiliser == 0) {
        blankCount ++;
      }
    }
    if (blankCount == 0) {
      fertilisers.push({ fertiliser: 0, amount: 0 });
    }
    this.setState({ fertilisers: fertilisers });
  }

  handleAmountChange(i, event) {
    const fertilisers = this.state.fertilisers.slice();
    fertilisers[i].amount = event.target.value;
    this.setState({ fertilisers: fertilisers });
  }

  submit() {
    if (!this.props.paddock) {
      alert(this.trans('no_paddock_selected_submit'));
      return;
    }
    if (!this.state.date) {
      alert(this.trans('valid_date'));
      return;
    }
    const self = this;
    const paddock = this.props.paddock;
    this.setState({ sent: true });
    axios
      .post('https://staging.grasslandtools.ie/api/insertfertiliserevent/', {
        sessionid: UserController.getSessionkey(),
        date: this.state.date.format('YYYY-MM-DD'),
        paddocks: paddock.id.toString(),
        fertilisers: this.state.fertilisers,
      })
      .then(function(response) {
        self.props.setEntering(false);
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }
  render() {
    if (this.state.sent) {
      return (
        <div
          className="InputDiv"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{this.trans('adding_fertiliser_event')}...</div>
        </div>
      );
    }

    const self = this;
    const fertiliser_list = this.props.fertilisers.map(function(c) {
      return (
        <option key={c.id} value={c.id}>
          {c.name}
        </option>
      );
    });

    const cult_selectors = this.state.fertilisers.map(function(c, i) {
      return (
        <div key={i} className="Metric">
          <div className="fg-split" style={{width: "100%"}}>
            <div className="fg-l">
              <label>
                {"Fertiliser " + (i+1)}:
              </label>
              <select
                value={c.fertiliser}
                onChange={self.handleFertiliserChange.bind(self, i)}
                className="FormInput"
                name="fertiliser"
              >
                <option /> {fertiliser_list}
              </select>
            </div>
            <div className="fg-s">
              <label>
                kgs:
              </label>
            <input
              value={c.amount}
              onChange={self.handleAmountChange.bind(self, i)}
              className="FormInput"
              type="number"
              name="amount"
            />
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className="MetricGroup">

          <div className="Metric">
            <label>
              {this.trans('date')}:
            </label>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              onChange={this.handleDateChange.bind(this)}
              selected={this.state.date}
              className="FormInput"
              placeholderText={this.trans('date')}
              style={{
                zIndex: '5'
              }}
            />
          </div>
          {cult_selectors}
          <div className="Metric">
            <label style={{opacity: 0}}>
            {this.trans('submit')}
            </label>
            <div className="Button Action" onClick={this.submit.bind(this)}>
              {this.trans('submit')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

