import React, { Component } from 'react';

import { rgb } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import { geoMercator, geoPath } from 'd3-geo';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { feature } from 'topojson';

import counties from './json/counties.json';

import './Weather.css';
import Translation from './Translation.js';

class WeatherMap extends Component {
  constructor(props) {
    super(props);
    this.projection = geoMercator();
    this.points = null;
    this.state = {
      mounted: false
    };
    this.width = 0;
    this.trans = Translation.getInstance().translate;
  }
  componentDidMount() {
    this.setState({ mounted: true });
    window.addEventListener('resize', this.resize.bind(this));
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  resize() {
    this.createMap();
    this.drawPoints();
  }

  createMap() {
    const self = this;
    const row = document.getElementById('WeatherMapRow');
    if (!row) {
      return;
    }
    const width = row.clientWidth / 3;
    this.width = width;
    const height = row.clientHeight;
    const id = this.props.id;

    const fontSize = width / 20;
    const margin = {
      top: width / 8 + 20,
      bottom: width / 30,
      left: width / 30,
      right: width / 30
    };

    const projection = this.projection
      .translate([width / 2, height / 2]) // translate to center of screen
      .center(['-2', '51'])
      .scale([1700])
      .fitExtent(
        [
          [margin.left, margin.top],
          [width - margin.right, height - margin.bottom]
        ],
        feature(counties, counties.objects.counties)
      );

    // Define path generator
    const path = geoPath() // path generator that will convert GeoJSON to SVG paths
      .projection(projection); // tell path generator to use albersUsa projection

    document.getElementById(this.props.id).innerHTML = '';

    //Create SVG element and append map to the SVG
    const svg = select('#' + this.props.id)
      .attr('width', width)
      .attr('height', height)
      .attr('class', 'MapSVG');

    const colScale = scaleLinear()
      .domain([-2, 10])
      .range([rgb('#ddd'), rgb('#ccc')])
      .interpolate(interpolateRgb);

    if (id === 'soil_temp_map') {
      colScale.range([rgb('#eee'), rgb('#ccaa44')]);
    }
    if (id === 'rainfall_map') {
      colScale.range([rgb('#eee'), rgb('#44aacc')]);
    }
    if (id === 'sunlight_map') {
      colScale.range([rgb('#eee'), rgb('#44ccaa')]);
    }

    svg
      .append('g')
      .attr('class', 'Ireland')
      .selectAll('path')
      .data(feature(counties, counties.objects.counties).features)
      .enter()
      .append('path')
      .attr('d', path)
      .style('fill', function(d) {
        return colScale(Math.random());
      });

    svg
      .append('text')
      .style('font-size', fontSize + 'px')
      .attr('x', 2 * width / 8 + fontSize / 3)
      .attr('y', width / 16 + fontSize * 0.65 + 15)
      .text(function(d) {
        if (id === 'soil_temp_map') {
          return self.trans('soil_temperature_weather');
        }
        if (id === 'rainfall_map') {
          return self.trans('rainfall_weather');
        }
        if (id === 'sunlight_map') {
          return self.trans('hours_of_sunlight_weather');
        }
      })
      .attr('text-anchor', 'start');

    svg
      .append('image')
      .attr('xlink:href', this.props.icon)
      .attr('width', width / 8)
      .attr('height', width / 8)
      .attr('x', width / 8)
      .attr('y', width / 70 + 15);

    this.points = svg.append('g').attr('class', 'Farm');
  }

  drawPoints() {
    const width = this.width;
    const id = this.props.id;
    const projection = this.projection;
    const weather = this.props.weather.filter(d => {
      if (
        (d.station.name === 'Phoenix Park') |
        (d.station.name === 'Casement') |
        (d.station.name === 'Roches Point')
      ) {
        return false;
      }
      if (!d.station.lng || !d.station.lat) {
        return false;
      }
      if (id === 'soil_temp_map') {
        return d.soil_temperature;
      }
      if (id === 'rainfall_map') {
        return d.rainfall;
      }
      if (id === 'sunlight_map') {
        return d.hrs_sunlight;
      }
      return true;
    });

    this.points.selectAll('circle').remove();

    this.points
      .selectAll('circle')
      .data(weather)
      .enter()
      .append('circle')
      .attr('cx', function(d) {
        return projection([d.station.lng, d.station.lat])[0];
      })
      .attr('cy', function(d) {
        return projection([d.station.lng, d.station.lat])[1];
      })
      .attr('r', function(d) {
        return width / 40;
      })
      .attr('class', d => {
        return this.props.selectedStation === d.station
          ? 'WeatherStation Active'
          : 'WeatherStation';
      })
      .style('cursor', 'pointer')
      .style('fill', () => {
        if (id === 'soil_temp_map') {
          return '#ccaa44';
        }
        if (id === 'rainfall_map') {
          return '#44aacc';
        }
        if (id === 'sunlight_map') {
          return '#44ccaa';
        }
      })
      .on('click', d => {
        this.props.selectStation(d.station);
      });

    const fontSize = width / 53;
    this.points
      .selectAll('text')
      .data(weather)
      .enter()
      .append('text')
      .style('font-size', fontSize + 'px')
      .attr('x', d => {
        return projection([d.station.lng, d.station.lat])[0];
      })
      .attr('y', d => {
        return projection([d.station.lng, d.station.lat])[1] + fontSize / 3;
      })
      .text(function(d) {
        if (id === 'soil_temp_map') {
          return d.soil_temperature;
        }
        if (id === 'rainfall_map') {
          return d.rainfall;
        }
        if (id === 'sunlight_map') {
          return d.hrs_sunlight;
        }
        return '';
      })
      .attr('text-anchor', 'middle')
      .attr('class', function(d) {
        return '';
      })
      .attr('pointer-events', 'none');
  }

  render() {
    if (this.state.mounted) {
      this.createMap();
      this.drawPoints();
    }
    return <svg id={this.props.id} />;
  }
}

export default WeatherMap;
