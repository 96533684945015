import React, { Component } from 'react';
import { min, max, rollups } from 'd3-array';
import { axisLeft } from 'd3-axis';
import { rgb } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import { polygonCentroid } from 'd3-polygon';
import { scaleLinear } from 'd3-scale';
import { select, event } from 'd3-selection';
import { line, curveLinearClosed } from 'd3-shape';
import { timer } from 'd3-timer';
import { zoom, zoomIdentity } from 'd3-zoom';
// import moment from 'moment-with-locales-es6';
import Translation from './Translation';
import Formulae from './Formulae';
import './Dashboard.css';
import GrasshopperTM from './img/MBG.png';
import Download from './img/Download.png';


class MapSVG extends Component {
  constructor(props) {
    super(props);
    this.createFarmMap = this.createFarmMap.bind(this);

    this.zoom_pad = null;
    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
    this.getPaddockClass = this.getPaddockClass.bind(this);
  }

  componentDidMount() {
    const chart = document.getElementById('FarmMap');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createFarmMap();
    window.addEventListener('resize', this.createFarmMap);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createFarmMap);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const chart = document.getElementById('FarmMap');
    if (this.w !== chart.clientWidth || this.h !== chart.clientHeight) {
      return true;
    }
    if (this.props.display !== nextProps.display) {
      return true;
    }
    if (this.props.displayBlock !== nextProps.displayBlock) {
      this.zoom_pad = null;
      return true;
    }
    if (this.props.displayGroup !== nextProps.displayGroup) {
      this.zoom_pad = null;
      return true;
    }
    if (this.props.residual !== nextProps.residual) {
      return true;
    }
    // console.log(this.props.map_key, nextProps.map_key,"HIHI")
    if (this.props.map_key !== nextProps.map_key) {
      return true;
    }
    if (this.props.cover) {
      if (this.props.cover.padCovers.length == nextProps.cover.padCovers.length) {
        for (let i = 0; i<nextProps.cover.padCovers.length; i++) {
          if (this.props.cover.padCovers[i].survey_id != nextProps.cover.padCovers[i].survey_id) {
            return true;
          }
        }
      } else {
        return true;
      }
    }
    if (this.props.inc_days !== nextProps.inc_days) {
      return true;
    }
    if (this.zoom_pad !== nextProps.paddock) {
      this.zoom_pad = nextProps.paddock;
      return true;
    }
    return true;
  }

  componentDidUpdate() {
    const chart = document.getElementById('FarmMap');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createFarmMap();
  }

  setPaddock(d) {
    this.zoom_pad = d;
    this.props.setPaddock(d);
  }

  getPaddockClass(status) {
    // console.log(this.props.displayGroup);
    let className = "Paddock";
    if (this.props.displayGroup <= 4) {
      if (!(status <= 4 || status == 7)) {
        className += " excluded";
      }
    } else if (this.props.displayGroup == 5) {
      if (!(status >= 4 && status != 7)) {
        className += " ignored";
      }
    } else if (status != this.props.displayGroup) {
      className += " ignored";
    }
    // if (!measured) {
      className += " unmeasured";
    // }
    return className;
  }

  createFarmMap() {
    // Remove old tooltip
    select('.tooltip').remove();

    // const self = this;
    const chart = document.getElementById('FarmMap');
    const w = chart.clientWidth;
    const h = chart.clientHeight;
    const self = this;

    document.getElementById('FarmMapSVG').innerHTML = '';

    const svg = select('#FarmMapSVG')
      .append('svg')
      .attr('id', "THISISTHEFARMMAPSVG")
      .attr('width', w)
      .attr('height', h)
      .style('overflow', 'hidden');

    // Grab setPaddock function from App component
    const setPaddock = this.setPaddock.bind(this);
    const displayBlock = this.props.displayBlock;

    // Define zoom behaviour
    const zoomDef = zoom()
      .scaleExtent([1, 20])
      .on('zoom', function() {
        map.attr('transform', event.transform);
      });

    // Variable to store active paddock
    let active;

    // Clicking background resets zoom
    svg
      .append('rect')
      .attr('width', w)
      .attr('height', h)
      .attr('fill', '#ffffff00')
      .on('click', function() {
        active = null;
        map
          .transition()
          .duration(750)
          .call(zoomDef.transform, zoomIdentity);
        map.selectAll('text').remove();
        setPaddock(null);
      });

    // Define margins
    const margin = {
      top: 35,
      right: 30,
      bottom: 35,
      left: 80
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    // Get paddocks
    const allPaddocks = this.props.farm.paddocks.slice();
    const cover = this.props.cover;

    const COVER_TYPE = this.props.coverType;
    const TARGET_RESIDUAL = this.props.residual;

    let padCovers;
    if (cover) {
      padCovers = cover.padCovers
        .slice()
        .sort((a, b) => a.survey_id - b.survey_id);
    } else {
      padCovers = [];
    }
    // console.log(padCovers)
    let paddocks = [];
    if (displayBlock > 0) {
      for (let p of allPaddocks) {
        if (p.block === displayBlock) {
          let survey = null;
          for (let j = 0; j < padCovers.length; j++) {
            if (cover.padCovers[j].paddock_id === p.id) {
              survey = cover.padCovers[j];
              // break;
            }
          }
          const cover_ha = survey
            ? survey.manualCover !== null
              ? survey.manualCover
              : Formulae.availableCover(
                  survey.avgHeight,
                  COVER_TYPE === '2' ? 0 : TARGET_RESIDUAL,
                  survey.dmPercentage
                )
            : null;
          const cover_pd = survey ? cover_ha * p.area / 10000 : null;
          paddocks.push({
            name: p.name,
            area: p.area,
            status: p.status,
            coordinates: p.coordinates,
            exclusions: p.exclusions,
            survey: survey,
            cover_ha: cover_ha,
            cover_pd: cover_pd
          });
        }
      }
    } else {
      for (let p of allPaddocks) {
        let survey = null;
        for (let j = 0; j < padCovers.length; j++) {
          if (cover.padCovers[j].paddock_id === p.id) {
            survey = cover.padCovers[j];
            // break;
          }
        }
        // const area = Math.max(paddock.area, 1)
        // const cover_ha = survey
        //   ? survey.manualCover !== null
        //     ? survey.manualCover
        //     : Formulae.availableCover(survey.avgHeight, COVER_TYPE === '2'
        //       ? 0
        //       : TARGET_RESIDUAL, survey.dmPercentage)
        //   : null;
        // const cover_pd = survey
        //   ? cover_ha * p.area / 10000
        //   : null;

        const cover_ha = survey
          ? survey.manualCover !== null && !isNaN(survey.manualCover)
            ? survey.manualCover
            : survey.dmHectare
          : null;
        const cover_pd = survey
          ? survey.manualCover !== null && !isNaN(survey.manualCover)
            ? survey.manualCover * p.area / 10000
            : survey.dmPaddock
          : null;
        // p = {   name: paddock.name,   area: area,   survey: {     cover_ha: cover_ha,
        //     cover_pd: cover_pd,     survey_date: cover.date,     avg_height:
        // survey.manualCover ? 'N/A' : survey.avgHeight   } };
        paddocks.push({
          id: p.id,
          name: p.name,
          area: p.area,
          status: p.status,
          coordinates: p.coordinates,
          exclusions: p.exclusions,
          survey: survey,
          cover_ha: cover_ha,
          cover_pd: cover_pd
        });
      }
    }

    // Find bounds of map
    const max_easting = max(paddocks, function(p) {
      return max(p.coordinates, function(c) {
        return c[0];
      });
    });
    const min_easting = min(paddocks, function(p) {
      return min(p.coordinates, function(c) {
        return c[0];
      });
    });

    const max_northing = max(paddocks, function(p) {
      return max(p.coordinates, function(c) {
        return c[1];
      });
    });
    const min_northing = min(paddocks, function(p) {
      return min(p.coordinates, function(c) {
        return c[1];
      });
    });

    const east_range = max_easting - min_easting;
    const north_range = max_northing - min_northing;

    // Define scales
    let xScale;
    let yScale;
    const ratio = east_range * height / (north_range * width);
    if (ratio > 1) {
      // East range controls the scale
      xScale = scaleLinear()
        .domain([min_easting, max_easting])
        .range([width * 0.01, width * 0.99]);
      yScale = scaleLinear()
        .domain([min_northing, max_northing])
        .range([
          height / ratio + height * (0.99 - 1 / ratio) / 2,
          height * (1.01 - 1 / ratio) / 2
        ]);
    } else {
      // North range controls the scale
      xScale = scaleLinear()
        .domain([min_easting, max_easting])
        .range([
          width * (1.01 - ratio) / 2,
          width * ratio + width * (0.99 - ratio) / 2
        ]);
      yScale = scaleLinear()
        .domain([min_northing, max_northing])
        .range([height * 0.99, height * 0.01]);
    }

    let color = scaleLinear()
      .domain([
        this.props.coverType=='1' ? 0 : 1000,
        padCovers.length > 0
          ? Math.max(
              1500,
              max(paddocks, function(p) {
                return p.cover_ha || 0;
              })
            )
          : 1500
      ])
      // .range([rgb('GreenYellow'), rgb('Green')])
      // .range([rgb('#d0da70'), rgb('#7dc243')])
      // .range([rgb(204, 225, 174, .6), rgb(120,190,32, .6)])
      .range([rgb(120,190,32, .2), rgb(120,190,32, .8)])
      // .range([rgb('#bce09f'), rgb('#64a92a')])
      .interpolate(interpolateRgb);

    // Define line function
    const drawPaddock = line()
      .x(function(d) {
        return xScale(d[0]);
      })
      .y(function(d) {
        return yScale(d[1]);
      })
      .curve(curveLinearClosed);

    let lengthscale = svg.append('g');

    let oldScale = 1;

    // Add map group
    const map = svg.append('g');

    for (let p of paddocks) {
      map
        .append('path')
        .attr('d', () => drawPaddock(p.coordinates))
        .attr('class', function() {
          return self.getPaddockClass(p.status);
          if (p.status <= 4 || p.status == 7) {
            return 'Paddock unmeasured';
          } else {
            return 'Paddock excluded unmeasured';
          }
        })
        .style('cursor', 'pointer')
        .style('vector-effect', 'non-scaling-stroke')
        // .style('stroke', '#78be20')
        .style('fill', function() {
          if (p.status==7) {
            return color(0);
          } else if (p.cover_ha) {
            return color(p.cover_ha);
          } else {
            // return '#FFFFFF00';
          }
        })
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .on('mouseover', function() {
          // If no paddock selected i.e. on farm view
          if (active === null) {
            const survey = p.survey;
            // Remove old tooltip
            select('.tooltip').remove();

            // Find paddock centroid
            const centroid = polygonCentroid(p.coordinates);

            const statusName =
              p.status === 1
                ? self.trans('in_grazing_platform')
                : p.status === 2
                  ? self.trans('mob') + ' 1'
                  : p.status === 3
                    ? self.trans('mob') + ' 2'
                    : p.status === 4
                      ? self.trans('mob') + ' 3'
                      : p.status === 5
                        ? self.trans('off_grazing_platform')
                        : p.status === 6
                          ? self.trans('silage_cut_later')
                          : p.status === 7
                            ? self.trans('silage_cut_now')
                            : p.status === 8 ? self.trans('closed') : '';
            // Show tooltip with transition
            const tooltip = select('#FarmMap')
              .append('div')
              .attr('class', 'tooltip')
              .style('left', margin.left + xScale(centroid[0]) - 110 + 'px')
              .style('bottom', h - margin.top - yScale(centroid[1]) + 15 + 'px')
              .style('width', '220px')
              .html(function() {
                let str =
                  self.trans('name') +
                  ': ' +
                  p.name +
                  '<br/>' +
                  self.trans('area') +
                  ': ' +
                  Math.round(p.area / 100) / 100 +
                  ' ha<br/>' +
                  self.trans('status') +
                  ': ' +
                  statusName;
                if (survey) {
                  str +=
                    '<br/>' +
                    self.trans('paddock_cover') +
                    ': ' +
                    Math.round(p.cover_pd) +
                    ' kg<br/>' +
                    self.trans('hectare_cover') +
                    ': ' +
                    Math.round(p.cover_ha) +
                    ' kg<br/>' +
                    self.trans('average_height') +
                    ': ' +
                    survey.avgHeight +
                    ' mm';
                } else {
                  str += '<br/>' + self.trans('no_surveys_found');
                }
                return str;
              });
            tooltip
              .transition()
              .duration(250)
              .style('opacity', 1);
          }
        })
        .on('mouseout', function() {
          // Fade out tooltip
          select('.tooltip')
            .transition()
            .duration(250)
            .style('opacity', 0)
            .remove();
        })
        .on('click', function() {
          event.stopPropagation();
          if (event.ctrlKey || event.metaKey) {
            // console.log("Ctrl+click has just happened!");
            return;
          }
          // Remove tooltip
          select('.tooltip').remove();
          map.selectAll('text').remove();

          // If active paddock clicked, reset zoom and return
          if (active!== null && active.id === p.id) {
            map
              .transition()
              .duration(750)
              .call(zoomDef.transform, zoomIdentity);
            active = null;
            setPaddock(null);
            drawLengthScale(1);
            let t = timer(function(elapsed) {
              let s;
              if (oldScale > 1) {
                s = Math.max(oldScale + elapsed * (1 - oldScale) / 750, 1);
              } else {
                s = Math.min(oldScale + elapsed * (1 - oldScale) / 750, 1);
              }
              drawLengthScale(s);
              if (elapsed > 800) {
                t.stop();
                oldScale = 1;
              }
            });
            return;
          }

          // Set clicked paddock as active paddock and zoom to paddock bounds
          active = p;
          setPaddock(p);
          zoomToActive(true);
        });

      for (let e of p.exclusions) {
        // console.log(e)
        map
          .append('path')
          .attr('d', () => drawPaddock(e))
          .attr('class', function() {
            return self.getPaddockClass(p.status) + " exclusion";
            if (p.status <= 4) {
              return 'Paddock unmeasured exclusion';
            } else {
              return 'Paddock excluded unmeasured exclusion';
            }
          })
          .style('cursor', 'pointer')
          .style('vector-effect', 'non-scaling-stroke')
          .style('fill', function() {
            if (p.cover_ha) {
              return '#FFccaa';
            } else {
              return '#FFccaa';
            }
          })
          .attr(
            'transform',
            'translate(' + margin.left + ',' + margin.top + ')'
          );
          // break;
      }
    }

    // Draw map
    // map
    //   .selectAll('path')
    //   .data(paddocks)
    //   .enter()
    //   .append('path')
    //   .attr('d', function (d) {
    //     return drawPaddock(d.coordinates);
    //   })
    //   .attr('class', function (d) {
    //     if (d.status <= 4) {
    //       return 'Paddock unmeasured';
    //     } else {
    //       return 'Paddock excluded unmeasured';
    //     }
    //   })
    //   .style('cursor', 'pointer')
    //   .style('vector-effect', 'non-scaling-stroke')
    //   .style('fill', function (d) {
    //     if (d.cover_ha) {
    //       return color(d.cover_ha);
    //     } else {
    //       return 'none';
    //     }
    //   })
    //   // .style('stroke', (d)=>{   for (let j = 0; j < padCovers.length; j++) { const
    //   // survey = cover.padCovers[j];     if (survey.paddock_id === d.id) {  return
    //   // 'none';     }   } })
    //   .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
    //   .on('mouseover', function (d) {
    //     // If no paddock selected i.e. on farm view
    //     if (active === null) {
    //       const survey = d.survey;
    //       // Remove old tooltip
    //       select('.tooltip').remove();

    //       // Find paddock centroid
    //       const centroid = polygonCentroid(d.coordinates);

    //       const statusName =
    //         d.status === 1
    //           ? self.trans('in_grazing_platform')
    //           : d.status === 2
    //             ? self.trans('mob') + ' 1'
    //             : d.status === 3
    //               ? self.trans('mob') + ' 2'
    //               : d.status === 4
    //                 ? self.trans('mob') + ' 3'
    //                 : d.status === 5
    //                   ? self.trans('off_grazing_platform')
    //                   : d.status === 6
    //                     ? self.trans('silage')
    //                     : d.status === 7
    //                       ? self.trans('set_aside')
    //                       : d.status === 8 ? self.trans('closed') : '';
    //       // Show tooltip with transition
    //       const tooltip = select('#FarmMap')
    //         .append('div')
    //         .attr('class', 'tooltip')
    //         .style('left', margin.left + xScale(centroid[0]) - 110 + 'px')
    //         .style('bottom', h - margin.top - yScale(centroid[1]) + 15 + 'px')
    //         .style('width', '220px')
    //         .html(function () {
    //           let str =
    //             self.trans('name') +
    //             ': ' +
    //             d.name +
    //             '<br/>' +
    //             self.trans('area') +
    //             ': ' +
    //             Math.round(d.area / 100) / 100 +
    //             ' ha<br/>' +
    //             self.trans('status') +
    //             ': ' +
    //             statusName;
    //           if (survey) {
    //             str +=
    //               '<br/>' +
    //               self.trans('paddock_cover') +
    //               ': ' +
    //               Math.round(d.cover_pd) +
    //               ' kg<br/>' +
    //               self.trans('hectare_cover') +
    //               ': ' +
    //               Math.round(d.cover_ha) +
    //               ' kg<br/>' +
    //               self.trans('average_height') +
    //               ': ' +
    //               survey.avgHeight +
    //               ' mm';
    //           } else {
    //             str += '<br/>' + self.trans('no_surveys_found');
    //           }
    //           return str;
    //         });
    //       tooltip
    //         .transition()
    //         .duration(250)
    //         .style('opacity', 1);
    //     }
    //   })
    //   .on('mouseout', function () {
    //     // Fade out tooltip
    //     select('.tooltip')
    //       .transition()
    //       .duration(250)
    //       .style('opacity', 0)
    //       .remove();
    //   })
    //   .on('click', function (d) {
    //     // Remove tooltip
    //     select('.tooltip').remove();
    //     map.selectAll('text').remove();

    //     // If active paddock clicked, reset zoom and return
    //     if (active === d) {
    //       map
    //         .transition()
    //         .duration(750)
    //         .call(zoomDef.transform, zoomIdentity);
    //       active = null;
    //       setPaddock(null);
    //       drawLengthScale(1);
    //       let t = timer(function (elapsed) {
    //         let s;
    //         if (oldScale > 1) {
    //           s = Math.max(oldScale + elapsed * (1 - oldScale) / 750, 1);
    //         } else {
    //           s = Math.min(oldScale + elapsed * (1 - oldScale) / 750, 1);
    //         }
    //         drawLengthScale(s);
    //         if (elapsed > 800) {
    //           t.stop();
    //           oldScale = 1;
    //         }
    //       });
    //       return;
    //     }

    //     // Set clicked paddock as active paddock and zoom to paddock bounds
    //     active = d;
    //     setPaddock(d);
    //     zoomToActive(true);
    //   });

    function zoomToActive(transition) {
      // Find paddock bounds
      const max_x = max(active.coordinates, function(p) {
        return xScale(p[0]);
      });
      const min_x = min(active.coordinates, function(p) {
        return xScale(p[0]);
      });

      const max_y = max(active.coordinates, function(p) {
        return yScale(p[1]);
      });
      const min_y = min(active.coordinates, function(p) {
        return yScale(p[1]);
      });

      // Find position of paddock center
      const x = margin.left + (min_x + max_x) / 2;
      const y = margin.top + (min_y + max_y) / 2;

      // Calculate required scaling and translation
      const scale = Math.min(
        20,
        0.9 / Math.max((max_x - min_x) / width, (max_y - min_y) / height)
      );
      const translate = [
        margin.left + width / 2 - scale * x,
        margin.top + height / 2 - scale * y
      ];

      // Zoom to paddock bounds
      if (transition) {
        map
          .transition()
          .duration(750)
          .call(
            zoomDef.transform,
            zoomIdentity.translate(translate[0], translate[1]).scale(scale)
          );
        let t = timer(function(elapsed) {
          let s;
          if (oldScale > scale) {
            s = Math.max(oldScale + elapsed * (scale - oldScale) / 750, scale);
          } else {
            s = Math.min(oldScale + elapsed * (scale - oldScale) / 750, scale);
          }
          drawLengthScale(s);
          if (elapsed > 800) {
            t.stop();
            oldScale = scale;
          }
        });
      } else {
        map.call(
          zoomDef.transform,
          zoomIdentity.translate(translate[0], translate[1]).scale(scale)
        );
        drawLengthScale(scale);
        oldScale = scale;
      }

      // Find centroid
      const centroid = polygonCentroid(active.coordinates);
      // Add paddock name label
      map
        .append('text')
        .text(active.name)
        .attr('x', margin.left + xScale(centroid[0]))
        .attr('y', margin.top + yScale(centroid[1]) + h / 12 / 3 / scale)
        .attr('class', 'PaddockName')
        // .style('fill', function() {
        //   let survey = null;
        //   for (let j = 0; j < padCovers.length; j++) {
        //     const s = cover.padCovers[j];
        //     if (s.paddock_id === active.id) {
        //       survey = s;
        //     }
        //   }
        //   if (!survey) {
        //     return 'green';
        //   }
        // })
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .style('font-size', h / 12 / scale + 'px');
    }

    // Check if paddock is selected and zoom as required
    active = null;
    if (this.props.paddock) {
      for (let pad of paddocks) {
        if (pad.id === this.props.paddock.id) {
          active = this.props.paddock;
          zoomToActive(false);
          break;
        }
      }
    }
    if (!active) {
      drawLengthScale(1);
    }

    // Add legend group
    const legend = svg
      .append('g')
      .attr('transform', 'translate(' + (55) + ', ' + margin.top + ')');

    // Define gradient for legend
    const gradient = legend
      .append('defs')
      .append('linearGradient')
      .attr('id', 'gradient')
      .attr('x1', '0%')
      .attr('y1', '100%')
      .attr('x2', '0%')
      .attr('y2', '0%');

    color.range().forEach(function(value, i) {
      gradient
        .append('stop')
        .attr('offset', i * 100 + '%')
        .attr('stop-color', value)
        .attr('stop-opacity', 1);
    });

    // Draw legend color bar
    legend
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', height + 20)
      .attr('fill', 'white');
    legend
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', height + 20)
      .attr('fill', 'url(#gradient)')

    // Define legend scale
    const legendScale = scaleLinear()
      .domain(color.domain())
      .range([height + 20, 0]);

    // Draw legend scale axis
    legend
      .append('g')
      .attr('class', 'legend axis')
      .style('font-size', 12 + 'px')
      .call(
        axisLeft(legendScale)
          .ticks(4)
          .tickSize(h / 200)
      );
    legend
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 20)
      .attr('height', height + 20)
      .attr("fill", "#00000000")
      .attr("stroke", "#51534a")
      .attr("stroke-width", "1px");

    // Add legend title
    svg
      .append('text')
      .attr('x', 55)
      .attr('y', margin.top - 5)
      .attr('class', 'LegendTitle')
      .text(this.trans('cover_kg_ha'))
      .attr('text-anchor', 'left');

    // Add heading
    if (this.props.mobile) {
      svg
        .append('text')
        .text(self.trans('farm_map'))
        .attr('x', w - margin.right)
        .attr('y', margin.top - 5)
        .attr('class', 'ChartHeading')
        .attr('text-anchor', 'end');
    } else {
      svg
        .append('text')
        .text(self.trans('farm_map'))
        .attr('x', w / 2)
        .attr('y', margin.top - 5)
        .attr('class', 'ChartHeading')
        .attr('text-anchor', 'middle');
      
      // var downloadButtonSize = margin.top*.75;
      // svg.append('image')
      //   .attr('xlink:href', Download)
      //   .attr('width', downloadButtonSize)
      //   .attr('height', downloadButtonSize)
      //   .attr('x', w - downloadButtonSize/3 - downloadButtonSize)
      //   .attr('y', downloadButtonSize/3).style("cursor","pointer").on("click", async function() {
      //     const canvas = preset.createCanvas(w, h);
      //     const ctx = canvas.getContext('2d');
          
      //     const v = Canvg.fromString(ctx, document.getElementById('FarmMapSVG').innerHTML, preset);

      //     await v.render();

      //     const png = canvas.toDataURL();

      //     var image = new Image(w, h);
      //     image.src = png;
  
      //     var newWindow = window.open("");
      //     newWindow.document.write(image.outerHTML);
      //     newWindow.document.close();
      //   });
    }
    
    svg
      .append('image')
      .attr('xlink:href', GrasshopperTM)
      .attr('width', 120)
      .attr('height', 30)
      .attr('x', margin.left + 10)
      .attr('y', h - margin.bottom - 13);

    svg
      .append('line')
      .attr('x1', margin.left+5)
      .attr('x2', margin.left+5)
      .attr('y1', h - margin.bottom + 25)
      // .attr('y1', h - 5)
      .attr('y2', h - margin.bottom - 20)
      // .attr('y2', h - 50)
      .attr('stroke', '#182c2e');

    svg
      .append('line')
      .attr('x1', margin.left)
      .attr('x2', 150)
      .attr('y1', h - margin.bottom + 20)
      .attr('y2', h - margin.bottom + 20)
      .attr('stroke', '#182c2e');

    function drawLengthScale(scale) {
      lengthscale.remove();
      lengthscale = svg.append('g');
      lengthscale
        .append('line')
        .attr('x1', w - margin.right)
        .attr('x2', w - margin.right)
        .attr('y1', h - margin.bottom + 20)
        .attr('y2', h - margin.bottom + 10)
        .attr('stroke', '#182c2e');

      function dx(l) {
        return scale * (xScale(l) - xScale(0));
      }

      let len = [
        5000,
        2000,
        1000,
        500,
        200,
        100,
        50,
        20,
        10,
        5,
        2,
        1,
        0.5,
        0.2,
        0.1
      ];

      let length = 0;
      for (let i = 0; i < len.length; i++) {
        if (dx(len[i]) < width / 2) {
          length = len[i];
          break;
        }
      }

      const DX = dx(length);

      lengthscale
        .append('line')
        .attr('x1', w - margin.right - DX)
        .attr('x2', w - margin.right - DX)
        .attr('y1', h - margin.bottom + 20)
        .attr('y2', h - margin.bottom + 10)
        .attr('stroke', '#182c2e');

      lengthscale
        .append('line')
        .attr('x1', w - margin.right)
        .attr('x2', w - margin.right - DX)
        .attr('y1', h - margin.bottom + 20)
        .attr('y2', h - margin.bottom + 20)
        .attr('stroke', '#182c2e');

      lengthscale
        .append('text')
        .text(length + ' m')
        .attr('x', w - margin.right - DX - 5)
        .attr('y', h - margin.bottom + 18)
        .attr('text-anchor', 'end')
        .style('font-size', 12 + 'px')
        .attr('fill', '#182c2e');

      // const mpg = svg.append('g');

    }
  }

  render() {
    return (
      <div
        id="FarmMapSVG"
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    );
  }
}

class FarmMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popover_toggled: false
    };
  }
  togglePopover() {
    this.setState({
      popover_toggled: !this.state.popover_toggled
    });
  }
  render() {
    return (
      <div id="FarmMap" className={this.props.className}>
        <MapSVG
          coverType={this.props.coverType}
          farm={this.props.farm}
          cover={this.props.cover}
          display={this.props.display}
          paddock={this.props.paddock}
          residual={this.props.residual}
          setPaddock={d => this.props.setPaddock(d, null, 'farm_map')}
          displayGroup={this.props.displayGroup}
          displayBlock={this.props.displayBlock}
          disp_dates={this.props.disp_dates}
          inc_days={this.props.inc_days}
          mobile={this.props.mobile}
          map_key={this.props.map_key}
        />
      </div>
    );
  }
}

export default FarmMap;
