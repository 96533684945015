import React, { Component } from 'react';
import Translation from './Translation';
import './Soil.css';

import UserController from './UserController';

import axios from 'axios';
import { min, max } from 'd3-array';
import { polygonCentroid } from 'd3-polygon';
import { scaleLinear } from 'd3-scale';
import { select, event } from 'd3-selection';
import { line, curveLinearClosed } from 'd3-shape';
import { timer } from 'd3-timer';
import { zoom, zoomIdentity } from 'd3-zoom';

import DatePicker from 'react-datepicker';

import moment from 'moment-with-locales-es6';
import Loading from './Loading';

moment.locale(Translation.getInstance().langCode());

class PaddockSoilTable extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    const paddock = this.props.paddock;
    const fieldArray = this.props.fieldArray;

    let hasResults = false;

    const padTable = paddock.soilSurveys.map(function(survey) {
      let fieldResults = null;
      if (survey.results.length > 0) {
        hasResults = true;
        fieldResults = fieldArray.map(function(field) {
          let resultFields = survey.results[0].fields;
          let value = 0;
          let index = 0;
          for (let i = 0; i < resultFields.length; i++) {
            if (resultFields[i].abbr === field) {
              value = resultFields[i].value;
              index = resultFields[i].index;
              break;
            }
          }
          if (index === 0 && survey.results[1]) {
            resultFields = survey.results[1].fields;
            for (let i = 0; i < resultFields.length; i++) {
              if (resultFields[i].abbr === field) {
                value = resultFields[i].value;
                index = resultFields[i].index;
                break;
              }
            }
          }
          return (
            <td key={field}>
              {'' +
                (index > 0 ? index : '') +
                (value > 0 && index > 0 ? ' ' : '') +
                (value > 0 ? '(' + Math.round(value * 100) / 100 + ')' : '')}
            </td>
          );
        });
      }

      return (
        <tr key={survey.barcode}>
          <td>{moment(survey.timestamp).format('L')}</td>
          {fieldResults}
        </tr>
      );
    });
    const fieldHeaders = fieldArray.map(function(field) {
      if (hasResults) {
        return <th key={field}>{trans(field + '_short')}</th>;
      } else {
        return null;
      }
    });
    return (
      <div className="SoilTableDiv">
        <h3
          style={{
            margin: '20px 0'
          }}
        >
          {trans('paddock_')} {paddock.name}
        </h3>
        <div
          className="TableDiv"
          style={{
            height: 'calc(100% - 75px)',
            width: 'calc(100% - 22px)'
          }}
        >
          <table className="SelectedTable">
            <thead>
              <tr>
                <th>{trans('survey_date')}</th>
                {fieldHeaders}
              </tr>
            </thead>
            <tbody>{padTable}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

class FarmSoilTable extends Component {
  constructor(props) {
    super(props);
    this.getLatestResult = this.getLatestResult.bind(this);
  }
  getLatestResult(paddock, disp) {
    const date = this.props.sampleDates[this.props.sampleDateIndex];
    for (let survey of paddock.soilSurveys) {
      if (moment(survey.timestamp).isSameOrBefore(date, 'day')) {
        for (let result of survey.results) {
          let resultFields = result.fields;
          for (let i = 0; i < resultFields.length; i++) {
            if (resultFields[i].abbr === disp) {
              return {
                value: resultFields[i].value,
                index: resultFields[i].index
              };
            }
          }
        }
      }
    }
    return null;
  }
  getLatestSurveyDate(paddock) {
    const date = this.props.sampleDates[this.props.sampleDateIndex];
    for (let survey of paddock.soilSurveys) {
      const s_date = moment(survey.timestamp);
      if (s_date.isSameOrBefore(date, 'day')) {
        return s_date.format('L');
      }
    }
    return null;
  }

  render() {
    const self = this;
    const trans = Translation.getInstance().translate;
    const paddocks = this.props.paddocks;
    const fieldArray = this.props.fieldArray;

    let hasResults = false;

    const padTable = paddocks.map(function(p) {
      if (p.soilSurveys.length > 0) {
        let fieldResults = null;
        hasResults = true;
        const date = self.getLatestSurveyDate(p);
        const dateField = <td>{date}</td>;
        fieldResults = fieldArray.map(function(field) {
          const result = self.getLatestResult(p, field);
          if (result === null) {
            return <td key={field}>{'~'}</td>;
          }
          const value = result.value;
          const index = result.index;
          return (
            <td key={field}>
              {'' +
                (index > 0 ? index : '') +
                (value > 0 && index > 0 ? ' ' : '') +
                (value > 0 ? '(' + Math.round(value * 100) / 100 + ')' : '')}
            </td>
          );
        });

        return (
          <tr key={p.name}>
            <td>{p.name}</td>
            {dateField}
            {fieldResults}
          </tr>
        );
      }
      return null;
    });
    const fieldHeaders = fieldArray.map(function(field) {
      if (hasResults) {
        return <th key={field}>{trans(field + '_short')}</th>;
      } else {
        return null;
      }
    });
    return (
      <div className="SoilTableDiv">
        <h3
          style={{
            margin: '20px 0'
          }}
        >
          {trans('results_table')}
        </h3>
        <div
          className="TableDiv"
          style={{
            height: 'calc(100% - 75px)',
            width: 'calc(100% - 22px)'
          }}
        >
          <table className="SelectedTable">
            <thead>
              <tr>
                <th>{trans('name')}</th>
                <th>{trans('survey_date')}</th>
                {fieldHeaders}
              </tr>
            </thead>
            <tbody>{padTable}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

class SoilMap extends Component {
  constructor(props) {
    super(props);
    this.createSoilMap = this.createSoilMap.bind(this);

    this.zoom_pad = null;

    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
    this.getLatestResult = this.getLatestResult.bind(this);
  }

  getLatestResult(paddock) {
    const date = this.props.sampleDates[this.props.sampleDateIndex];
    const disp = this.props.disp;
    for (let survey of paddock.soilSurveys) {
      if (moment(survey.timestamp).isSameOrBefore(date, 'day')) {
        for (let result of survey.results) {
          let resultFields = result.fields;
          for (let i = 0; i < resultFields.length; i++) {
            if (resultFields[i].abbr === disp) {
              return {
                value: resultFields[i].value,
                index: resultFields[i].index
              };
            }
          }
        }
      }
    }
    return null;
  }

  componentDidMount() {
    const chart = document.getElementById('SoilMapDiv');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createSoilMap();
    window.addEventListener('resize', this.createSoilMap);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createSoilMap);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const chart = document.getElementById('SoilMapDiv');
    if (this.w !== chart.clientWidth || this.h !== chart.clientHeight) {
      return true;
    }
    if (this.zoom_pad !== nextProps.paddock) {
      return true;
    }
    if (this.props.sampleDateIndex !== nextProps.sampleDateIndex) {
      return true;
    }
    if (this.props.selectingPaddocks || nextProps.selectingPaddocks) {
      return true;
    }
    if (this.props.paddocks !== nextProps.paddocks) {
      return true;
    }
    if (this.props.disp !== nextProps.disp) {
      return true;
    }
    return false;
  }

  componentDidUpdate() {
    const chart = document.getElementById('SoilMapDiv');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createSoilMap();
  }

  setPaddock(d) {
    this.zoom_pad = d;
    this.props.setPaddock(d);
  }

  createSoilMap() {
    select('.tooltip').remove();

    const setPaddock = this.setPaddock.bind(this);

    const selectPaddock = this.props.selectPaddock.bind(this);

    const selectingPaddocks = this.props.selectingPaddocks;
    const selectedPaddocks = this.props.selectedPaddocks;

    const chart = document.getElementById('SoilMapDiv');
    const w = chart.clientWidth;
    const h = chart.clientHeight;

    chart.innerHTML = '';

    const svg = select('#SoilMapDiv')
      .append('svg')
      .attr('width', w)
      .attr('height', h);

    // Define zoom behaviour
    const zoomDef = zoom()
      .scaleExtent([1, 20])
      .on('zoom', function() {
        map.attr('transform', event.transform);
      });

    // Variable to store active paddock
    let active = select(null);
    let readout = select(null);

    // Clicking background resets zoom
    svg
      .append('rect')
      .attr('width', w)
      .attr('height', h)
      .attr('fill', '#ffffff00')
      .on('click', function() {
        active = null;
        map
          .transition()
          .duration(750)
          .call(zoomDef.transform, zoomIdentity);
        setPaddock(null);
        readout.remove();
      });

    // Add map group
    const map = svg.append('g');

    const margin = {
      top: 50,
      right: 5,
      bottom: 45,
      left: 5 //80
    };

    // Find size of drawing region
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    // Get paddocks
    const paddocks = this.props.paddocks;

    // Find bounds of map
    const max_easting = max(paddocks, function(p) {
      return max(p.coordinates, function(c) {
        return c[0];
      });
    });
    const min_easting = min(paddocks, function(p) {
      return min(p.coordinates, function(c) {
        return c[0];
      });
    });

    const max_northing = max(paddocks, function(p) {
      return max(p.coordinates, function(c) {
        return c[1];
      });
    });
    const min_northing = min(paddocks, function(p) {
      return min(p.coordinates, function(c) {
        return c[1];
      });
    });

    const east_range = max_easting - min_easting;
    const north_range = max_northing - min_northing;

    // Define scales
    let xScale;
    let yScale;
    const ratio = east_range * height / (north_range * width);
    if (ratio > 1) {
      // East range controls the scale
      xScale = scaleLinear()
        .domain([min_easting, max_easting])
        .range([width * 0.01, width * 0.99]);
      yScale = scaleLinear()
        .domain([min_northing, max_northing])
        .range([
          height / ratio + height * (0.99 - 1 / ratio) / 2,
          height * (1.01 - 1 / ratio) / 2
        ]);
    } else {
      // North range controls the scale
      xScale = scaleLinear()
        .domain([min_easting, max_easting])
        .range([
          width * (1.01 - ratio) / 2,
          width * ratio + width * (0.99 - ratio) / 2
        ]);
      yScale = scaleLinear()
        .domain([min_northing, max_northing])
        .range([height * 0.99, height * 0.01]);
    }

    // Define line function
    const drawPaddock = line()
      .x(function(d) {
        return xScale(d[0]);
      })
      .y(function(d) {
        return yScale(d[1]);
      })
      .curve(curveLinearClosed);

    let lengthscale = svg.append('g');

    let oldScale = 1;
    const self = this;
    map
      .selectAll('path')
      .data(paddocks)
      .enter()
      .append('path')
      .attr('d', function(d) {
        return drawPaddock(d.coordinates);
      })
      .attr('class', 'Paddock soil')
      .style('cursor', 'pointer')
      .style('vector-effect', 'non-scaling-stroke')
      .style('fill', function(d) {
        if (selectingPaddocks) {
          let selected = false;
          for (let pad of selectedPaddocks) {
            if (pad.id === d.id) {
              selected = true;
              break;
            }
          }
          return selected ? '#51534a' : '#78be20';
        }
        const result = self.getLatestResult(d);
        if (result) {
          switch (result.index) {
            case 1:
              return '#c00d1e';
            case 2:
              return '#f2a900';
            case 3:
              return '#78be20';
            case 4:
              return '#5a8918';
            default:
              return '#919388';
          }
        }
      })
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
      .on('mouseover', function(d) {
        // If no paddock selected i.e. on farm view
        if (active === null) {
          const result = self.getLatestResult(d);
          // Remove old tooltip
          select('.tooltip').remove();

          // Find paddock centroid
          const centroid = polygonCentroid(d.coordinates);

          // Show tooltip with transition
          const tooltip = select('#SoilMapDiv')
            .append('div')
            .attr('class', 'tooltip')
            .style('left', margin.left + xScale(centroid[0]) - 85 + 'px')
            .style('bottom', h - margin.top - yScale(centroid[1]) + 15 + 'px')
            .style('width', '170px')
            .html(function() {
              const str = result
                ? Math.round(result.value * 100) / 100 + ' ' //+ unit
                : '-';
              return (
                self.trans('paddock') +
                ': ' +
                d.name +
                '<br/>' +
                self.trans('value') +
                ': ' +
                str +
                '<br/>' +
                self.trans('index') +
                ': ' +
                (result ? result.index : "-")
              );
            });
          tooltip
            .transition()
            .duration(250)
            .style('opacity', 1);
        }
      })
      .on('mouseout', function() {
        // Fade out tooltip
        select('.tooltip')
          .transition()
          .duration(250)
          .style('opacity', 0)
          .remove();
      })
      .on('click', function(d) {
        if (selectingPaddocks) {
          selectPaddock(d);
          return;
        }
        // Remove tooltip
        select('.tooltip').remove();
        readout.remove();

        // If active paddock clicked, reset zoom and return
        if (active === d) {
          map
            .transition()
            .duration(750)
            .call(zoomDef.transform, zoomIdentity);
          active = null;
          setPaddock(null);
          drawLengthScale(1);
          let t = timer(function(elapsed) {
            let s;
            if (oldScale > 1) {
              s = Math.max(oldScale + elapsed * (1 - oldScale) / 750, 1);
            } else {
              s = Math.min(oldScale + elapsed * (1 - oldScale) / 750, 1);
            }
            drawLengthScale(s);
            if (elapsed > 800) {
              t.stop();
              oldScale = 1;
            }
          });
          return;
        }

        // Set clicked paddock as active paddock and zoom to paddock bounds
        active = d;
        setPaddock(d);
        zoomToActive(true);
      });
    
      map
        .selectAll('labels')
        .data(paddocks)
        .enter()
        .append('text')
        .text((d)=>d.name)
        .attr('x', (d)=>margin.left + xScale(polygonCentroid(d.coordinates)[0]))
        .attr('y', (d)=>margin.top + yScale(polygonCentroid(d.coordinates)[1]) + h / 12 / 3 / 5)
        .attr('class', 'PaddockName')
        .attr('text-anchor', 'middle')
        .attr('pointer-events', 'none')
        .style('font-size', h / 12 / 5 + 'px')

    function zoomToActive(transition) {
      // Find paddock bounds
      const max_x = max(active.coordinates, function(p) {
        return xScale(p[0]);
      });
      const min_x = min(active.coordinates, function(p) {
        return xScale(p[0]);
      });

      const max_y = max(active.coordinates, function(p) {
        return yScale(p[1]);
      });
      const min_y = min(active.coordinates, function(p) {
        return yScale(p[1]);
      });

      // Find position of paddock center
      const x = margin.left + (min_x + max_x) / 2;
      const y = margin.top + (min_y + max_y) / 2;

      // Calculate required scaling and translation
      const scale = Math.min(
        20,
        0.9 / Math.max((max_x - min_x) / width, (max_y - min_y) / height)
      );
      const translate = [
        margin.left + width / 2 - scale * x,
        margin.top + height / 2 - scale * y
      ];

      // Zoom to paddock bounds
      if (transition) {
        map
          .transition()
          .duration(750)
          .call(
            zoomDef.transform,
            zoomIdentity.translate(translate[0], translate[1]).scale(scale)
          );
        let t = timer(function(elapsed) {
          let s;
          if (oldScale > scale) {
            s = Math.max(oldScale + elapsed * (scale - oldScale) / 750, scale);
          } else {
            s = Math.min(oldScale + elapsed * (scale - oldScale) / 750, scale);
          }
          drawLengthScale(s);
          if (elapsed > 800) {
            t.stop();
            oldScale = scale;
          }
        });
      } else {
        map.call(
          zoomDef.transform,
          zoomIdentity.translate(translate[0], translate[1]).scale(scale)
        );
        drawLengthScale(scale);
        oldScale = scale;
      }
    }

    function drawLengthScale(scale) {
      lengthscale.remove();
      lengthscale = svg.append('g');
      lengthscale
        .append('line')
        .attr('x1', w - margin.right)
        .attr('x2', w - margin.right)
        .attr('y1', h - margin.bottom)
        .attr('y2', h - margin.bottom - 10)
        .attr('stroke', 'black');

      function dx(l) {
        return scale * (xScale(l) - xScale(0));
      }

      let len = [
        5000,
        2000,
        1000,
        500,
        200,
        100,
        50,
        20,
        10,
        5,
        2,
        1,
        0.5,
        0.2,
        0.1
      ];

      let length = 0;
      for (let i = 0; i < len.length; i++) {
        if (dx(len[i]) < width / 2) {
          length = len[i];
          break;
        }
      }

      const DX = dx(length);

      lengthscale
        .append('line')
        .attr('x1', w - margin.right - DX)
        .attr('x2', w - margin.right - DX)
        .attr('y1', h - margin.bottom)
        .attr('y2', h - margin.bottom - 10)
        .attr('stroke', 'black');

      lengthscale
        .append('line')
        .attr('x1', w - margin.right)
        .attr('x2', w - margin.right - DX)
        .attr('y1', h - margin.bottom)
        .attr('y2', h - margin.bottom)
        .attr('stroke', 'black');

      lengthscale
        .append('text')
        .text(length + ' m')
        .attr('x', w - margin.right - DX - 5)
        .attr('y', h - margin.bottom - 2)
        //	.attr("class", "ChartHeading")
        .attr('text-anchor', 'end');
    }

    // Check if paddock is selected and zoom as required
    if (this.props.paddock && paddocks.indexOf(this.props.paddock) >= 0) {
      active = this.props.paddock;
      zoomToActive(false);
    } else {
      active = null;
      drawLengthScale(1);
    }
  }
  render() {
    return <div className="SoilMapDiv" id="SoilMapDiv" />;
  }
}

class SoilSelector extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    const disp = this.props.disp;
    const handleDispChange = this.props.handleDispChange;
    const options = this.props.fieldArray.map(function(field) {
      return (
        <option key={field} value={field}>
          {trans(field)}
        </option>
      );
    });
    return (
      <div className="SoilSelectDiv">
        <h3
          style={{
            margin: '20px 0'
          }}
        >
          {trans('soil_nutrient_to_display')}
        </h3>
        <select
          className="FormInput"
          value={disp}
          onChange={handleDispChange.bind(this)}
        >
          {options}
        </select>
      </div>
    );
  }
}

class SoilMapDownload extends Component {
  mapDownload() {
    document.forms['mapDownload'].submit();
  }
  render() {
    const trans = Translation.getInstance().translate;
    const disp = this.props.disp;
    const sampleDates = this.props.sampleDates;
    const sampleDate = sampleDates[this.props.sampleDateIndex];
    return (
      <div className="SoilSelectDiv">
        <h3
          style={{
            margin: '20px 0'
          }}
        >
          {trans('download_soil_map')}
        </h3>
        <form
          method="POST"
          action="https://staging.grasslandtools.ie/api/soilpdf/"
          name="mapDownload"
        >
          <input
            type="hidden"
            name="sessionid"
            value={UserController.getSessionkey()}
          />
          <input type="hidden" name="nutrient" value={disp} />
          <input type="hidden" name="date" value={sampleDate} />
          <input
            type="hidden"
            name="farm_id"
            value={UserController.getFarmid()}
          />
          <div className="Button" onClick={this.mapDownload.bind(this)}>
            {trans('download')}
          </div>
        </form>
      </div>
    );
  }
}

class SampleDateSelector extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    const sampleDates = this.props.sampleDates;
    const sampleDateIndex = this.props.sampleDateIndex;
    const sampleDatesMax = sampleDates.length - 1;
    return (
      <div className="SoilDateSelector">
        <h3
          style={{
            margin: '20px 0'
          }}
        >
          {trans('sample_date')}
        </h3>
        <div className="DateSelectorContainer">
          <h1
            className={sampleDateIndex === 0 ? 'Arrow disabled' : 'Arrow'}
            onClick={
              sampleDateIndex > 0
                ? this.props.changeDateIndex.bind(this, -1)
                : null
            }
          >
            {'<'}
          </h1>
          <div className="DateDisplay">
            <label className="Year">
              {sampleDates.length > 0
                ? sampleDates[sampleDateIndex].format('L')
                : moment().format('L')}
            </label>
          </div>
          <h1
            className={
              sampleDateIndex === sampleDatesMax ? 'Arrow disabled' : 'Arrow'
            }
            onClick={
              sampleDateIndex < sampleDatesMax
                ? this.props.changeDateIndex.bind(this, 1)
                : null
            }
          >
            {'>'}
          </h1>
        </div>
      </div>
    );
  }
}

class SoilInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      ph: '',
      phIndex: '',
      p: '',
      pIndex: '',
      k: '',
      kIndex: '',
      sent: false
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  handleDateChange(d) {
    this.setState({ date: d });
  }

  handlePhChange(event) {
    let ph = parseFloat(event.target.value);
    let index = 0;
    if (ph <= 5.8) {
      index = 1;
    } else if (ph <= 6.2) {
      index = 2;
    } else if (ph <= 6.5) {
      index = 3;
    } else if (ph > 6.5) {
      index = 4;
    }
    this.setState({
      ph: ph,
      phIndex: index
    });
  }
  handlePhIndexChange(event) {
    this.setState({
      phIndex: event.target.value
    });
  }

  handlePChange(event) {
    let p = parseFloat(event.target.value);
    let index = 0;
    if (p <= 3) {
      index = 1;
    } else if (p <= 5) {
      index = 2;
    } else if (p <= 8) {
      index = 3;
    } else if (p > 8) {
      index = 4;
    }
    this.setState({
      p: p,
      pIndex: index
    });
  }
  handlePIndexChange(event) {
    this.setState({
      pIndex: event.target.value
    });
  }

  handleKChange(event) {
    let k = parseFloat(event.target.value);
    let index = 0;
    if (k <= 50) {
      index = 1;
    } else if (k <= 100) {
      index = 2;
    } else if (k <= 150) {
      index = 3;
    } else if (k > 150) {
      index = 4;
    }
    this.setState({
      k: k,
      kIndex: index
    });
  }
  handleKIndexChange(event) {
    this.setState({
      kIndex: event.target.value
    });
  }

  cancel() {
    this.props.setSelectingPaddocks(false);
    this.setState({ date: moment(), ph: '', p: '', k: '', sent: false });
  }

  submit() {
    this.setState({ sent: true });

    const self = this;
    axios
      .post('https://staging.grasslandtools.ie/api/inputsoildata/', {
        sessionid: UserController.getSessionkey(),
        farm_id: UserController.getFarmid(),
        pad_ids: self.props.selectedPaddocks.map(p => p.id).join(','),
        date: self.state.date.format('YYYY-MM-DD'),
        result_fields: JSON.stringify([
          {
            name: 'ph',
            value: self.state.ph,
            index: self.state.phIndex
          },
          {
            name: 'p',
            value: self.state.p,
            index: self.state.pIndex
          },
          {
            name: 'k',
            value: self.state.k,
            index: self.state.kIndex
          }
        ])
      })
      .then(function(response) {
        self.setState({ sent: false });
        self.props.loadSoilData();
        // console.log(response)
        alert(self.trans('soil_data_updated'));
      })
      .catch(error => {
        // console.log(error.response);
        self.setState({ sent: false });
        switch (error.response.status) {
          case 403:
            alert(self.trans('soil_data_not_updated_permission_denied'));
            break;
          case 500:
            alert(self.trans('soil_data_not_updated_server_error'));
            break;
          default:
            alert(self.trans('soil_data_not_updated') + error.response + '.');
        }
      });
  }

  render() {
    if (this.state.sent) {
      return (
        <div className="SoilInput">
          <Loading text={'updating_soil_data'} /> 
        </div>
      );
    }
    const selectedPaddocks = this.props.selectedPaddocks;
    const paddockList =
      selectedPaddocks.length > 0 ? (
        selectedPaddocks.map((p, i) => (
          <div key={i} className="Paddock">
            {p.name}
          </div>
        ))
      ) : this.props.selectingPaddocks ? (
        <div
          style={{
            textAlign: 'center'
          }}
        >
          {this.trans('click_on_the_map_to_select_paddocks')}
        </div>
      ) : null;
    let totalArea = 0;
    this.props.selectedPaddocks.forEach(p => (totalArea += p.area / 100));
    totalArea = Math.round(totalArea) / 100;
    const paddockAreaDisplay =
      this.props.selectedPaddocks.length > 0 ? (
        <div className="PaddockAreaDisplay">
          <label>Total area:</label>
          <label
            style={
              totalArea > 5
                ? {
                    color: 'red'
                  }
                : {}
            }
          >{`${totalArea} ha`}</label>
        </div>
      ) : null;
    return (
      <div className="SoilInput">
        <h3
          style={{
            margin: '20px 0'
          }}
        >
          {this.trans('input_soil_data')}
        </h3>
        <div className="InputContainer">
          <div className="InputCol">
            <div
              className="Button"
              style={
                this.props.selectingPaddocks
                  ? {
                      background: '#51534a',
                      marginBottom: '10px'
                    }
                  : {
                      marginBottom: '10px'
                    }
              }
              onClick={this.props.setSelectingPaddocks.bind(this, true)}
            >
              {this.props.selectingPaddocks
                ? this.trans('clear_selection')
                : this.trans('select_paddocks')}
            </div>
            <div className="PaddockList">{paddockList}</div>
            {paddockAreaDisplay}
            <div className="PaddockAreaDisplay" />
          </div>
          <div className="InputCol">
            <div
              className="Row"
              style={
                {
                  // marginBottom: '10px'
                }
              }
            >
              <label>{this.trans('date_sampled') + ':'}</label>
              <div
                style={{
                  width: '60%'
                }}
              >
                <DatePicker
                  locale="en-ie"
                  maxDate={Date()}
                  popperPlacement="left"
                  dateFormat="DD/MM/YYYY"
                  onChange={this.handleDateChange.bind(this)}
                  selected={this.state.date}
                  className="FormInput"
                  placeholderText={this.trans('date')}
                  style={{
                    zIndex: '5'
                  }}
                />
              </div>
            </div>
            <div className="Row">
              <div />
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  justifyContent: 'space-around'
                }}
              >
                <label style={{ marginBottom: 3 }}>{this.trans('value')}</label>
                <label style={{ marginBottom: 3 }}>
                  {this.trans('lime_tonnes_ha')}
                </label>
              </div>
            </div>
            <div className="Row">
              <label>{this.trans('ph')}</label>
              <div
                className="Row"
                style={{
                  width: '60%'
                }}
              >
                <div
                  style={{
                    width: '45%'
                  }}
                >
                  <input
                    className="FormInput"
                    type="number"
                    step="0.01"
                    value={this.state.ph}
                    onChange={this.handlePhChange.bind(this)}
                  />
                </div>
                <div
                  style={{
                    width: '45%'
                  }}
                >
                  <select
                    value={this.state.phIndex}
                    onChange={this.handlePhIndexChange.bind(this)}
                    className="FormInput"
                  >
                    <option value="" />
                    <option value="1">7</option>
                    <option value="2">4</option>
                    <option value="3">0</option>
                    <option value="4">0</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="Row">
              <div />
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  justifyContent: 'space-around'
                }}
              >
                <label style={{ marginBottom: 3 }}>{this.trans('value')}</label>
                <label style={{ marginBottom: 3 }}>{this.trans('index')}</label>
              </div>
            </div>
            <div className="Row">
              <label>{this.trans('p')}</label>
              <div
                className="Row"
                style={{
                  width: '60%'
                }}
              >
                <div
                  style={{
                    width: '45%'
                  }}
                >
                  <input
                    className="FormInput"
                    type="number"
                    step="0.01"
                    value={this.state.p}
                    onChange={this.handlePChange.bind(this)}
                  />
                </div>
                <div
                  style={{
                    width: '45%'
                  }}
                >
                  <select
                    value={this.state.pIndex}
                    onChange={this.handlePIndexChange.bind(this)}
                    className="FormInput"
                  >
                    <option value="" />
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="Row">
              <label>{this.trans('k')}</label>
              <div
                className="Row"
                style={{
                  width: '60%'
                }}
              >
                <div
                  style={{
                    width: '45%'
                  }}
                >
                  <input
                    className="FormInput"
                    type="number"
                    step="0.01"
                    value={this.state.k}
                    onChange={this.handleKChange.bind(this)}
                  />
                </div>
                <div
                  style={{
                    width: '45%'
                  }}
                >
                  <select
                    value={this.state.kIndex}
                    onChange={this.handleKIndexChange.bind(this)}
                    className="FormInput"
                  >
                    <option value="" />
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="SoilInputButtonContainer">
          <div className="Button" onClick={this.cancel.bind(this)}>
            {this.trans('cancel')}
          </div>
          <div className="Button" onClick={this.submit.bind(this)}>
            {this.trans('submit')}
          </div>
        </div>
      </div>
    );
  }
}

class Soil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disp: 'ph',
      paddock: null,
      selectingPaddocks: false,
      selectedPaddocks: [],
      sampleDates: [],
      sampleDateIndex: -1
    };
    this.fieldArray = ['ph', 'p', 'k'];
  }
  handleDispChange(event) {
    this.setState({ disp: event.target.value });
  }
  selectPaddock(p) {
    if (this.state.selectingPaddocks && p) {
      const selectedPaddocks = this.state.selectedPaddocks;
      const index = selectedPaddocks.indexOf(p);
      if (index < 0) {
        selectedPaddocks.push(p);
      } else {
        selectedPaddocks.splice(index, 1);
      }
      this.setState({ selectedPaddocks: selectedPaddocks });
    }
  }
  setPaddock(p) {
    this.setState({ paddock: p });
  }
  setSelectingPaddocks(b) {
    this.setState({
      selectingPaddocks: b,
      selectedPaddocks: [],
      paddock: null
    });
  }
  changeDateIndex(n) {
    this.setState({
      sampleDateIndex: this.state.sampleDateIndex + n
    });
  }
  componentWillMount() {
    const sampleDates = [];
    this.props.soil_data.samples.forEach(function(sample) {
      const sampleDate = moment(sample.timestamp);
      let isNew = true;
      sampleDates.forEach(
        date => (date.isSame(sampleDate, 'day') ? (isNew = false) : null)
      );
      if (isNew) {
        sampleDates.push(sampleDate);
      }
      // const results = sample.results; results.forEach(function (result) {   const
      // fields = result.fields;   fields.forEach(function (field) {     if
      // (fieldArray.indexOf(field.abbr) < 0) {       fieldArray.push(field.abbr); }
      // }); });
    });
    if (sampleDates.length > 0) {
      const sortedArray = sampleDates.sort((a, b) => a.diff(b));
      this.setState({
        sampleDates: sortedArray,
        sampleDateIndex: sortedArray.length - 1
      });
    }
    // fieldArray.sort(); if (fieldArray.length === 0) {}
    const soil_data = this.props.soil_data;
    const paddocks = this.props.dataset.paddocks;
    paddocks.forEach(function(pad) {
      pad['soilSurveys'] = [];
      const samples = soil_data.samples;
      for (let i = 0; i < samples.length; i++) {
        if (samples[i].paddocks.indexOf(pad.id) >= 0) {
          pad['soilSurveys'].push(samples[i]);
        }
      }
    });
  }

  render() {
    // fieldArray.sort(); if (fieldArray.length === 0) {}
    const soil_data = this.props.soil_data;
    const paddocks = this.props.dataset.paddocks;
    paddocks.forEach(function(pad) {
      pad['soilSurveys'] = [];
      const samples = soil_data.samples;
      for (let i = 0; i < samples.length; i++) {
        if (samples[i].paddocks.indexOf(pad.id) >= 0) {
          pad['soilSurveys'].push(samples[i]);
        }
      }
    });
    let soilResultTable = null;

    this.trans = Translation.getInstance().translate;

    if (this.state.paddock) {
      if (this.props.soil_data.samples.length === 0) {
        soilResultTable = (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              color: 'var(--text-dark)'
            }}
          >
            <p>
              {this.trans('no_soil_surveys_found_for_paddock')}{' '}
              {this.state.paddock.name}
            </p>
          </div>
        );
      } else {
        soilResultTable = (
          <PaddockSoilTable
            sampleDates={this.state.sampleDates}
            sampleDateIndex={this.state.sampleDateIndex}
            paddock={this.state.paddock}
            fieldArray={this.fieldArray}
          />
        );
      }
    } else {
      if (this.props.soil_data.samples.length === 0) {
        soilResultTable = (
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '20px',
              color: 'var(--text-dark)'
            }}
          >
            <p>{this.trans('no_soil_surveys_found')}</p>
          </div>
        );
      } else {
        soilResultTable = (
          <FarmSoilTable
            sampleDates={this.state.sampleDates}
            sampleDateIndex={this.state.sampleDateIndex}
            paddocks={this.props.dataset.paddocks}
            fieldArray={this.fieldArray}
          />
        );
      }
    }
    return (
      <div className="Soil">
        <div className="SoilCol">
          <div
            style={{
              height: '103px',
              display: 'flex'
            }}
          >
            <SampleDateSelector
              changeDateIndex={this.changeDateIndex.bind(this)}
              sampleDates={this.state.sampleDates}
              sampleDateIndex={this.state.sampleDateIndex}
            />
            <SoilSelector
              disp={this.state.disp}
              handleDispChange={this.handleDispChange.bind(this)}
              fieldArray={this.fieldArray}
            />
            <SoilMapDownload
              disp={this.state.disp}
              sampleDates={this.state.sampleDates}
              sampleDateIndex={this.state.sampleDateIndex}
            />
          </div>
          <SoilMap
            sampleDates={this.state.sampleDates}
            sampleDateIndex={this.state.sampleDateIndex}
            selectPaddock={this.selectPaddock.bind(this)}
            selectingPaddocks={this.state.selectingPaddocks}
            selectedPaddocks={this.state.selectedPaddocks}
            paddocks={this.props.dataset.paddocks}
            soil_data={this.props.soil_data}
            disp={this.state.disp}
            setPaddock={this.setPaddock.bind(this)}
            paddock={this.state.paddock}
          />
        </div>
        <div className="SoilCol">
          {soilResultTable}
          <SoilInput
            loadSoilData={this.props.loadSoilData}
            selectedPaddocks={this.state.selectedPaddocks}
            selectingPaddocks={this.state.selectingPaddocks}
            setSelectingPaddocks={this.setSelectingPaddocks.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default Soil;
