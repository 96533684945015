import React, { Component } from 'react';
import './Login.css';
import axios from 'axios';
import UserController from './UserController';
import Spinner from './img/Spinner.png';
import Translation from './Translation';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: null,
      sent: false
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.setAppState = this.props.setAppState.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }

  handleInput(event) {
    const self = this;
    if (!this.state.username) {
      self.setState({
        error: this.trans('input_username')
      });
      return;
    }
    if (!this.state.password) {
      self.setState({
        error: this.trans('input_password')
      });
      return;
    }
    self.setState({ sent: true });
    const setAppState = this.setAppState;
    const username = this.state.username;
    const password = this.state.password;
    axios
      .post('https://staging.grasslandtools.ie/api/login/', {
        username: username,
        password: password
      })
      .then(function(response) {
        if (response.data.sessionid) {
          UserController.setSessionkey(response.data.sessionid);
          UserController.setEmail(response.data.email);
          UserController.setWhitelist(response.data.whitelist);
          localStorage.setItem('sessionid', response.data.sessionid);
          self.props.getDataset();
          setAppState({ userLoggedIn: true });
        }
        if (response.data.error) {
          self.setState({ error: response.data.error, sent: false });
        }
      })
      .catch(function(error) {
        alert(error);
        self.setState({ sent: false });
      });
    event.preventDefault();
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleInput(event);
    }
  };

  render() {
    if (this.state.sent) {
      return (
        <div
          className="Login"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{this.trans('signing_you_in')}...</div>
        </div>
      );
    }
    return (
      <div className="Login">
        <form>
          <h3>{this.trans('login_below')}</h3>
          <p
            style={{
              color: 'red'
            }}
          >
            {this.state.error}
          </p>
          <label>{this.trans('username')}</label>
          <input
            className="FormInput"
            type="text"
            id="username"
            value={this.state.username}
            onChange={this.handleUsernameChange}
            onKeyPress={this.handleKeyPress}
            name="username"
            placeholder={this.trans('username')}
          />
          <p />
          <label>{this.trans('password')}</label>
          <input
            className="FormInput"
            type="password"
            id="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
            onKeyPress={this.handleKeyPress}
            name="password"
            placeholder={this.trans('password')}
          />
          <p />
          <div
            className="Button"
            id="button"
            value="Submit"
            onClick={this.handleInput}
          >
            {this.trans('login')}
          </div>
        </form>
        <p />
      </div>
    );
  }
}

export default Login;
