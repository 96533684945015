import React, { Component } from 'react';
import Translation from './Translation';
import axios from 'axios';
import UserController from './UserController';

import Drop from './img/Drop.svg';
import Add from './img/Add.svg';
import './Dashboard.css';
class PaddockBlocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      sent: false
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }

  save(){
    const self = this;
    self.setState({ sent: true });
    var paddocks = this.props.farm.paddocks.map(
      (p, j)=> {
        return {
          "id": p.id, 
          "name": p.name, 
          "block": p.block ? this.props.farm.blocks[p.block-1] : null
        };
      }
      )
    axios
      .post('https://staging.grasslandtools.ie/api/updateblocks/', {
        sessionid: UserController.getSessionkey(),
        paddocks: paddocks,
      })
      .then(function(response) {
        self.setState({ sent: false });
      })
      .catch(function(error) {
        self.setState({ sent: false });
        alert(self.trans('error'));
        console.log(error);
      });
  }

  addBlock(){
    var name = window.prompt(this.trans("block_name"));
    let b = this.props.farm.blocks.slice();
    if (name && b.indexOf(name) < 0) {
      this.props.updateDatasetBlocks({block: name, padstr:""})
    }
  }

  render() {
    const blocks = this.props.farm.blocks;
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('create_manage_blocks')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "0 20px 10px"}}>
          <div className="MetricGroup">
            <div className="Metric" style={{width: "50%"}}>
              <div className="Button Action" style={{justifyContent: "space-around"}} onClick={this.addBlock.bind(this)}>
                <img style={{width: "16px", height: "16px", opacity: 0}} src={Add} alt="" />
                {this.trans("add_block")}
                <img style={{width: "16px", height: "16px", filter:"invert(0)"}} src={Add} alt="" />
              </div>
            </div>
            <div className="Metric" style={{width: "50%"}}>
              <div className="Button Action" onClick={this.save.bind(this)}>
                {this.state.sent ? this.trans("saving_") : this.trans('save')}
              </div>
            </div>
          </div>
          <table className="BlockTable" style={{width: "100%", padding: "0 10px"}}>
            <thead>
              <tr>
                {blocks.map((b, i)=><th key={i} style={{width: "calc((100% - 30px) / " + (blocks.length) + ")"}}>{b}<br/>({Math.round(this.props.farm.paddocks.filter((p)=>p.block===this.props.farm.blocks.indexOf(b)+1).reduce((a,b)=>a+b.area, 0) / 100) / 100} ha)</th>)}
              </tr>
            </thead>
            <tbody>
              {this.props.farm.paddocks.map((p, j)=><tr key={j}>
              {blocks.map(
                (b, i)=><td 
                key={i}>
                  {(p.block===this.props.farm.blocks.indexOf(b)+1) ? <div className="Button Green" onClick={this.props.updateDatasetBlocks.bind(this, {padstr: '' + p.id, block: b})}>{p.name}</div> : <div className="Button Grey" onClick={this.props.updateDatasetBlocks.bind(this, {padstr: '' + p.id, block: b})}>{p.name}</div>}</td>)}
              </tr>)}
            </tbody>
          </table>
        </div>}
      </div>
    );
  }
}

export default PaddockBlocks;
