import React, { Component } from "react";
import Calendar from "./Calendar";
import HerdProfile from "./HerdProfile";
import HerdProgression from "./HerdProgression";
import InputAnimals from "./InputAnimals";
import Loading from "./Loading";

import moment from "moment-with-locales-es6";
import Translation from "./Translation";
import { min, max } from "d3-array";

import { Range } from "rc-slider";
import WRGettingStarted from "./WRGettingStarted";

import "rc-slider/assets/index.css";
import "./Weight.css";
import RadialRangeChart from "./RadialRangeChartThing";

moment.locale(Translation.getInstance().langCode());

class WeightDashboard extends Component {
  constructor(props) {
    super(props);
    const selectedSession = this.props.herd.sessions
      ? this.props.herd.sessions[this.props.herd.sessions.length - 1]
      : null;

    this.state = {
      herd: this.props.herd,
      selectedSession: selectedSession,
      disp_dates: [
        selectedSession ? moment(selectedSession.timestamp) : moment()
      ],
      selected_animal: null,
      filter_dob: null,
      filter_sex: null,
      filter_brd: null,

      filter_variable: null,
      filter_range: null,
      filter_index: null
    };
    this.trans = Translation.getInstance().translate;
  }

  handleDateChange(date, controlKeyPressed) {
    let disp_dates = [];
    if (controlKeyPressed) {
      disp_dates = this.state.disp_dates;
      let containsDate = false;
      for (let c = 0; c < disp_dates.length; c++) {
        let d = disp_dates[c];
        if (moment(d).isSame(date, "day")) {
          containsDate = true;
          if (disp_dates.length > 1) {
            disp_dates.splice(c, 1);
          }
          break;
        }
      }
      if (!containsDate) {
        disp_dates.push(date);
      }
    }

    disp_dates = disp_dates.sort((a, b) => moment(a.valueOf() - b.valueOf()));
    if (disp_dates.length === 0) {
      disp_dates.push(date);
    }
    var selectedSession = this.state.selectedSession;

    const selectedDateSessions = this.state.herd.sessions.filter(s => {
      for (var d of disp_dates) {
        if (moment(s.timestamp).isSame(d, "day")) {
          return true;
        }
      }
    });

    if (
      selectedSession == null ||
      selectedDateSessions.indexOf(selectedSession) < 0
    ) {
      selectedSession = selectedDateSessions[selectedDateSessions.length - 1];
    }
    this.setState({
      disp_dates: disp_dates,
      selectedSession: selectedSession,
      selected_animal: null,
      filter_dob: null,
      filter_sex: null,
      filter_brd: null,
      filter_variable: null,
      filter_range: null,
      filter_index: null
    });
  }

  selectSession(session) {
    this.setState({
      selectedSession: session,
      selected_animal: null,
      filter_dob: null,
      filter_sex: null,
      filter_brd: null,
      filter_variable: null,
      filter_range: null,
      filter_index: null
    });
  }

  selectDob(dob) {
    this.setState({
      filter_dob: this.state.filter_dob === dob ? null : dob,
      filter_variable: null,
      filter_range: null,
      filter_index: null
    });
  }

  selectSex(sex) {
    this.setState({
      filter_sex: this.state.filter_sex === sex ? null : sex,
      filter_variable: null,
      filter_range: null,
      filter_index: null
    });
  }

  selectBreed(brd) {
    this.setState({
      filter_brd: this.state.filter_brd === brd ? null : brd,
      filter_variable: null,
      filter_range: null,
      filter_index: null
    });
  }

  selectChartFilter(variable, min, max, index) {
    if (
      this.state.filter_variable === variable &&
      this.state.filter_range &&
      this.state.filter_range[0] === min &&
      this.state.filter_range[1] === max
    ) {
      this.setState({
        filter_variable: null,
        filter_range: null,
        filter_index: null
      });
    } else {
      this.setState({
        filter_variable: variable,
        filter_range: [min, max],
        filter_index: index
      });
    }
  }

  selectAnimal(animal) {
    if (
      this.state.selected_animal &&
      animal &&
      this.state.selected_animal.tag_no === animal.tag_no
    ) {
      this.setState({ selected_animal: null });
    } else {
      this.setState({ selected_animal: animal });
    }
  }

  render() {
    console.log(this.state)
    if (!this.state.herd.has_animals) {
      return (
        <InputAnimals
          herd_id={this.state.herd_id}
          animalsAdded={this.animalsAdded}
          loadingMessage={this.loadingMessage}
        />
      );
    }
    if (this.state.herd.sessions.length === 0) {
      return (
        <WRGettingStarted
          herd_id={this.state.herd.herdid}
          herd_name={this.state.herd.herdname}
        />
      );
    }

    const surveyDates = this.state.herd.sessions
      .map(s => s.timestamp.split("T")[0])
      .filter((value, index, self) => self.indexOf(value) == index);

    const selectedDateSessions = this.state.herd.sessions.filter(s => {
      for (var d of this.state.disp_dates) {
        if (moment(s.timestamp).isSame(d, "day")) {
          return true;
        }
      }
    });

    const dobBreakdown = {};
    const sexBreakdown = {};
    const breedBreakdown = {};
    const filteredMeasurements = [];
    const remainingMeasurements = [];
    for (let a of this.state.selectedSession.measurements) {
      const dob = moment(a.dob);
      const dobYear = dob.year();
      const dobSeason = [0, 0, 0, 1, 1][dob.quarter()];
      const dobPeriod = dobYear + " " + dobSeason;
      if (dobPeriod in dobBreakdown) {
        dobBreakdown[dobPeriod] += 1;
      } else {
        dobBreakdown[dobPeriod] = 1;
      }

      if (a.sex in sexBreakdown) {
        sexBreakdown[a.sex] += 1;
      } else {
        sexBreakdown[a.sex] = 1;
      }

      if (a.brd in breedBreakdown) {
        breedBreakdown[a.brd] += 1;
      } else {
        breedBreakdown[a.brd] = 1;
      }

      if (this.state.filter_sex !== null && a.sex !== this.state.filter_sex) {
        remainingMeasurements.push(a);
      } else if (
        this.state.filter_brd !== null &&
        a.brd !== this.state.filter_brd
      ) {
        remainingMeasurements.push(a);
      } else if (
        this.state.filter_dob !== null &&
        dobPeriod !== this.state.filter_dob
      ) {
        remainingMeasurements.push(a);
      } else {
        filteredMeasurements.push(a);
      }
    }

    if (this.state.filter_variable) {
      const chartFilteredMeasurements = filteredMeasurements.filter(
        s =>
          s[this.state.filter_variable] >= this.state.filter_range[0] &&
          s[this.state.filter_variable] <= this.state.filter_range[1]
      );
    }

    console.log(this.state);
    return (
      <div id="ChartContainer" className="ChartContainer">
        <div
          className="ChartCol"
          style={{
            width: "calc(100vw - 400px)"
          }}
        >
          {/* <div className="DashboardTabList">
            <div className="TabsLabel">{this.trans("block")}</div>
          </div>
          <div className="DashboardTabList">
            <div className="TabsLabel">{this.trans("status")}</div>
          </div> */}

          <HerdProgression
            selectedAnimals={filteredMeasurements}
            selected_animal={this.state.selected_animal}
            measurements={this.state.herd.sessions}
            selected_session={this.state.selectedSession}
            height={
              "calc((100vh - 65px) - min(calc((100vh - 65px) / 3), calc((100vw - 400px)/4)))"
            }
            width={"calc(100vw - 400px)"}
            id="HerdProgressionSVG"
            variable="weight"
            label="live_weight_kg_svg"
          />
          <div
            style={{
              width: "calc(100vw - 400px)",
              height: "min(calc((100vh - 65px) / 3), calc((100vw - 400px)/4))",
              display: "flex",
              flexDirection: "row"
            }}
          >
            <RadialRangeChart
              measurements={filteredMeasurements}
              selected_animal={this.state.selected_animal}
              height={"min(calc((100vh - 65px) / 3), calc((100vw - 400px)/4))"}
              width={"calc((100vw - 400px)/2)"}
              id="RadialRangeSVG1"
              variable="weight"
              label="weight"
              unit="kg"
              selectChartFilter={this.selectChartFilter.bind(this)}
              filter_variable={this.state.filter_variable}
              filter_index={this.state.filter_index}
            />
            <RadialRangeChart
              measurements={filteredMeasurements}
              selected_animal={this.state.selected_animal}
              height={"min(calc((100vh - 65px) / 3), calc((100vw - 400px)/4))"}
              width={"calc((100vw - 400px)/2)"}
              id="RadialRangeSVG2"
              variable="adg_last"
              label="adg"
              unit="kg"
              selectChartFilter={this.selectChartFilter.bind(this)}
              filter_variable={this.state.filter_variable}
              filter_index={this.state.filter_index}
            />
          </div>
        </div>
        <div
          className="ChartCol"
          style={{
            width: "400px"
          }}
        >
          <div className="WeightRightPanel">
            <div className="HalfWidth">
              <Calendar
                className="ScrollPanelItem"
                surveyDates={surveyDates}
                setDate={this.handleDateChange.bind(this)}
                dispDates={this.state.disp_dates}
              />
              {selectedDateSessions.map((s, i) => (
                <div
                  key={i}
                  className={
                    "WeighingSessionSelector" +
                    (s === this.state.selectedSession ? " Selected" : "")
                  }
                  onClick={this.selectSession.bind(this, s)}
                >
                  <span>
                    {moment(s.timestamp).format("HH:mm | Do MMM YYYY")}
                  </span>
                  <span>
                    {s.measurements.length}{" "}
                    {s.measurements.length == 1
                      ? this.trans("animal")
                      : this.trans("animals")}
                  </span>
                </div>
              ))}
              <div className="ProportionLineContainer">
                {Object.keys(dobBreakdown)
                  .sort()
                  .map((dob, i) => (
                    <div
                      key={i}
                      className="Proportion"
                      style={{ flexGrow: dobBreakdown[dob] }}
                      title={
                        ["Spring", "Autumn"][parseInt(dob.split(" ")[1])] +
                        " " +
                        dob.split(" ")[0]
                      }
                    >
                      <div
                        className="ProportionLine"
                        onClick={this.selectDob.bind(this, dob)}
                        style={{
                          backgroundColor:
                            "hsl(" +
                            (200 + 150 * i) +
                            ",64%," +
                            (this.state.filter_dob === dob
                              ? 56
                              : this.state.filter_dob === null
                              ? 72
                              : 80) +
                            "%)",
                          color:
                            this.state.filter_dob === dob ? "white" : "#51534a"
                        }}
                      >
                        <span>{dobBreakdown[dob]}</span>
                      </div>
                      <div
                        className="ProportionLabel"
                        onClick={this.selectDob.bind(this, null)}
                      >
                        <span>
                          {
                            (dobBreakdown[dob] /
                              this.state.selectedSession.measurements.length >
                            0.2
                              ? ["Spring", "Autumn"]
                              : ["Sp", "At"])[parseInt(dob.split(" ")[1])]
                          }
                          &nbsp;
                          {dobBreakdown[dob] /
                            this.state.selectedSession.measurements.length >
                          0.15
                            ? dob.split(" ")[0]
                            : "'" + dob.split(" ")[0].substring(2, 4)}
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="ProportionLineContainer">
                {Object.keys(sexBreakdown)
                  .sort()
                  .map((sex, i) => (
                    <div
                      key={i}
                      className="Proportion"
                      style={{ flexGrow: sexBreakdown[sex] }}
                      title={sex}
                    >
                      <div
                        className="ProportionLine"
                        onClick={this.selectSex.bind(this, sex)}
                        style={{
                          backgroundColor:
                            "hsl(" +
                            150 * i +
                            ",64%," +
                            (this.state.filter_sex === sex
                              ? 56
                              : this.state.filter_sex === null
                              ? 72
                              : 80) +
                            "%)",
                          color:
                            this.state.filter_sex === sex ? "white" : "#51534a"
                          // "#" + (((1 << 24) * Math.random()) | 0).toString(16)
                        }}
                      >
                        <span>{sexBreakdown[sex]}</span>
                      </div>
                      <div
                        className="ProportionLabel"
                        onClick={this.selectSex.bind(this, null)}
                      >
                        <span>{sex}</span>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="ProportionLineContainer">
                {Object.keys(breedBreakdown)
                  .sort()
                  .map((breed, i) => (
                    <div
                      key={i}
                      className="Proportion"
                      style={{ flexGrow: breedBreakdown[breed] }}
                      title={breed}
                    >
                      <div
                        className="ProportionLine"
                        onClick={this.selectBreed.bind(this, breed)}
                        style={{
                          backgroundColor:
                            "hsl(" +
                            (100 + 150 * i) +
                            ",64%," +
                            (this.state.filter_brd === breed
                              ? 56
                              : this.state.filter_brd === null
                              ? 72
                              : 80) +
                            "%)",
                          color:
                            this.state.filter_brd === breed
                              ? "white"
                              : "#51534a"
                        }}
                      >
                        <span>{breedBreakdown[breed]}</span>
                      </div>
                      <div
                        className="ProportionLabel"
                        onClick={this.selectBreed.bind(this, null)}
                      >
                        <span>{breed}</span>
                      </div>
                    </div>
                  ))}
              </div>
              {/* <div className="WeighingSessionSelector" style={{fontWeight: "bold"}}>
                <span>Download CSV File</span>
                <span>{filteredMeasurements.length} Animal{filteredMeasurements.length!==1?"s":""}</span>
              </div> */}
              <div>
                {this.state.filter_variable ? (
                  <div>
                    {filteredMeasurements
                      .filter(
                        s =>
                          s[this.state.filter_variable] >=
                            this.state.filter_range[0] &&
                          s[this.state.filter_variable] <=
                            this.state.filter_range[1]
                      )
                      .slice().sort((a, b)=>a[this.state.filter_variable]-b[this.state.filter_variable]).map((m, i) => (
                        <TagButton
                          key={i}
                          animal={m}
                          selectAnimal={this.selectAnimal.bind(this, m)}
                          selected={m === this.state.selected_animal}
                          filtered={true}
                          filter_index={this.state.filter_index}
                        />
                      ))}
                    {filteredMeasurements
                      .filter(
                        s =>
                          !(
                            s[this.state.filter_variable] >=
                              this.state.filter_range[0] &&
                            s[this.state.filter_variable] <=
                              this.state.filter_range[1]
                          )
                      )
                      .map((m, i) => (
                        <TagButton
                          key={i}
                          animal={m}
                          selectAnimal={this.selectAnimal.bind(this, m)}
                          selected={m === this.state.selected_animal}
                          filtered={true}
                        />
                      ))}
                  </div>
                ) : (
                  filteredMeasurements.map((m, i) => (
                    <TagButton
                      key={i}
                      animal={m}
                      selectAnimal={this.selectAnimal.bind(this, m)}
                      selected={m === this.state.selected_animal}
                      filtered={true}
                    />
                  ))
                )}
                {remainingMeasurements.map((m, i) => (
                  <TagButton
                    key={i}
                    animal={m}
                    selectAnimal={this.selectAnimal.bind(this, m)}
                    selected={m === this.state.selected_animal}
                    filtered={false}
                  />
                ))}
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <div className="CopyrightContainer">
                <span style={{ fontSize: "0.9em" }}>
                  {this.trans("copyright") + " "} © 2017 -{" "}
                  {moment().format("YYYY")} True North Technologies Ltd.
                </span>
              </div>
            </div>
          </div>
          <div className="CopyrightContainer">
            <span>
              {this.trans("copyright") + " "} © 2017 - {moment().format("YYYY")}{" "}
              True North Technologies Ltd.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default WeightDashboard;

class TagButton extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    const animal = this.props.animal;
    const tag_no = animal.tag_no;
    const sections = [];
    if (tag_no.length === 14) {
      sections.push(tag_no.substring(0, 2));
      sections.push(
        tag_no.substring(2, 4) +
          "-" +
          tag_no.substring(4, 9) +
          "-" +
          tag_no.substring(9, 10)
      );
      sections.push(tag_no.substring(10));
    } else if (tag_no.length === 15) {
      sections.push(tag_no.substring(0, 3));
      sections.push(tag_no.substring(3, 5) + "-" + tag_no.substring(5, 10));
      sections.push(tag_no.substring(10));
    } else {
      sections.push(tag_no);
    }
    let buttonClassName = "TagButton";
    if (this.props.selected) {
      buttonClassName += " Expanded";
    }
    if (this.props.filtered) {
      buttonClassName += " Filtered";
    }

    const filter_borders = [
      "hsl(0, 64%, 56%)",
      "hsl(200, 64%, 56%)",
      "hsl(100, 64%, 56%)"
    ];
    const filter_backgrounds = [
      "hsl(0, 64%, 72%)",
      "hsl(200, 64%, 72%)",
      "hsl(100, 64%, 72%)"
    ];
    return (
      <div
        className="TagButtonContainer"
        style={
          this.props.filter_index || this.props.filter_index === 0
            ? {
                borderColor: filter_borders[this.props.filter_index]
              }
            : {}
        }
      >
        <div
          className={buttonClassName}
          onClick={this.props.selectAnimal}
          style={
            this.props.filter_index || this.props.filter_index === 0
              ? {
                  backgroundColor: filter_backgrounds[this.props.filter_index]
                }
              : {}
          }
        >
          <span>
            {sections.map((s, i) => (
              <span key={i}>{s}</span>
            ))}
          </span>
          <span>{animal.weight + " kg"}</span>
        </div>
        <div
          className={
            this.props.selected ? "AnimalDetails Show" : "AnimalDetails"
          }
        >
          <div className="AnimalDetailsRow">
            <span>{trans("date_of_birth")}:</span>
            <span>{moment(animal.dob).format("D-M-Y")}</span>
          </div>
          <div className="AnimalDetailsRow">
            <span>{trans("breed")}:</span>
            <span>{animal.brd}</span>
          </div>
          <div className="AnimalDetailsRow">
            <span>{trans("sex")}:</span>
            <span>{animal.sex.toUpperCase()}</span>
          </div>
          <div className="AnimalDetailsRow">
            <span>{trans("weight")}:</span>
            <span>{animal.weight} kg</span>
          </div>
          <div className="AnimalDetailsRow">
            <span>{trans("bcs")}:</span>
            <span>{animal.condition}</span>
          </div>
          <div className="AnimalDetailsRow">
            <span>{trans("docility")}:</span>
            <span>{animal.docility}</span>
          </div>

          <div className="AnimalDetailsRow">
            <span>{trans("average_daily_gain")}:</span>
          </div>
          <div className="AnimalDetailsRow">
            <span style={{ paddingLeft: "20px" }}>
              {trans("since_last_measure")}:
            </span>
            <span>
              {animal.adg_last !== null
                ? Math.round(animal.adg_last * 100) / 100
                : "~"}{" "}
              kg
            </span>
          </div>

          <div className="AnimalDetailsRow">
            <span style={{ paddingLeft: "20px" }}>{trans("since_birth")}:</span>
            <span>
              {animal.adg_birth !== null
                ? Math.round(animal.adg_birth * 100) / 100
                : "~"}{" "}
              kg
            </span>
          </div>
        </div>
      </div>
    );
  }
}
