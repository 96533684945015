import React, { Component } from 'react';

import './App.css';
import WeightDashboard from './WeightDashboard';

class WeightController extends Component {
  componentWillMount() {
    this.props.setDisplay('weight_dashboard');
  }

  render() {
    return (
      <div className="Body">
        <WeightDashboard
          herd={this.props.herd}
          getDataset={this.props.getDataset}
          appkey={this.props.appkey}
        />
      </div>
    );
  }
}

export default WeightController;
