import React, { Component } from 'react';
import Translation from './Translation';

import moment from 'moment-with-locales-es6';
import axios from 'axios';
import Drop from './img/Drop.svg';
import './Dashboard.css';
import UserController from './UserController';
import Spinner from './img/Spinner.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
class PaddockGrazing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      entering: false,
      gotData: false,
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }
  setEntering(value) {
    this.setState({entering: value})
  }

  render() {
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('grazed_out_dates')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "10px 20px 10px"}} className="MetricsPanel">
          <div className="ToggleContainer">
            <div 
              onClick={this.setEntering.bind(this, false)} 
              className={this.state.entering ? "Toggle" : "Toggle Active"}>
                {this.trans('view_graze_dates')}
            </div>
            <div 
              onClick={this.setEntering.bind(this, true)}
              className={this.state.entering ? "Toggle Active" : "Toggle"}>
                {this.trans('input_graze_date')}
            </div>
          </div>
          {this.props.paddock ? this.state.entering ? <GrazingInput
            setEntering={this.setEntering.bind(this)}
            paddock={this.props.paddock}
          /> : <GrazeDateList
            
            paddock={this.props.paddock}
          /> : <p>{this.trans('select_a_paddock_on_the_farm_map_or_grass_wedge')}</p>}
          
        </div>}
      </div>
    );
  }
}

export default PaddockGrazing;


class GrazeDateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: null,
      graze_dates: [],
    };
    this.trans = Translation.getInstance().translate;
    this.fetchGrazeDates = this.fetchGrazeDates.bind(this)
  }

  componentDidMount(){
    this.fetchGrazeDates(this.props.paddock.id)
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.paddock.id !== this.props.paddock.id) {
      this.fetchGrazeDates(nextProps.paddock.id)
    }
    return true
  }

  fetchGrazeDates(padid) {
    this.setState({
      loaded: false,
      error: null,
      graze_dates: [],
    })
    const self = this;
    axios.post("https://staging.grasslandtools.ie/api/listgrazedoutdates/", {
      sessionid: UserController.getSessionkey(),
      paddock: padid,
    }).then(function(response) {
      self.setState({
        loaded: true,
        error: null,
        graze_dates: response.data.graze_dates,
      })
    }).catch(function(error) {
      self.setState({
        loaded: true,
        error: this.trans('error_fetching_graze_dates_for_') + self.props.paddock.name,
        graze_dates: [],
      })
    })
  }

  render() {
    if (!this.state.loaded) {
      return <p>{this.trans('loading___')}</p>
    }
    if (this.state.error) {
      return <p>{this.state.error}</p>
    }
    if (this.state.graze_dates.length == 0) {
      return <p>{this.trans('no_graze_dates_recorded_for_')} {this.props.paddock.name}</p>
    }
    return <div style={{padding: "10px"}}>
      <table className="SelectedTable">
        <thead>
          <tr>
            <th>{this.trans('date')}</th>
            <th>{this.trans('pre_grazing_cover')} (kg/ha)</th>
            <th>{this.trans('post_grazing_cover')} (kg/ha)</th>
            <th>{this.trans('number_of_animals')}</th>
            <th>{this.trans('allocation_per_animal')} (kg)</th>
          </tr>
        </thead>
        <tbody>
          {this.state.graze_dates.map((d, i)=><tr key={i}>
            <td>{d.date}</td>
            <td>{d.pre_cover}</td>
            <td>{d.post_cover}</td>
            <td>{d.num_animals}</td>
            <td>{d.allocation_animal}</td>
          </tr>)}
        </tbody>
      </table>
    </div>
  }
}


class GrazingInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      olddate: moment(),
      paddock: '',
      pre: '',
      post: '',
      nag: '',
      alloc: 17,
      sent: false
    };
    this.trans = Translation.getInstance().translate;
  }
  handleDateChange(d) {
    this.setState({ date: d });
  }
  handlePreChange(event) {
    this.setState({ pre: event.target.value });
  }
  handlePostChange(event) {
    this.setState({ post: event.target.value });
  }
  handleNagChange(event) {
    this.setState({ nag: event.target.value });
  }
  handleAllocChange(event) {
    this.setState({ alloc: event.target.value });
  }
  submit() {
    if (!this.props.paddock) {
      alert(this.trans('no_paddock_selected_submit'));
      return;
    }
    if (!this.state.date) {
      alert(this.trans('valid_date'));
      return;
    }
    const paddock = this.props.paddock;
    const date = this.state.date.format('YYYY-MM-DD');
    const pre = this.state.pre;
    const post = this.state.post;
    const nag = this.state.nag;
    const alloc = this.state.alloc;
    const self = this;
    this.setState({ sent: true });
    axios
      .post('https://staging.grasslandtools.ie/api/insertgrazedoutdate/', {
        sessionid: UserController.getSessionkey(),
        date: date,
        paddock: paddock.id,
        pre: pre,
        post: post,
        nag: nag,
        alloc: alloc
      })
      .then(function(response) {
        self.props.setEntering(false);
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }
  render() {
    if (this.state.sent) {
      return (
        <div
          className="InputDiv"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{this.trans('adding_grazing_event')}...</div>
        </div>
      );
    }
    return (
      <div>
        <div className="MetricGroup">
          <div className="Metric">
            <label>
              {this.trans('date')}:
            </label>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              onChange={this.handleDateChange.bind(this)}
              selected={this.state.date}
              className="FormInput"
              placeholderText={this.trans('date')}
              style={{
                zIndex: '5'
              }}
            />
          </div>
          <div className="Metric">
            <label>
            {this.trans('pre_grazing_cover')} (kg/ha):
            </label>
            <input
              value={this.state.pre}
              onChange={this.handlePreChange.bind(this)}
              className="FormInput"
              type="text"
              id="pre-grazing"
              name="pre-grazing"
              placeholder={this.trans('pre_grazing_cover')}
            />
          </div>
          <div className="Metric">
            <label>
            {this.trans('post_grazing_cover')} (kg/ha):
            </label>
            <input
              value={this.state.post}
              onChange={this.handlePostChange.bind(this)}
              className="FormInput"
              type="text"
              id="post-grazing"
              name="post-grazing"
              placeholder={this.trans('post_grazing_cover')}
            />
          </div>
          <div className="Metric">
            <label>
            {this.trans('number_of_animals_grazing')}:
            </label>
            <input
              value={this.state.nag}
              onChange={this.handleNagChange.bind(this)}
              className="FormInput"
              type="text"
              id="animals"
              name="animals"
              placeholder={this.trans('number_of_animals_grazing')}
            />
          </div>
          <div className="Metric">
            <label>
            {this.trans('allocation_per_day')} (kg/ha):
            </label>
            <input
                value={this.state.alloc}
                onChange={this.handleAllocChange.bind(this)}
                className="FormInput"
                type="number"
                id="allocations"
                name="allocations"
                placeholder={this.trans('allocation_per_day')}
              />
          </div>
          <div className="Metric">
            <label style={{opacity: 0}}>
            {this.trans('submit')}
            </label>
            <div className="Button Action" onClick={this.submit.bind(this)}>
              {this.trans('submit')}
            </div>
          </div>
        </div>
      </div>
    );
  }
}