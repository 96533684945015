import languages from './json/languages-new.json';

var Translation = (function() {
  // Instance stores a reference to the Singleton
  var instance;

  function init() {
    var lang = languages.fr;
    var en = languages.en;
    var fr = languages.fr;

    return {
      // Public methods and variables
      setLang: function(langCode) {
        lang = languages[langCode] || fr;
      },

      translate: function(string) {
        //set this to true to debug translations
        if (false) {
          if (lang[string]) {
            return '[translated]'; //exists and is translated
          } else if (FileReader[string]) {
            return '[untranslated]'; // exists in english, but is not translated
          } else {
            return '[unknown(' + string + ')]'; // this string isn't in the json file
          }
        }

        return lang[string] || en[string] || string;
      },

      langCode: function() {
        return lang['lang_code'] || 'en';
      }
    };
  }

  return {
    // Get the Singleton instance if one exists or create one if it doesn't
    getInstance: function() {
      if (!instance) {
        instance = init();
      }

      return instance;
    }
  };
})();

export default Translation;
