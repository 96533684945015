import React, { Component } from 'react';

import axios from 'axios';
import WeatherMap from './WeatherMap';
import WeatherChart from './WeatherChart';
import UserController from './UserController';
import rain from './img/rain_icon.png';
import sun from './img/sunshine_icon.png';
import temp from './img/temp_icon.png';
import Translation from './Translation';

class Weather extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weatherArray: [],
      latestWeather: [],
      position: {
        lat: 0,
        lng: 0
      },
      selectedStation: null
    };
    this.trans = Translation.getInstance().translate;
    this.selectStation = this.selectStation.bind(this);
  }
  componentDidMount() {
    const self = this;
    const farmID = UserController.getFarmid();
    axios
      .get('https://staging.grasslandtools.ie/api/weather/', {
        params: {
          farmID: farmID
        }
      })
      .then(response => {
        self.setState({
          weatherArray: response.data.data,
          latestWeather: response.data.data[0].reports,
          position: response.data.location
        });
      });
  }
  selectStation(s) {
    const station = s === this.state.selectedStation ? null : s;
    this.setState({ selectedStation: station });
  }
  render() {
    return (
      <div
        style={{
          height: '100%'
        }}
      >
        <div
          id="WeatherMapRow"
          style={{
            height: 'calc(70% - 20px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <WeatherMap
            id={'soil_temp_map'}
            icon={temp}
            weather={this.state.latestWeather}
            selectStation={this.selectStation}
            selectedStation={this.state.selectedStation}
            position={this.state.position}
          />
          <WeatherMap
            id={'rainfall_map'}
            icon={rain}
            weather={this.state.latestWeather}
            selectStation={this.selectStation}
            selectedStation={this.state.selectedStation}
            position={this.state.position}
          />
          <WeatherMap
            id={'sunlight_map'}
            icon={sun}
            weather={this.state.latestWeather}
            selectStation={this.selectStation}
            selectedStation={this.state.selectedStation}
            position={this.state.position}
          />
        </div>
        <div
          id="WeatherChartRow"
          style={{
            height: 'calc(30% - 10px)',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <WeatherChart
            id={'soil_temp_chart'}
            weather={this.state.weatherArray}
            selectedStation={this.state.selectedStation}
          />
          <WeatherChart
            id={'rainfall_chart'}
            weather={this.state.weatherArray}
            selectedStation={this.state.selectedStation}
          />
          <WeatherChart
            id={'sunlight_chart'}
            weather={this.state.weatherArray}
            selectedStation={this.state.selectedStation}
          />
        </div>
        <div
          style={{
            height: '30px'
          }}
        >
          <p
            style={{
              margin: 0
            }}
          >
            {this.trans('data_courtesy_of_met_eireann')}
          </p>
        </div>
      </div>
    );
  }
}

export default Weather;
