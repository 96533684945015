import React, { Component } from 'react';
import Translation from './Translation';

import Drop from './img/Drop.svg';
import './Dashboard.css';
import moment from "moment"

import Formulae from './Formulae';
class Metric extends Component {
  render() {
    return (
      <div className="Metric">
        <label>
          {this.props.label}
        </label>
        <div className="FormInput">
          {this.props.value}
        </div>
      </div>
    );
  }
}
class MetricPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }

  render() {
    const totalGrowth = this.props.cover ? this.props.cover.totalGrowth : 0;
    const hectareGrowth = this.props.cover ? this.props.cover.hectareGrowth : 0;
    const ytdTotalGrowth = this.props.cover
      ? this.props.cover.ytdTotalGrowth
      : 0;
    const ytdHectareGrowth = this.props.cover
      ? this.props.cover.ytdHectareGrowth
      : 0;

    const paddocks = this.props.farm.paddocks;
    const cover = this.props.cover;
    let grazingPlatformCover = 0;
    let grazingPlatformArea = 0;
    // if (cover) {
      const padCovers = cover ? cover.padCovers.slice().reverse() : [];
      for (let i = 0; i < paddocks.length; i++) {
        const pad = paddocks[i];
        if (pad.status >= 1 && pad.status <= 4) {
          grazingPlatformArea += pad.area;
          for (let j = 0; j < padCovers.length; j++) {
            const survey = padCovers[j];
            if (survey.paddock_id === pad.id) {
              grazingPlatformCover +=
                survey.manualCover !== null
                  ? survey.manualCover * pad.area / 10000
                  : survey.dmPaddock;
            }
          }
        } else if (pad.status == 7) {
          grazingPlatformArea += pad.area;
        }
      }
    // }

    let paddock = this.props.paddock;
    let survey = this.props.survey;
    if (survey) {

    }
    if (paddock) {
      survey = this.props.cover
        ? this.props.cover.padCovers
            .slice()
            .find(s => s.paddock_id === paddock.id)
        : {};
    }

    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('cover_details')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "0 20px 10px"}} className="MetricsPanel">
          <div className="MetricGroupHeader">{this.props.survey ? "Survey" : this.props.paddock ? "Paddock" : "Farm"}</div>
          {this.props.survey ? <div className="MetricGroup">
            <Metric label={this.trans('date')+":"} value={moment(this.props.cover.date).format('L')}/>
            <Metric label={this.trans('average_height')+":"} value={this.props.survey.data.avgHeight + " mm"}/>
            <Metric label={this.trans('cover')+":"} value={this.props.survey.data.dmPaddock.toFixed(0) + " kg (" + this.props.survey.data.dmHectare.toFixed(0) + " kg/ha)"}/>
            <Metric label={this.trans('drops')+":"} value={this.props.survey.coords.length}/>
            <Metric label={this.trans('variation')+":"} value={(this.props.survey.data.max_height - this.props.survey.data.min_height) + " mm"}/>
            <Metric label={this.trans('residual')+":"} value={this.props.survey.data.resHeight + " mm"}/>
            <Metric label={this.trans('dry_matter')+":"} value={this.props.survey.data.dmPercentage + " %"}/>
          </div> : this.props.paddock ? <div className="MetricGroup">
            <Metric label={this.trans('name')+":"} value={paddock.name}/>
            <Metric label={this.trans('area')+":"} value={(paddock.area / 10000).toFixed(2) + " ha"}/>
            <Metric label={this.trans('cover')+":"} value={(survey
                      ? (survey.manualCover !== null
                        ? survey.manualCover * paddock.area / 10000
                        : survey.dmPaddock).toFixed(0)
                      : '-') + " kg (" + (survey
                        ? (survey.manualCover !== null
                          ? survey.manualCover
                          : survey.dmHectare).toFixed(0)
                        : '-') + " kg/ha)"}/>
            <Metric label={this.trans('average_height')+":"} value={survey
                      ? survey.manualCover !== null
                        ? '-'
                        : survey.avgHeight + ' mm'
                      : '-'}/>
            <Metric label={this.trans('daily_growth')+":"} value={(survey && survey.growthTot > 0 ? survey.growthTot.toFixed(0) : '-') + " kg (" + 
                    (survey && survey.growthHa > 0 ? survey.growthHa.toFixed(0) : '-') + " kg/ha)"}/>
            <Metric label={this.trans('number_of_rotations')+":"} value={survey ? survey.rotations : '-'}/>
            <Metric label={this.trans('notes')+":"} value={paddock.notes}/>
          </div> : <div className="MetricGroup">
            <Metric label={this.trans('grazing_platform_cover')+":"} value={grazingPlatformCover.toFixed(0) + " kg"}/>
            <Metric label={this.trans('average_cover')+":"} value={(grazingPlatformArea>0?Math.round(grazingPlatformCover / grazingPlatformArea * 10000) : "~") + " kg/ha"}/>
            <Metric label={this.trans('cover_per_animal_svg')+":"} value={(this.props.lu_count>0?(grazingPlatformCover / this.props.lu_count).toFixed(0) : "~") + " kg/LU"}/>
            <Metric label={this.trans('stocking_rate')+":"} value={(grazingPlatformArea>0?(this.props.lu_count / grazingPlatformArea * 10000).toFixed(2) : "~") + " LU/ha"}/>
            <Metric label={this.trans('total_daily_requirement')+":"} value={(this.props.demand ? this.props.demand : "~") + " kg"}/>
            <Metric label={this.trans('demand_line_svg')+":"} value={Math.round(this.props.demand * this.props.rotLength / grazingPlatformArea * 10000 +
              (this.props.coverType=="2" ? Formulae.availableCover(this.props.residual, 0, null): 0)) + " kg/ha"}/>
            <Metric label={this.trans('days_ahead_svg')+":"} value={(grazingPlatformCover / this.props.demand).toFixed(2) + this.trans('_days')}/>
            <Metric label={this.trans('daily_growth')+":"} value={totalGrowth.toFixed(0) + " kg (" + hectareGrowth.toFixed(0) + " kg/ha)"}/>
            <Metric label={this.trans('year_to_date_growth')+":"} value={(ytdTotalGrowth >= 20000 ? (ytdTotalGrowth/1000).toFixed(2) + " t " : ytdTotalGrowth.toFixed(0) + " kg ") + "(" + (ytdHectareGrowth >= 20000 ? (ytdHectareGrowth/1000).toFixed(2) + " t/ha)" : ytdHectareGrowth.toFixed(0) + " kg/ha)")}/>
          </div>}
        </div>}
      </div>
    );
  }
}

export default MetricPanel;
