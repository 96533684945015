import React, { Component } from 'react';
import { min, max } from 'd3-array';
import { axisLeft } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import Translation from './Translation';


import Formulae from './Formulae';

import './Dashboard.css';

class WedgeSVG extends Component {
  constructor(props) {
    super(props);
    this.createGrassWedge = this.createGrassWedge.bind(this);
    this.state = {
      popover_toggled: false
    };

    this.w = 0;
    this.h = 0;
    this.trans = Translation.getInstance().translate;
  }

  togglePopover() {
    this.setState({
      popover_toggled: !this.state.popover_toggled
    });
  }

  componentDidMount() {
    const chart = document.getElementById('GrassWedge');
    this.w = chart.clientWidth;
    this.h = chart.clientHeight;
    this.createGrassWedge();
    window.addEventListener('resize', this.createGrassWedge);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.createFarmMap);
  }

  createGrassWedge() {
    // Remove old tooltip
    select('.tooltip').remove();
    const setPaddock = this.props.setPaddock;


    const id = 'GrassWedgeSVG' + this.props.i;
    const chart = document.getElementById(id);
    if (!chart) {
      return;
    }
    chart.innerHTML = '';

    if (!chart) {
      return;
    }
    const width = chart.clientWidth;
    const height = chart.clientHeight;
    const svg = select('#' + id)
      .append('svg')
      .attr('width', width)
      .attr('height', height);

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', '#ffffff00')
      .on('click', function() {
        setPaddock(null);
      });

    const COVER_TYPE = this.props.coverType;
    const N_ANIMALS = this.props.nAnimals;
    const TARGET_INTAKE = this.props.targetIntake;
    const TARGET_RESIDUAL = this.props.residual;
    const TARGET_EXIT_COVER =
      COVER_TYPE === '2'
        ? Formulae.availableCover(TARGET_RESIDUAL, 0, null)
        : 0;
    const ROTATION_LENGTH = this.props.rotLength;

    const n_days = this.props.n_days;

    const group = this.props.group;
    const tol = group === 1 || group === 5 ? 3 : 0;
    const cover = this.props.cover;

    let padCovers;
    if (cover) {
      padCovers = cover.padCovers
        .slice()
        .sort((a, b) => a.survey_id - b.survey_id);
    } else {
      padCovers = [];
    }

    // Get paddocks
    const paddocks = this.props.paddocks;

    const pad = this.props.paddock;

    let dataset = [];
    let unmeasured = [];
    let pad_count = 0;
    let whole_farm_area = 0;
    let block_area = 0;
    for (let i = 0; i < paddocks.length; i++) {
      let paddock = paddocks[i];
      if (paddock.status >= group && paddock.status <= group + tol) {
        whole_farm_area += paddock.area;
        if (
          this.props.displayBlock <= 0 ||
          paddock.block === this.props.displayBlock
        ) {
          block_area += paddock.area;
          let p = null;
          for (let j = 0; j < padCovers.length; j++) {
            if (padCovers[j].paddock_id === paddock.id) {
              const survey = padCovers[j];
              const area = Math.max(paddock.area, 1);

              const cover_ha =
                survey.manualCover !== null && !isNaN(survey.manualCover)
                  ? survey.manualCover
                  : survey.dmHectare;
              const cover_pd =
                survey.manualCover !== null && !isNaN(survey.manualCover)
                  ? survey.manualCover * area / 10000
                  : survey.dmPaddock;
              if (!isNaN(cover_ha) && !isNaN(cover_pd)) {
                p = {
                  name: paddock.name,
                  area: area,
                  survey: {
                    cover_proj: cover_ha + n_days * cover.hectareGrowth,
                    cover_ha: cover_ha,
                    cover_pd: cover_pd,
                    survey_date: cover.date,
                    avg_height:
                      survey.manualCover !== null ? 'N/A' : survey.avgHeight
                  }
                };
              }
              break;
            }
          }
          if (p !== null) {
            dataset.push(p);
          } else {
            unmeasured.push({
              name: paddock.name,
              area: Math.max(paddock.area, 0.01),
              survey: {
                cover_ha: TARGET_EXIT_COVER,
                cover_pd: 0
              }
            });
          }
          pad_count++;
        }
      }
    }

    const block_ratio = whole_farm_area > 0 ? block_area / whole_farm_area : 0;

    if (pad_count === 0) {
      svg
        .append('text')
        .text(this.trans('no_paddocks_found_in_selected_group_wedge'))
        .attr('class', 'svgMessage')
        .attr('x', width / 2)
        .attr('y', height / 2);

      return;
    }

    dataset.sort(function(a, b) {
      return (
        b.survey.cover_ha +
        n_days * cover.hectareGrowth -
        (a.survey.cover_ha + n_days * cover.hectareGrowth)
      );
    });

    for (let i = 0; i < unmeasured.length; i++) {
      dataset.push(unmeasured[i]);
    }

    let totalArea = 0;
    let measuredArea = 0;
    let xArray = [];
    for (let paddock of dataset) {
      xArray.push(totalArea);
      totalArea += paddock.area;
      if (paddock.survey.survey_date) {
        measuredArea += paddock.area;
      }
    }
    if (measuredArea === 0) {
      measuredArea = 1;
    }
    xArray.push(totalArea);

    const DEMAND_COVER =
      (N_ANIMALS * TARGET_INTAKE * ROTATION_LENGTH / totalArea * 10000 +
        TARGET_EXIT_COVER) *
      block_ratio;

    const maxCover = max(dataset, function(d) {
      if (d.survey.survey_date) {
        return d.survey.cover_ha;
      } else {
        return 1000;
      }
    });

    const mxCover = dataset[0].survey.cover_ha;
    const mnCover = dataset[dataset.length - 1].survey.cover_ha;
    const delta = (mxCover - mnCover) / 2;
    let totalCover = 0;
    for (let i = 0; i < dataset.length; i++) {
      const d = dataset[i];
      if (!isNaN(d.survey.cover_pd)) {
        totalCover += d.survey.cover_pd;
      }
    }
    const averageCover = totalCover / measuredArea * 10000;

    const minY =
      COVER_TYPE === '2'
        ? 0
        : Formulae.availableCover(
            0,
            TARGET_RESIDUAL,
            padCovers.length > 0 ? padCovers[0].dmPercentage : 17
          );

    let maxLine = Math.max(DEMAND_COVER, averageCover + delta);

    const padScaleMagic = (x, px) => {
      const c1 = px / height;
      return (c1 * minY - x) / (c1 - 1);
    };

    maxLine = padScaleMagic(maxLine, height / 20);

    let maxY = Math.max(maxCover, maxLine);

    const xScale = scaleLinear()
      .range([0, width])
      .domain([0, totalArea]);

    const yScale = scaleLinear()
      .range([height, 0])
      .domain([minY, maxY]);

    svg
      .append('g')
      .attr('class', 'grid')
      .call(
        axisLeft(yScale)
          .tickSize(-width)
          .tickFormat('')
      );

    const minPaddockWidth = min(dataset, d => {
      return xScale(d.area);
    });
    const paddingWidth = Math.min(minPaddockWidth / 3, width / 80);
    if (cover) {
      svg
        .selectAll('proj')
        .data(dataset)
        .enter()
        .append('rect')
        .attr('x', (d, i) => {
          return xScale(xArray[i]) + paddingWidth / 2;
        })
        .attr('y', d => {
          return yScale(d.survey.cover_proj);
        })
        .attr('width', d => {
          return xScale(d.area) - paddingWidth;
        })
        .attr('height', d => {
          return Math.max(0, height - yScale(d.survey.cover_proj));
        })
        .attr('class', d => {
          if (d.survey.survey_date) {
            return 'WedgeProj';
          } else {
            return 'WedgeProj unmeasured';
          }
        });
    }

    svg
      .selectAll('current')
      .data(dataset)
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        return xScale(xArray[i]) + paddingWidth / 2;
      })
      .attr('y', d => {
        return yScale(d.survey.cover_ha);
      })
      .attr('width', d => {
        return xScale(d.area) - paddingWidth;
      })
      .attr('height', d => {
        return Math.max(0, height - yScale(d.survey.cover_ha));
      })
      .attr('class', d => {
        let str = 'WedgeBar';
        if (!d.survey.survey_date) {
          str += ' unmeasured';
        }
        if (pad && d.name === pad.name) {
          str += ' active';
        }
        return str;
      })
      .style('cursor', 'default');

    svg
      .append('polyline')
      .attr(
        'points',
        0 +
          ' ' +
          yScale(averageCover + delta) +
          ',' +
          0 +
          ' ' +
          yScale(DEMAND_COVER) +
          ',' +
          width +
          ' ' +
          yScale(TARGET_EXIT_COVER) +
          ',' +
          width +
          ' ' +
          yScale(averageCover - delta)
      )
      .attr('class', 'Difference')
      .style('pointer-events', 'none');
    // Average Cover
    svg
      .append('line')
      .attr('x1', 0)
      .attr('y1', yScale(averageCover + delta))
      .attr('x2', width)
      .attr('y2', yScale(averageCover - delta))
      .attr('stroke', '#51534a')
      .attr('stroke-width', '2px')
      .style('stroke-dasharray', '6, 6')
      .style('pointer-events', 'none');
    // Demand Line
    svg
      .append('line')
      .attr('x1', 0)
      .attr('y1', yScale(DEMAND_COVER))
      .attr('x2', width)
      .attr('y2', yScale(TARGET_EXIT_COVER))
      .attr('stroke', '#ffb000')
      .attr('stroke-width', '3px')
      .style('pointer-events', 'none');

    // Add heading
    svg
      .append('text')
      .text(this.props.blockName)
      .attr('x', width - 10)
      .attr('y', 20)
      .attr('class', 'ChartHeading')
      .attr('text-anchor', 'end')
      .style('pointer-events', 'none');
  }

  render() {
    this.createGrassWedge();
    return (
      <div
        id={'GrassWedgeSVG' + this.props.i}
        style={{
          width: '100%',
          height: '100%'
        }}
      />
    );
  }
}

class OverviewWedges extends Component {
  constructor(props) {
    super(props);
    this.trans = Translation.getInstance().translate;
  }
  render() {
    const blockSet = new Set();
    for (var p of this.props.paddocks) {
      if (p.block) {
        blockSet.add(p.block);
      }
    }
    const blockList = [...blockSet];
    blockList.sort((a, b) =>
      ('' + this.props.farm.blocks[a - 1]).localeCompare(
        this.props.farm.blocks[b - 1]
      )
    );

    const chart = document.getElementById('GrassWedge');
    if (!chart) {
      return;
    }
    const w = chart.clientWidth;
    const h = chart.clientHeight;

    const width = w - 25;
    const height = h - 45;
    const padding = 10;
    const n_blocks = blockList.length;
    const n_columns = Math.ceil(Math.sqrt(n_blocks));
    const n_rows = Math.ceil(n_blocks / n_columns);
    const chartWidth = (width - 2 * n_columns * padding) / n_columns;
    const chartHeight = (height - 2 * n_rows * padding) / n_rows;

    return (
      <div style={{ width: w, height: h }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            height: '50px'
          }}
        >
          <div className="ChartHeading" style={{ textAlign: 'center' }}>
            {this.trans('blocks_overview')}
          </div>
        </div>
        <div
          style={{
            width: w,
            height: h - 50,
            paddingLeft: '25px',
            boxSizing: 'border-box',
            display: 'flex',
            flexWrap: 'wrap'
          }}
        >
          {blockList.map((b, i) => (
            <div
              style={{ margin: '10px', width: chartWidth, height: chartHeight }}
            >
              <WedgeSVG
                i={i}
                blockName={this.props.farm.blocks[b - 1]}
                editingWedge={this.props.editingWedge}
                coverType={this.props.coverType}
                rotLength={this.props.rotLength}
                nAnimals={this.props.nAnimals}
                targetIntake={this.props.targetIntake}
                entryCover={this.props.entryCover}
                residual={this.props.residual}
                setEditingWedge={this.props.setEditingWedge}
                cover={this.props.cover}
                paddocks={this.props.paddocks}
                display={this.props.display}
                paddock={this.props.paddock}
                setPaddock={d => this.props.setPaddock(d, null, 'grass_wedge')}
                group={this.props.displayGroup}
                disp_date={this.props.disp_date}
                inc_days={this.props.inc_days}
                n_days={this.props.n_days}
                demand={this.props.demand}
                feed_cost={this.props.feed_cost}
                displayBlock={b}
              />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default OverviewWedges;
