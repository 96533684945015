import React, { Component } from "react";
import moment from "moment-with-locales-es6";
import axios from "axios";

import Flags from "./Flags";
import Icon from "./img/GrasslandToolsLogoNewGW.png";
import UserController from "./UserController";
import Translation from "./Translation";

import "./LandingPage.css";

class SignupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      domain: null,
      enabled: ["Grass", "Weighing", "Environment"],
      selected: null,
      action: "Login",
      username: "",
      password: "",
      cpassword: "",
      email: "",
      postcode: "",
      advisory: "",
      account_type: "",
      error: null,
      payment: false,
      sent: false,
      ac_type: 1,
      survey_methods: []
    };
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmChange = this.handleConfirmChange.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
    this.handleAdvisoryChange = this.handleAdvisoryChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.createAccount = this.createAccount.bind(this);
    this.setAppState = this.props.setAppState.bind(this);
    this.trans = Translation.getInstance().translate;
  }

  setAction(x) {
    this.setState({ action: x, error: "" });
  }

  handleUsernameChange(event) {
    this.setState({ username: event.target.value });
  }
  handlePasswordChange(event) {
    this.setState({ password: event.target.value });
  }
  handleConfirmChange(event) {
    this.setState({ cpassword: event.target.value });
  }
  handleEmailChange(event) {
    this.setState({ email: event.target.value });
  }
  handlePostcodeChange(event) {
    this.setState({ postcode: event.target.value });
  }
  handleAdvisoryChange(event) {
    this.setState({ advisory: event.target.value });
  }
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.createAccount(event);
    }
  };

  createAccount(event) {
    const self = this;
    if (!this.state.username) {
      self.setState({
        error: this.trans("input_username")
      });
      return;
    }
    if (!this.state.password) {
      self.setState({
        error: this.trans("input_password")
      });
      return;
    }
    if (!this.state.cpassword) {
      self.setState({
        error: this.trans("new_password_and_confirm_password_must_match")
      });
      return;
    }
    if (this.state.password !== this.state.cpassword) {
      self.setState({
        error: this.trans("new_password_and_confirm_password_must_match")
      });
      return;
    }
    if (!this.state.email) {
      self.setState({
        error: this.trans("input_a_valid_email_address")
      });
      return;
    }
    self.setState({ sent: true });
    const setAppState = this.setAppState;
    const username = this.state.username;
    const password = this.state.password;
    const email = this.state.email;
    const postcode = this.state.postcode;
    const lead = localStorage.getItem("lead_uuid")
    axios
      .post("https://staging.grasslandtools.ie/api/v2/createaccount/", {
        username: username,
        password: password,
        email: email,
        postcode: postcode,
        lead: lead,
      })
      .then(function(response) {
        if (response.data.sessionid) {
          UserController.setSessionkey(response.data.sessionid);
          localStorage.setItem("sessionid", response.data.sessionid);
          localStorage.removeItem("lead_uuid");
          self.props.getDataset();
          setAppState({ userLoggedIn: true });
        }
        if (response.data.error) {
          self.setState({ error: response.data.error, sent: false });
        }
      })
      .catch(function(error) {
        alert(error);
        self.setState({ sent: false });
      });
    event.preventDefault();
  }

  render() {
    const self = this;
    return (
      <div className="LandingPage">
        <div className="LandingHeader">
          <div className="IconDiv" onClick={this.props.goHome}>
            <img className="Icon" src={Icon} alt="Icon" />
            <div className="LandingPageFlagsContainer">
              <Flags incrementKey={this.props.incrementKey} />
            </div>
          </div>
        </div>
        <div
          className="LandingPageLoginContainer"
          style={{
            height: "unset",
            minHeight: "calc(100vh - 200px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <div className="LandingPageLoginBody" style={{ height: "unset" }}>
            <label>{this.trans("username")}</label>
            <input
              className="FormInput"
              type="text"
              required
              id="username"
              value={this.state.username}
              onChange={this.handleUsernameChange}
              onKeyPress={this.handleKeyPress}
              name="username"
              placeholder={this.trans("username")}
            />
            <label>{this.trans("password")}</label>
            <input
              className="FormInput"
              type="password"
              required
              value={this.state.password}
              onChange={this.handlePasswordChange}
              onKeyPress={this.handleKeyPress}
              id="password"
              name="password"
              placeholder={this.trans("password")}
            />
            <label>{this.trans("password_confirm")}</label>
            <input
              className="FormInput"
              type="password"
              required
              value={this.state.cpassword}
              onChange={this.handleConfirmChange}
              onKeyPress={this.handleKeyPress}
              id="cpassword"
              name="cpassword"
              placeholder={this.trans("password_confirm")}
            />
            <label>{this.trans("email")}</label>
            <input
              className="FormInput"
              type="email"
              required
              value={this.state.email}
              onChange={this.handleEmailChange}
              onKeyPress={this.handleKeyPress}
              id="email"
              name="email"
              placeholder={this.trans("email")}
            />
            <label>{this.trans("postcode_eircode")}</label>
            <input
              className="FormInput"
              type="text"
              value={this.state.postcode}
              onChange={this.handlePostcodeChange}
              onKeyPress={this.handleKeyPress}
              id="postcode"
              name="postcode"
              placeholder={this.trans("postcode_eircode")}
            />
            <div style={{height:50}}/>
            <label>{this.trans("advisory group")}</label>
            <select
              className="FormInput"
              type="text"
              value={this.state.advisory}
              onChange={this.handleAdvisoryChange}
              onKeyPress={this.handleKeyPress}
              id="advisory"
              name="advisory"
              // placeholder={this.trans("postcode_eircode")}
            >
            <option value="">{this.trans("advisory group")}</option>
              <option value="Pature-Vision">Pature-Vision</option>
            </select>
            <div className="Button" onClick={this.createAccount}>{this.trans("create_account")}</div>
          </div>
          {this.state.error ? <p className="Error">{this.state.error}</p> : null}
        </div>
        <div className="LandingPageFooter">
          <span>
            {this.trans("copyright") + " "} © 2017 - {moment().format("YYYY")}{" "}
            True North Technologies Ltd.
          </span>
        </div>
      </div>
    );
  }
}

export default SignupPage;
