import React, { Component } from 'react';
import north from './img/north_flag.png';
import ireland from './img/ireland_flag.png';
import france from './img/French_flag.png';
import brittany from './img/brittany_flag.png';
import scotland from './img/scottish_flag.png';
import switzerland from './img/swiss_flag.png';
import netherlands from './img/Netherlands_flag.png';
import italy from './img/Italy_flag.png';
import denmark from './img/danish_flag.png';
import germany from './img/german_flag.png';
import Translation from './Translation';

class Flags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      expanded: false
    };
    this.trans = Translation.getInstance();
  }

  setLang(lang) {
    this.trans.setLang(lang);
    this.props.incrementKey();
  }

  render() {
    const langCode = this.trans.langCode();
    const languages = [
      {
        name: "English",
        image: ireland,
        langCode: "en",
      },
      {
        name: "Francais",
        image: france,
        langCode: "fr",
      },
      {
        name: "Deutsch",
        image: germany,
        langCode: "de",
      },
    ]
    const activeLanguage = languages.find((l)=>langCode.startsWith(l.langCode));

    return (
      <div className="FlagDiv">
        {[activeLanguage].map((l, i)=>
          <img
            key={i}
            title={l.name}
            alt={l.name}
            className="Flag"
            src={l.image}
            onClick={this.setLang.bind(this, l.langCode)}
          />
        )}
        <div className="InactiveFlags">
          {languages.filter((l)=>!langCode.startsWith(l.langCode)).map((l, i)=>
            <img
            key={i}
            title={l.name}
              alt={l.name}
              className="Flag"
              src={l.image}
              onClick={this.setLang.bind(this, l.langCode)}
            />
          )}
        </div>
        {/* <img
          alt="English"
          className="Flag"
          src={ireland}
          onClick={this.setLang.bind(this, 'en')}
        />
        <img
          alt="Francais"
          className="Flag"
          src={france}
          onClick={this.setLang.bind(this, 'fr')}
        />
        <img
          alt="Deutsch"
          className="Flag"
          src={germany}
          onClick={this.setLang.bind(this, 'de')}
        /> */}
        {/* <img
          alt="Brittany"
          className="Flag"
          src={brittany}
          onClick={this.setLang.bind(this, 'fr')}
        /> */}
        {/* <img
          alt="Italy"
          className="Flag"
          src={italy}
          onClick={this.setLang.bind(this, 'it')}
        /> */}
        {/* <img
          alt="Switzerland"
          className="Flag"
          src={switzerland}
          onClick={this.setLang.bind(this, 'de-ch')}
        /> */}
        {/* <img
          alt="Netherlands"
          className="Flag"
          src={netherlands}
          onClick={this.setLang.bind(this, 'nl')}
        /> */}
        {/* <img
          alt="Scotland"
          className="Flag"
          src={scotland}
          onClick={this.setLang.bind(this, 'en')}
        /> */}
        {/* <img
          alt="Denmark"
          className="Flag"
          src={denmark}
          onClick={this.setLang.bind(this, 'da')}
        /> */}
      </div>
    );
  }
}

export default Flags;
