import React, { Component } from 'react';
import Translation from './Translation';
import UserController from './UserController';

import moment from 'moment-with-locales-es6';
import axios from 'axios';
import Drop from './img/Drop.svg';

class CoverSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      saving: false
    };
    this.trans = Translation.getInstance().translate;
  }


  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }


  save() {
    const self = this;
    this.setState({ saving: true });
    axios
      .post('https://staging.grasslandtools.ie/api/updatewedgesettings/', {
        grazingPaddocks: "",
        silagePaddocks: "",
        date: moment().format('YYYY-MM-DD'),
        sessionid: UserController.getSessionkey(),
        farmid: UserController.getFarmid(),
        coverType: self.props.coverType,
        rotLengthType: self.props.rotLengthType,
        rotLength: self.props.rotLength,
        nAnimals: self.props.nAnimals,
        targetIntake: self.props.targetIntake,
        entryCover: self.props.entryCover,
        residual: self.props.residual,
        demandGroups: self.props.demandGroups,
        demandType: self.props.demandType,
        overrideDm: self.props.overrideDm,
        dmPercentage: self.props.dmPercent
      })
      .then(function(response) {
        self.setState({ saving: false });
      })
      .catch(function(error) {
        self.setState({ saving: false });
      });
  }

  
  getLUFromType(type) {
    switch (parseInt(type, 10)) {
      case 1:
        return 1;
      case 2:
        return 1;
      case 3:
        return 1;
      case 4:
        return 1;
      case 5:
        return 0.1;
      case 6:
        return 0.3;
      case 7:
        return 0.7;
      case 8:
        return 1;
      case 9:
        return 0.25;
      case 10:
        return 0.15;
      case 11:
        return 0;
      default:
        return 0;
    }
  }

  
  render() {
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
      <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
        <h3 style={{margin: "0"}}>{this.trans('cover_settings')}</h3>
        <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
      </div>
      {this.state.collapsed ? null : <div className="MetricGroup" style={{padding: "0px 20px 10px"}}>
            <div className="Metric">
              <label>{this.trans('cover')}:</label>
              <select
                className="FormInput"
                value={this.props.coverType}
                onChange={this.props.handleCoverTypeChange.bind(this)}
              >
                <option value="1">{this.trans('available_cover')}</option>
                <option value="2">{this.trans('total_cover')}</option>
              </select>
            </div>
            <div className="Metric">
              <label>
                {this.trans('rotation_length')}
                ({this.trans('days')}):
              </label>
              <input
                className="FormInput"
                value={this.props.rotLength}
                onChange={this.props.handleRotLengthChange.bind(this)}
              />
            </div>
            <div className="Metric">
              <label>
                {this.trans('entry_cover')}
                (kg/ha):
              </label>
              <input
                className="FormInput"
                value={this.props.entryCover}
                onChange={this.props.handleEntryCoverChange.bind(this)}
              />
            </div>
            <div className="Metric">
              <label>
                {this.trans('residual')}
                (mm):
              </label>
              <input
                className="FormInput"
                value={this.props.residual}
                onChange={this.props.handleResidualChange.bind(this)}
              />
            </div>
            <div className="Metric">
              <label>{this.trans('override_dm')}:</label>
              <select
                className="FormInput"
                value={this.props.overrideDm}
                onChange={this.props.handleDmOverrideChange.bind(this)}
              >
                <option value={'false'}>{this.trans('use_existing_dm')}</option>
                <option value={'true'}>{this.trans('override')}</option>
              </select>
            </div>
            <div className="Metric">
              <label>
                {this.trans('dm_percent')}
                (%):
              </label>
              <input
                className="FormInput"
                value={this.props.dmPercent}
                onChange={this.props.handleDmPercentChange.bind(this)}
              />
            </div>
            <div className="Metric">
              <label>{this.trans('demand_calculation')}:</label>
              <select
                className="FormInput"
                value={this.props.demandType}
                onChange={this.props.handleDemandTypeChange.bind(this)}
              >
                <option value="simple">{this.trans('simple')}</option>
                <option value="advanced">{this.trans('advanced')}</option>
              </select>
            </div>
            {(this.props.demandType === 'simple') ?
              <div className="Metric">
                <label>{this.trans('number_of_animals')}:</label>
                <input
                  className="FormInput"
                  value={this.props.nAnimals}
                  onChange={this.props.handleNAnimalsChange.bind(this)}
                />
              </div> 
            : null}
            {(this.props.demandType === 'simple') ?
              <div className="Metric">
                <label>
                  {this.trans('target_intake')}
                  ({this.trans('kg_dm_per_animal_per_day')}):
                </label>
                <input
                  className="FormInput"
                  value={this.props.targetIntake}
                  onChange={this.props.handleTargetIntakeChange.bind(this)}
                />
              </div> 
            : null}
            <div className="Metric">
              <label style={{opacity: 0}}>
                {this.trans('save')}
              </label>
              <div className="Button Action" onClick={this.save.bind(this)}>
                {this.trans('save')}
              </div>
            </div>
        </div>}
        {this.state.collapsed ? null : <div style={{padding: "0 20px"}}>
            {(this.props.demandType === 'advanced') ? 
            <table style={{width: "100%", padding: "10px"}}>
              <thead>
                <tr>
                  <th>{this.trans('stock_type')}</th><th>{this.trans('animal_count')}</th><th>{this.trans('grass_intake')}</th><th>{this.trans('meal_intake')}</th><th>{this.trans('silage_intake')}</th><th>{this.trans('total_intake')}</th>
                </tr>
              </thead>
              <tbody>
                {this.props.demandGroups.map((g, i)=><tr key={i}>
                  <td>
                    <select value={g.type} onChange={this.props.handleDemandGroupsDetailChange.bind(this, i, "type")} className="FormInput">
                      <option value="1">{this.trans('dairy_milking_cows___')}</option>
                      <option value="2">{this.trans('suckler_cows___')}</option>
                      <option value="3">{this.trans('stock_bulls___')}</option>
                      <option value="4">{this.trans('dry_cows___')}</option>
                      <option value="5">{this.trans('zero_to_six_month_old___')}</option>
                      <option value="6">{this.trans('six_to_twelve_month_old___')}</option>
                      <option value="7">{this.trans('one_to_two_year_old___')}</option>
                      <option value="8">&gt;{this.trans('two_year_old___')}</option>
                      <option value="9">{this.trans('lactating_ewes___')}</option>
                      <option value="10">{this.trans('dry_ewe_hoggets___')}</option>
                      <option value="11">{this.trans('lambs___')}</option>
                      <option value="12">{this.trans('Remove Group')}</option>
                      
                    </select>
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={g.animalCount}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, i, "animalCount")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={g.grassIntake}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, i, "grassIntake")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={g.mealIntake}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, i, "mealIntake")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={g.silageIntake}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, i, "silageIntake")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={g.grassIntake+g.mealIntake+g.silageIntake}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, i, 5)}
                    />
                  </td>
                </tr>)}

                <tr>
                  <td>
                    <select value={0} onChange={this.props.handleDemandGroupsDetailChange.bind(this, this.props.demandGroups.length, "type")} className="FormInput">
                      <option value="0">{this.trans('add_group')}</option>
                      <option value="1">{this.trans('dairy_milking_cows___')}</option>
                      <option value="2">{this.trans('suckler_cows___')}</option>
                      <option value="3">{this.trans('stock_bulls___')}</option>
                      <option value="4">{this.trans('dry_cows___')}</option>
                      <option value="5">{this.trans('zero_to_six_month_old___')}</option>
                      <option value="6">{this.trans('six_to_twelve_month_old___')}</option>
                      <option value="7">{this.trans('one_to_two_year_old___')}</option>
                      <option value="8">&gt;{this.trans('two_year_old___')}</option>
                      <option value="9">{this.trans('lactating_ewes___')}</option>
                      <option value="10">{this.trans('dry_ewe_hoggets___')}</option>
                      <option value="11">{this.trans('lambs___')}</option>
                      
                    </select>
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={0}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, this.props.demandGroups.length, "animalCount")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={0}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, this.props.demandGroups.length, "grassIntake")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={0}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, this.props.demandGroups.length, "mealIntake")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={0}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, this.props.demandGroups.length, "silageIntake")}
                    />
                  </td>
                  <td>
                    <input
                      className="FormInput"
                      value={0}
                      onChange={this.props.handleDemandGroupsDetailChange.bind(this, this.props.demandGroups.length, 5)}
                    />
                  </td>
                </tr>

                <tr>
                  <th>{this.trans('total_')}</th>
                  <th>
                    {this.props.demandGroups.reduce((a,b)=>a+b.animalCount,0)} ({this.props.demandGroups.reduce((a,b)=>a+b.animalCount*this.getLUFromType(b.type),0)} LU)
                  </th>
                  <th>
                    {this.props.demandGroups.reduce((a,b)=>a+b.animalCount*b.grassIntake,0) + " kg"}
                  </th>
                  <th>
                    {this.props.demandGroups.reduce((a,b)=>a+b.animalCount*b.mealIntake,0) + " kg"}
                  </th>
                  <th>
                    {this.props.demandGroups.reduce((a,b)=>a+b.animalCount*(b.silageIntake),0) + " kg"}
                  </th>
                  <th>
                    {this.props.demandGroups.reduce((a,b)=>a+b.animalCount*(b.grassIntake+b.mealIntake+b.silageIntake),0) + " kg"}
                  </th>
                </tr>
              </tbody>
            </table> : null
              
            }
          </div>}
      </div>
    );
  }
}

export default CoverSettings;
