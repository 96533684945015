import React, { Component } from 'react';

import FarmBrowser from './FarmBrowser';
import AddFarm from './AddFarm';
import PickFarm from './PickFarm';
import AccountSettings from './AccountSettings';
import UserController from './UserController';
import Translation from './Translation';

import GH from './img/GrasshopperTM.png';
import WR from './img/WeighRite_Logo_Dark.png';
import Field from './img/Field.png';
import WeightDashboard from './WeightDashboard';
import HerdSetup from './HerdSetup';



class OverviewController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      domain: null,
    };
    this.trans = Translation.getInstance().translate;
  }
  toggleCreating() {
    this.setState({
      creating: !this.state.creating
    });
  }

  componentWillUpdate() {
    if (this.state.creating && this.props.display !== 'add_farm') {
      this.toggleCreating();
    }
  }

  componentWillMount() {
    this.props.setDisplay('farm_selector');
  }
  render() {
    // return (
    //   <div
    //     style={{
    //       width: '100%',
    //       height: 'calc(100vh - 100px)',
    //       display: 'flex',
    //       justifyContent: 'center',
    //       flexDirection: 'row',
    //       alignItems: 'center',
    //       // cursor: 'pointer'
    //     }}>
    //     <div className="PackageIcon GrassPackage" >
    //       <div><img src={Field} alt="Logo" /></div>
    //       <h1>Grass</h1>
    //     </div>
    //     <div className="PackageIcon SoilPackage" title="Coming Soon">
    //       <div><img src={Field} alt="Logo" /></div>
          
    //       <h1>Soil</h1>
    //     </div>
    //     <div className="PackageIcon WeightPackage" title="Coming Soon">
    //       <div><img src={Field} alt="Logo" /></div>
    //       <h1>Weight</h1>
    //     </div>
    //   </div>
    // )
    if (this.props.display === 'farm_selector') {
      if (this.props.farms.length+this.props.herds.length > 0) {
        return (
          <FarmBrowser
            setFarms={this.props.setFarms}
            farms={this.props.farms}
            herds={this.props.herds}
            getDataset={this.props.getDataset}
            mobile={this.props.mobile}
          />
        );
      } else if (UserController.getUserType() === 'Farmer') {
        return (
          <div
            // onClick={this.props.setDisplay.bind(this, 'add_farm')}
            style={{
              width: '100%',
              height: 'calc(100vh - 100px)',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'row',
              alignItems: 'center',
              // cursor: 'pointer'
            }}
          >
            <div className="FarmIcon" 
              onClick={this.props.setDisplay.bind(this, 'add_farm')}
              style={{
                width: "min(calc(50vh - 50px), calc(30vw - 7px))", 
                height: "min(calc(50vh - 50px), calc(30vw - 7px))", 
                borderRadius: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 10)", 
                margin: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 20)", 
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: 'space-evenly',
                alignItems: 'center',
                // padding: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 5) 0"

              }}>
              <img style={{width: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) *.9)", height: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) *.4)", objectFit: "contain"}} src={GH} alt="Logo" />
                <h1 style={{fontSize: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 12)"}}>{this.trans('add_farm')}</h1>
            </div>
            <div className="FarmIcon" 
              onClick={this.props.setDisplay.bind(this, 'create_herd')}
              style={{
                width: "min(calc(50vh - 50px), calc(30vw - 7px))", 
                height: "min(calc(50vh - 50px), calc(30vw - 7px))", 
                borderRadius: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 10)", 
                margin: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 20)", 
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: 'center',
                // padding: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 5) 0"
              }}>
              <img style={{width: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) *.8)", height: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) *.4)", objectFit: "contain"}} src={WR} alt="Logo" />
              <h1 style={{fontSize: "calc(min(calc(50vh - 50px), calc(30vw - 7px)) / 12)"}}>{this.trans('create_herd')}</h1>
            </div>
            {/* <h3
              style={{
                pointerEvents: 'none'
              }}
            >
              {this.trans('no_farms_found_add')}
            </h3> */}
          </div>
        );
      } else {
        return (
          <div
            style={{
              width: '100%',
              height: 'calc(100vh - 100px)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <h3
              style={{
                pointerEvents: 'none'
              }}
            >
              {this.trans('no_farms_found')}
            </h3>
          </div>
        );
      }
    }
    if (this.props.display === 'find_farm') {
      if (this.props.user_type === 'Admin') {
        return (
          <PickFarm
            getDataset={this.props.getDataset}
            setAppState={this.props.setAppState}
          />
        );
      }
    }
    if (this.props.display === 'add_farm') {
      return (
        <AddFarm
          getDataset={this.props.getDataset}
          setAppState={this.props.setAppState}
        />
      );
    }
    if (this.props.display === 'create_herd') {
          return (
            <HerdSetup
              setAppState={this.props.setAppState}
              herdCreated={function(){}}
              appkey={this.props.appkey}
            />
          );
    }
    if (this.props.display === 'account_settings') {
      return (
        <AccountSettings
          user_data={this.props.user_data}
          getDataset={this.props.getDataset}
          setAppState={this.props.setAppState}
        />
      );
    }
    return null;
  }
}

export default OverviewController;
