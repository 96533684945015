import React, { Component } from 'react';
import { max } from 'd3-array';
import { axisRight } from 'd3-axis';
import { scaleLinear } from 'd3-scale';
import { select } from 'd3-selection';

import moment from 'moment-with-locales-es6';
import './Weather.css';
import Translation from './Translation';

moment.locale(Translation.getInstance().langCode());

class WeatherChart extends Component {
  constructor(props) {
    super(props);
    this.points = null;
    this.state = {
      mounted: false
    };
    this.width = 0;
    this.trans = Translation.getInstance().translate;
  }
  componentDidMount() {
    this.setState({ mounted: true });
    window.addEventListener('resize', this.resize.bind(this));
  }

  componentWillUnmount() {
    this.setState({ mounted: false });
  }

  resize() {
    this.createChart();
  }

  createChart() {
    const row = document.getElementById('WeatherChartRow');
    if (!row) {
      return;
    }
    const w = row.clientWidth / 3;
    const h = row.clientHeight;
    const id = this.props.id;

    const fontSize = 12;
    const margin = {
      top: 20,
      bottom: fontSize * 3,
      left: w / 6,
      right: w / 6
    };
    const width = w - margin.left - margin.right;
    const height = h - margin.top - margin.bottom;

    document.getElementById(this.props.id).innerHTML = '';

    //Create SVG element and append map to the SVG
    const svg = select('#' + this.props.id)
      .attr('width', w)
      .attr('height', h)
      .attr('class', 'ChartSVG');

    const weatherArray = this.props.weather;
    const station = this.props.selectedStation;

    const dataset = [];
    for (let weather of weatherArray) {
      let reports = weather.reports.filter(r => {
        if (id === 'soil_temp_chart') {
          return r.soil_temperature;
        }
        if (id === 'rainfall_chart') {
          return r.rainfall;
        }
        if (id === 'sunlight_chart') {
          return r.hrs_sunlight;
        }
        return false;
      });

      if (station) {
        reports = reports.filter(r => {
          return r.station.name === station.name;
        });
      }
      let d = 0;
      if (reports.length > 0) {
        if (id === 'soil_temp_chart') {
          for (let r of reports) {
            d += parseFloat(r.soil_temperature);
          }
          d /= reports.length;
        }

        if (id === 'rainfall_chart') {
          for (let r of reports) {
            d += parseFloat(r.rainfall);
          }
          d /= reports.length;
        }

        if (id === 'sunlight_chart') {
          for (let r of reports) {
            d += parseFloat(r.hrs_sunlight);
          }
          d /= reports.length;
        }
      }
      dataset.push(d);
    }

    dataset.reverse();

    const yScale = scaleLinear()
      .domain([0, max(dataset, d => d)])
      .range([height, 0]);

    const bars = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const barWidth = width / 8;

    bars
      .selectAll('bars')
      .data(dataset)
      .enter()
      .append('rect')
      .attr('x', (d, i) => {
        return i * barWidth + (i + 1 / 2) * barWidth / 7;
      })
      .attr('y', d => {
        return yScale(d);
      })
      .attr('width', barWidth)
      .attr('height', d => {
        return height - yScale(d);
      })
      .style('fill', () => {
        if (id === 'soil_temp_chart') {
          return '#ccaa44';
        }
        if (id === 'rainfall_chart') {
          return '#44aacc';
        }
        if (id === 'sunlight_chart') {
          return '#44ccaa';
        }
      });
    const font_size = barWidth / 2.5;
    bars
      .selectAll('labels')
      .data(dataset)
      .enter()
      .append('text')
      .style('font-size', font_size + 'px')
      .attr('transform', 'rotate(-90)')
      .attr('x', -height + 4)
      .attr('y', function(d, i) {
        return (
          (i + 1 / 2) * barWidth + (i + 1 / 2) * barWidth / 7 + font_size * 0.34
        );
      })
      .text(function(d, i) {
        return moment(weatherArray[6 - i].date).format("Do MMM")
        const offset = 7 - i;

        return moment()
          .subtract(offset, 'days')
          .format('ddd');
      })
      .attr('class', function(d) {
        return 'WedgeLabel';
      });

    svg
      .append('g')
      .attr('class', 'axis')
      .style('font-size', 12 + 'px')
      .attr(
        'transform',
        'translate(' + (margin.left + width) + ',' + margin.top + ')'
      )
      .call(axisRight(yScale).ticks(4));
    svg
      .append('line')
      .attr(
        'transform',
        'translate(' + margin.left + ',' + (margin.top + 1 / 2) + ')'
      )
      .attr('x1', 0)
      .attr('x2', width)
      .attr('y1', height)
      .attr('y2', height);

    svg
      .append('text')
      .attr(
        'transform',
        'translate(' + (margin.left + width) + ',' + (margin.top + height) + ')'
      )
      .style('font-size', fontSize + 'px')
      .attr('x', 0)
      .attr('y', fontSize)
      .text(this.trans('last_7_days_weather'))
      .attr('text-anchor', 'end')
      .attr('class', 'LegendTitle');

    svg
      .append('text')
      .attr(
        'transform',
        'translate(' +
          (margin.left + width) +
          ',' +
          margin.top +
          ') rotate(-90)'
      )
      .style('font-size', fontSize + 'px')
      .attr('x', 0)
      .attr('y', 3 * fontSize)
      .text(() => {
        if (id === 'soil_temp_chart') {
          return this.trans('soil_temperature_degrees_c_weather');
        }
        if (id === 'rainfall_chart') {
          return this.trans('rainfall_mm_weather');
        }
        if (id === 'sunlight_chart') {
          return this.trans('sunlight_hours_weather');
        }
      })
      .attr('text-anchor', 'end')
      .attr('class', 'LegendTitle');
  }

  render() {
    if (this.state.mounted) {
      this.createChart();
    }
    return <svg id={this.props.id} />;
  }
}

export default WeatherChart;
