import React, { Component } from 'react';
import './Login.css';
import Spinner from './img/Spinner.png';
// import logo from './img/GrasslandToolsTNBlue.png';
import logo from './img/GrasslandToolsLogoNewGG.png';
// import logo from './img/IDELE INSIDE.png';
import Flags from './Flags';
import Translation from './Translation';
import './Header.css';
import moment from 'moment-with-locales-es6';

class SplashPage extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          width: '100%',
          height: '100%'
        }}
      >
        <img src={logo} alt="GrasslandTools" className="LoginLogoLarge" />
        <img className="LargeSpinner" src={Spinner} alt="Loading" />
        <div className="Message">{this.props.message}</div>
        <div className="Footer">
          <span
            style={{
              paddingLeft: '10px'
            }}
          >
            {trans('copyright') +
              ' ©  2017 - ' +
              moment().format('YYYY') +
              ' True North Technologies Ltd.'}
          </span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              float: 'right'
            }}
          >
            
        <Flags incrementKey={this.props.incrementKey} />{' '}
            {this.props.userLoggedIn ? (
              <div
                style={{
                  cursor: 'pointer'
                }}
                className="Logout"
                onClick={this.props.logout.bind(this)}
              >
                {trans('logout')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default SplashPage;
