import React, { Component } from "react";
import { injectStripe, CardElement } from "react-stripe-elements";
import Translation from "./Translation";
import stripeLogo from "./img/Stripe Badges/Solid Light/powered_by_stripe.png";

import "./Stripe.css";

class CardForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: ""
    };
    console.log("CARDINIT")
  }

  async handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    if (this.props.stripe_token) {
      return this.props.onSubmit();
    }
    this.setState({ loading: true });
    try {
      var response = await this.props.stripe.createToken();
      console.log(response);
      if (response.error) {
        this.setState({ error: response.error.message, loading: false });
      } else if (response.token) {
        this.setState({ error: "", loading: false });
        this.props.setStripeToken(response.token.id);
      } else {
        this.setState({ error: this.trans("please_try_again"), loading: false });
      }
    } catch (error) {
      console.log(error);
      this.setState({ error: this.trans("please_try_again"), loading: false });
    }
  }

  logoClick() {
    window.open("https://www.stripe.com");
  }

  render() {
    const trans = Translation.getInstance().translate;
    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <label>{trans("card_details")}</label>
        <CardElement
          style={{
            base: {
              fontSize: "16px"
            }
          }}
        />
        <img
          src={stripeLogo}
          onClick={this.logoClick.bind(this)}
          alt="Stripe"
        />
        {this.state.loading ? (
          <h1 style={{ color: "white" }}>{trans("loading___")}</h1>
        ) : this.props.stripe_token ? null : (
          <div
            className="Button"
            onClick={this.handleSubmit.bind(this)}
            style={{ width: "unset" }}
          >
            {trans("confirm_card_details")}
          </div>
        )}
        {this.state.error ? (
          <p style={{ gridRow: "unset" }} className="Error">
            {this.state.error}
          </p>
        ) : null}
      </form>
    );
  }
}

export default injectStripe(CardForm);
