const Formulae = (function() {
  const availableCover = (height, res, dm) => {
    if (!dm) {
      dm = 17;
    }
    // console.log(height, res, dm)
    let A = res / 133.3794;
    let B = height / 133.3794;
    // console.log(A,B)
    A = Math.pow(A, 2.435674);
    B = Math.pow(B, 2.435674);
    // console.log(A,B)
    A = 1 + A;
    B = 1 + B;
    // console.log(A,B)
    A = 4914.218 / A;
    B = 4914.218 / B;
    // console.log(A,B)

    const ans = (A - B) * dm / 16;
    // console.log(ans)
    return ans;
  };
  const totalCover = (height, res, dm) => {
    if (!dm) {
      dm = 17;
    }
    // console.log(height, res, dm)
    let A = 40 / 133.3794;
    let B = height / 133.3794;
    // console.log(A,B)
    A = Math.pow(A, 2.435674);
    B = Math.pow(B, 2.435674);
    // console.log(A,B)
    A = 1 + A;
    B = 1 + B;
    // console.log(A,B)
    A = 4914.218 / A;
    B = 4914.218 / B;
    // console.log(A,B)

    const ans = (A - B) * dm / 16 + 1500;
    // console.log(ans)
    return ans;
  };
  return { 
    availableCover: availableCover,
    totalCover: totalCover 
  };
})();

export default Formulae;
