import React, { Component } from 'react';

import axios from 'axios';

import './App.css';

import UserController from './UserController';
import Body from './Body';
import Translation from './Translation';

class ToolsetController extends Component {
  constructor() {
    super();
    this.state = {
      display: 'dashboard',
      dataset: {},
      soil_data: {},
      soil_data_loaded: false,
      fm_paddock: null,
      gw_paddock: null,
      ph_paddock: null,
      survey: null,
      cultivars: [],
      fertilisers: [],
      soil_key: 0,
      map_key: 0,
    };
    this.trans = Translation.getInstance().translate;
  }
  setPaddock(d, s, src) {
    var self = this;
    if (src === 'farm_map') {
      if (d) {
        self.setState({
          fm_paddock: d,
          gw_paddock: d,
          ph_paddock: d,
          survey: null
        });
      } else {
        self.setState({ fm_paddock: null, gw_paddock: null, survey: null });
      }
    } else if (src === 'grass_wedge') {
      if (d) {
        self.setState({
          fm_paddock: d,
          gw_paddock: d,
          ph_paddock: d,
          survey: null
        });
      } else {
        self.setState({ fm_paddock: null, gw_paddock: null, survey: null });
      }
    } else if (src === 'paddock_history') {
      if (s) {
        axios
          .get('https://staging.grasslandtools.ie/api/surveypoints/', {
            params: {
              sessionid: UserController.getSessionkey(),
              survey_id: s.survey_id
            }
          })
          .then(function(response) {
            self.setState({
              survey: {
                coords: response.data,
                data: s
              }
            });
          })
          .catch(function(error) {});
      } else {
        self.setState({ survey: null });
      }
    }
  }
  updateDatasetStatus(newdata) {
    let paddocks = this.props.farm.paddocks.slice();
    const pad_ids = newdata.padstr.split(',').map(function(p) {
      return parseInt(p, 10);
    });
    const newStatus = parseInt(newdata.status, 10);

    var statusName = '';

    switch (newStatus) {
      case 1:
        statusName = this.trans('in_grazing_platform');
        break;
      case 2:
        statusName = this.trans('mob_1_status');
        break;
      case 3:
        statusName = this.trans('mob_2_status');
        break;
      case 4:
        statusName = this.trans('mob_3_status');
        break;
      case 5:
        statusName = this.trans('off_grazing_platform');
        break;
      case 6:
        statusName = this.trans('silage_cut_later');
        break;
      case 7:
        statusName = this.trans('silage_cut_now');
        break;
      case 8:
        statusName = this.trans('closed');
        break;

      default:
        statusName = '';
        break;
    }

    pad_ids.forEach(function(id) {
      paddocks.forEach(function(p) {
        if (p.id === id) {
          p.status = newStatus;
          p.status_name = statusName;
        }
      });
    }, this);
    let newDataset = this.props.farm;

    newDataset.paddocks = paddocks;
    this.setState({ dataset: newDataset, map_key: this.state.map_key+1 });
  }
  updateDatasetBlocks(newdata) {
    let paddocks = this.props.farm.paddocks.slice();
    let blocks = this.props.farm.blocks.slice();
    const pad_ids = newdata.padstr.split(',').map(function(p) {
      return parseInt(p, 10);
    });
    const newBlock = newdata.block;

    if (blocks.indexOf(newBlock) < 0) {
      blocks.push(newBlock);
    }
    pad_ids.forEach(function(id) {
      paddocks.forEach(function(p) {
        if (p.id === id) {
          if (p.block == blocks.indexOf(newBlock) + 1) {
            p.block = null;
          } else {
            p.block = blocks.indexOf(newBlock) + 1;
          }
        }
      });
    }, this);
    
    let newDataset = Object.assign(this.props.farm);

    newDataset.paddocks = paddocks;
    newDataset.blocks = blocks;
    this.setState({ dataset: newDataset, map_key: this.state.map_key+1 });
  }
  async updateDatasetBlockRemoved(blockName) {
    const self = this;
    await axios.post(
      'https://staging.grasslandtools.ie/api/deleteblock/',
      {
        sessionid: localStorage.getItem('sessionid'),
        farm_id: UserController.getFarmid(),
        block: blockName
      }
    );
    let paddocks = self.props.farm.paddocks.slice();
    let blocks = self.props.farm.blocks.slice();
    paddocks.forEach(function(p) {
      if (p.block === blocks.indexOf(blockName) + 1) {
        p.block = null;
      }
    });
    let newDataset = self.props.farm;

    newDataset.paddocks = paddocks;
    newDataset.blocks = blocks;
    self.setState({ dataset: newDataset });
  }
  loadSoilData() {
    if (this.state.soil_data_loaded === true) {
      this.setState({ soil_data_loaded: false });
    }
    const self = this;
    axios
      .get('https://staging.grasslandtools.ie/api/soil/', {
        params: {
          sessionid: UserController.getSessionkey(),
          farmid: UserController.getFarmid()
        }
      })
      .then(function(response) {

        self.setState({
          soil_key: self.state.soil_key + 1,
          soil_data: response.data,
          soil_data_loaded: true
        });
      })
      .catch(function(error) {});
  }
  componentWillMount() {
    this.props.setDisplay('grass_dashboard');
    // }

    var self = this;
    this.setState({ ph_paddock: this.props.farm.paddocks[0] });
    axios
      .get('https://staging.grasslandtools.ie/api/fertilisers/', {
        params: {
          sessionid: UserController.getSessionkey()
        }
      })
      .then(function(response) {
        self.setState({
          fertilisers: response.data.fertilisers.sort(function(a, b) {
            if (parseFloat(a.name) && parseFloat(b.name)) {
              return parseFloat(a.name) - parseFloat(b.name);
            }
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        });
      })
      .catch(function(error) {});
    axios
      .get('https://staging.grasslandtools.ie/api/cultivars/', {
        params: {
          sessionid: UserController.getSessionkey()
        }
      })
      .then(function(response) {
        self.setState({
          cultivars: response.data.cultivars.sort(function(a, b) {
            if (parseFloat(a.name) && parseFloat(b.name)) {
              return parseFloat(a.name) - parseFloat(b.name);
            }
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
        });
      })
      .catch(function(error) {});
  }
  render() {
    return (
      <Body
        soil_key={this.state.soil_key}
        map_key={this.state.map_key}
        menu_shown={this.props.menu_shown}
        toggleMenu={this.props.toggleMenu}
        display={this.props.display}
        setPaddock={(d, s, src) => this.setPaddock(d, s, src)}
        setAppState={this.props.setAppState}
        fm_paddock={this.state.fm_paddock}
        gw_paddock={this.state.gw_paddock}
        ph_paddock={this.state.ph_paddock}
        survey={this.state.survey}
        farm={this.props.farm}
        fertilisers={this.state.fertilisers}
        cultivars={this.state.cultivars}
        updateDatasetStatus={this.updateDatasetStatus.bind(this)}
        updateDatasetBlocks={this.updateDatasetBlocks.bind(this)}
        updateDatasetBlockRemoved={this.updateDatasetBlockRemoved.bind(this)}
        soil_data={this.state.soil_data}
        soil_data_loaded={this.state.soil_data_loaded}
        loadSoilData={this.loadSoilData.bind(this)}
        getDataset={this.props.getDataset}
        appkey={this.props.appkey}
        mobile={this.props.mobile}
      />
    );
  }
}

export default ToolsetController;
