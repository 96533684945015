import React, { Component } from 'react';
import Translation from './Translation';

import axios from 'axios';

import Loading from './Loading';
import UserController from './UserController';
import './FarmBrowser.css';

class PickFarm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      farm_id1: '',
      farm_id2: '',
      sent: false,
      error: null
    };
    this.trans = Translation.getInstance().translate;
  }

  handleID1change(event) {
    const id = event.target.value;
    if (id.length === 5) {
      document.getElementById('FarmID2').focus();
    }
    this.setState({ farm_id1: id });
  }

  handleID2change(event) {
    const id = event.target.value;
    this.setState({ farm_id2: id });
    if (id.length === 5) {
      document.getElementById('go').focus();
    }
  }

  keysubmit(e) {
    if (e.key === 'Enter') {
      this.submit();
    }
  }

  submit() {
    const self = this;
    if (this.state.farm_id1.length < 5 || this.state.farm_id2.length < 5) {
      this.setState({ error: 'Invalid Farm ID' });
      return;
    } else {
      this.setState({ error: null, sent: true });
    }
    axios
      .post('https://staging.grasslandtools.ie/api/addfarm/', {
        sessionid: UserController.getSessionkey(),
        farmid: self.state.farm_id1 + '-' + self.state.farm_id2
      })
      .then(function(response) {
        if (response.data.error) {
          self.setState({ sent: false, error: response.data.error });
        } else {
          self.props.getDataset(response.data.farm_id, 'Toolset', null);
          self.setState({ sent: false });
        }
      })
      .catch(function(error) {
        self.setState({ sent: false });
      });
  }

  render() {
    let body;
    if (this.state.sent) {
      body = (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Loading text={'finding_farm'} />
        </div>
      );
    } else {
      body = (
        <div
          style={{
            width: '100%'
          }}
        >
          <h3
            style={{
              marginBottom: '10px'
            }}
          >
            {this.trans('input_farm_id')}:
          </h3>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <input
              id="FarmID1"
              className="FormInput"
              style={{
                marginBottom: '0',
                marginRight: '10px'
              }}
              type="text"
              maxLength="5"
              size="8"
              value={this.state.farm_id1}
              onChange={this.handleID1change.bind(this)}
            />
            -
            <input
              id="FarmID2"
              className="FormInput"
              style={{
                marginBottom: '0',
                marginLeft: '10px'
              }}
              type="text"
              maxLength="5"
              size="8"
              value={this.state.farm_id2}
              onChange={this.handleID2change.bind(this)}
            />
          </div>
          <p>{this.trans('farm_id_found_app')}</p>
          <p
            style={{
              color: 'red'
            }}
          >
            {this.state.error}
          </p>
          <div
            id="go"
            className="Button"
            tabIndex="0"
            onKeyPress={this.keysubmit.bind(this)}
            onClick={this.submit.bind(this)}
          >
            {this.trans('find_farm')}
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          width: '100%',
          height: 'calc(100vh - 100px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >
        <div className="LoginContainer">
          <div
            className="Login"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            {body}
          </div>
        </div>
      </div>
    );
  }
}

export default PickFarm;
