import React, { Component } from "react";

import FarmBrowser from "./FarmBrowser";
import AddFarm from "./AddFarm";
import PickFarm from "./PickFarm";
import AccountSettings from "./AccountSettings";
import UserController from "./UserController";
import Translation from "./Translation";

import GH from "./img/GrasshopperTM.png";
import WR from "./img/WeighRite_Logo_Dark.png";
import FieldGrey from "./img/FieldGrey.png";
import HerdSetup from "./HerdSetup";
import Drawing from "./img/Landing_Page_Line_Dwg_2.png";

import "./HomePage.css";
import HalfHeightFarmBrowser from "./HalfHeightFarmBrowser";
import HalfHeightHerdBrowser from "./HalfHeightHerdBrowser";

class HomePageController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creating: false,
      domain: null,
      enabled: this.props.enabledPackages,
      selected: null,
      maxFarms: this.props.maxFarms,
      maxHerds: this.props.maxHerds
    };
    this.trans = Translation.getInstance().translate;
    this.props.setDisplay("home");
  }
  toggleCreating() {
    this.setState({
      creating: !this.state.creating
    });
  }

  componentWillUpdate() {
    if (this.state.creating && this.props.display !== "add_farm") {
      this.toggleCreating();
    }
  }

  select(x) {
    this.setState({ selected: this.state.selected == x ? null : x });
  }

  render() {
    const self = this;
    if (this.props.display === "home") {
      return (
        <div className="HomePageWrapper">
          <div className="HomePageContainer">
            <div className="HomePageController">
              <div className="DrawingContainer">
                <div className="IconsContainer">
                  {["Grass", "Weighing", "Environment"].map((x, i) => (
                    <div
                      key={i}
                      className={
                        "Icon " +
                        x +
                        (self.state.selected == x ? " Selected" : "") +
                        (self.state.enabled.includes(x) ? " Enabled" : "")
                      }
                      style={{
                        opacity: [x, null].includes(self.state.selected)
                          ? 1
                          : 0.3
                      }}
                      onClick={
                        // self.state.enabled.includes(x)
                          self.select.bind(self, x)
                          // : self.select.bind(self, null)
                      }
                    >
                      {i % 2 == 1 ? <div /> : null}
                      <h1>{x}</h1>
                    </div>
                  ))}
                </div>
                <img className="Drawing" src={Drawing} alt="Logo" />
              </div>
              {this.state.selected == "Grass" ? (
                this.state.enabled.includes("Grass") ? (
                  <HalfHeightFarmBrowser
                    setFarms={this.props.setFarms}
                    farms={this.props.farms}
                    herds={this.props.herds}
                    getDataset={this.props.getDataset}
                    setDisplay={this.props.setDisplay}
                    mobile={this.props.mobile}
                    maxFarms={this.state.maxFarms}
                  />
                ) : (
                  <div className="DescriptionContainer" />
                )
              ) : this.state.selected == "Weighing" ? (
                this.state.enabled.includes("Weighing") ? (
                  <HalfHeightHerdBrowser
                    setFarms={this.props.setFarms}
                    farms={this.props.farms}
                    herds={this.props.herds}
                    getDataset={this.props.getDataset}
                    setDisplay={this.props.setDisplay}
                    mobile={this.props.mobile}
                    maxHerds={this.state.maxHerds}
                  />
                ) : (
                  <div className="DescriptionContainer" />
                )
              ) : (
                <div className="DescriptionContainer">
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    if (this.props.display === "find_farm") {
      if (this.props.user_type === "Admin") {
        return (
          <PickFarm
            getDataset={this.props.getDataset}
            setAppState={this.props.setAppState}
          />
        );
      }
    }
    if (this.props.display === "add_farm") {
      return (
        <AddFarm
          getDataset={this.props.getDataset}
          setAppState={this.props.setAppState}
        />
      );
    }
    if (this.props.display === "create_herd") {
      return (
        <HerdSetup
          setAppState={this.props.setAppState}
          herdCreated={function() {}}
          appkey={this.props.appkey}
        />
      );
    }
    if (this.props.display === "account_settings") {
      return (
        <AccountSettings
          user_data={this.props.user_data}
          getDataset={this.props.getDataset}
          setAppState={this.props.setAppState}
        />
      );
    }
    return null;
  }
}

export default HomePageController;
