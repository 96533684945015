import React, { Component } from 'react';
import axios from 'axios';
import moment from 'moment-with-locales-es6';

import Translation from './Translation';
import UserController from './UserController';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


import Spinner from './img/Spinner.png';

import Drop from './img/Drop.svg';
import './Dashboard.css';
class PaddockCovers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
    this.trans = Translation.getInstance().translate;
  }

  toggleCollapsed() {
    this.setState({collapsed: !this.state.collapsed})
  }

  render() {
    return (
      <div className="ScrollPanelItem" style={{height: "auto", minHeight: "auto"}}>
        <div className={this.state.collapsed ? "ScrollPanelItemHeader Collapsed" : "ScrollPanelItemHeader"} onClick={this.toggleCollapsed.bind(this)}>
          <h3 style={{margin: "0"}}>{this.trans('edit_covers')}</h3>
          <img className={this.state.collapsed ? "DropIcon" : "DropIcon Expanded"} src={Drop} alt="" />
        </div>
        {this.state.collapsed ? null : <div style={{padding: "0 20px 10px"}}>
          <MeasurementInputManual
            dataset={this.props.farm}
            getEventData={this.props.getEventData}
            getDataset={this.props.getDataset}
          />
        </div>}
      </div>
    );
  }
}

export default PaddockCovers;

class MeasurementInputManual extends Component {
  constructor(props) {
    super(props);
    const date = moment();
    let cover = null;
    const covers = [];
    const allCovers = this.props.dataset.covers;
    for (let i = 0; i < allCovers.length; i++) {
      if (moment(allCovers[i].date).isSame(date, 'day')) {
        cover = allCovers[i];
      }
    }
    if (cover) {
      const paddocks = this.props.dataset.paddocks;
      for (let i = 0; i < paddocks.length; i++) {
        const pad = paddocks[i];
        const pc = cover.padCovers.slice().reverse();
        let result = ' ';
        for (let j = 0; j < pc.length; j++) {
          if (pc[j].paddock_id === pad.id) {
            result =
              pc[j].manualCover !== null ? pc[j].manualCover : pc[j].dmHectare;
            break;
          }
        }
        covers.push(result);
      }
    } else {
      const paddocks = this.props.dataset.paddocks;
      for (let i = 0; i < paddocks.length; i++) {
        covers.push(' ');
      }
    }
    this.state = {
      date: date,
      method: '1',
      survey_methods: [],
      covers: covers
    };
    this.trans = Translation.getInstance().translate;
  }
  handleDateChange(date) {
    let cover = null;
    const covers = [];
    const allCovers = this.props.dataset.covers;
    for (let i = 0; i < allCovers.length; i++) {
      if (moment(allCovers[i].date).isSame(date, 'day')) {
        cover = allCovers[i];
      }
    }

    const paddocks = this.props.dataset.paddocks;
    for (let i = 0; i < paddocks.length; i++) {
      if (cover) {
        const pad = paddocks[i];
        const pc = cover.padCovers.slice().reverse();
        let result = ' ';
        for (let j = 0; j < pc.length; j++) {
          if (pc[j].paddock_id === pad.id) {
            result =
              pc[j].manualCover !== null ? pc[j].manualCover : pc[j].dmHectare;
            break;
          }
        }
        covers.push(result);
      } else {
        const paddocks = this.props.dataset.paddocks;
        for (let i = 0; i < paddocks.length; i++) {
          covers.push(' ');
        }
      }
    }


    this.setState({ date: date, covers: covers });
  }
  handleSurveyMethodChange(event) {
    this.setState({ survey_method: event.target.value });
  }
  handleCoverChange(i, event) {
    const covers = this.state.covers;
    covers[i] = event.target.value;
    this.setState({ covers: covers });
  }
  componentDidMount() {
    const self = this;
    axios
      .get('https://staging.grasslandtools.ie/api/surveymethods/')
      .then(function(response) {
        self.setState({ survey_methods: response.data.surveymethods });
      });
  }
  handleEnter(i, e) {
    if (e.key === 'Enter' && i < this.props.dataset.paddocks.length - 1) {
      document.getElementById('CoverEntry' + (i + 1)).focus();
    }
  }
  submit() {
    const self = this;
    const covers = this.state.covers.map(function(cover, i) {
      return { paddock_id: self.props.dataset.paddocks[i].id, cover: cover };
    });
    self.setState({ sent: true });
    axios
      .post('https://staging.grasslandtools.ie/api/manualcovers/', {
        sessionid: UserController.getSessionkey(),
        date: self.state.date.format('YYYY-MM-DD'),
        method: self.state.method,
        covers: covers
      })
      .then(function(response) {
        self.props.getDataset(UserController.getFarmid(), 'Toolset', null);
        self.setState({ sent: false });
      })
      .catch(function(error) {
        self.setState({ sent: false });
        alert(self.trans('error'));
      });
  }
  render() {
    if (this.state.files_sent) {
      return (
        <div
          className="InputDiv"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 'calc(100% - 40px)',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <img className="Spinner" src={Spinner} alt="Loading" />
          <div>{this.trans('uploading_measurements')}...</div>
        </div>
      );
    }
    const self = this;
    const padRows = this.props.dataset.paddocks.map(function(pad, i) {
      return (
        <tr key={i}>
          <td>{pad.name}</td>
          <td>{Math.round(pad.area / 100) / 100}</td>
          <td>
            <input
              id={'CoverEntry' + i}
              style={{
                textAlign: 'center'
              }}
              value={self.state.covers[i]
                ? Math.round(self.state.covers[i])
                : ''}
              onChange={self.handleCoverChange.bind(self, i)}
              className="FormInput"
              type="number"
              placeholder={self.trans('input_cover')}
              onKeyPress={self.handleEnter.bind(self, i)}
            />
          </td>
          <td>
            {self.state.covers[i]
              ? Math.round(self.state.covers[i] * pad.area / 10000)
              : ''}
          </td>
        </tr>
      );
    });
    const surveymethods = this.state.survey_methods.map(function(method, i) {
      return (
        <option key={i} value={method.id}>
          {method.name}
        </option>
      );
    });
    return (
      <div>
        <div className="MetricGroup">
          <div className="Metric">
            <label>
              {this.trans('date')}
            </label>
            <DatePicker
              dateFormat="DD/MM/YYYY"
              onChange={this.handleDateChange.bind(this)}
              selected={this.state.date}
              className="FormInput"
              placeholderText={this.trans('date')}
              style={{
                zIndex: '5'
              }}
            />
          </div>
          <div className="Metric">
            <label>
              {this.trans('method')}
            </label>
            <select
              className="FormInput"
              value={this.state.survey_method}
              onChange={this.handleSurveyMethodChange.bind(this)}
            >
              {surveymethods}
            </select>
          </div>
          <div className="Metric">
            <label style={{opacity: 0}}>
              {this.trans('save')}
            </label>
            <div className="Button Action" onClick={this.submit.bind(this)}>
              {this.trans('submit')}
            </div>
          </div>
        </div>
        <div
          style={{
            width: 'calc(100% - 20px)',
            margin: '10px'
          }}
        >
          <table className="SelectedTable">
            <thead>
              <tr>
                <th>{this.trans('paddock')}</th>
                <th>
                  {this.trans('area')}
                  (ha)
                </th>
                <th>
                  {this.trans('hectare_cover')}
                  (kg/ha)
                </th>
                <th>
                  {this.trans('paddock_cover')}
                  (kg)
                </th>
              </tr>
            </thead>
            <tbody>{padRows}
              <tr><td></td><td></td>
                <td>
                  <div className="Button Action" onClick={this.submit.bind(this)}>
                    {this.trans('submit')}
                  </div>
                </td>
              <td></td></tr>
            </tbody>
          </table>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
          </div>
        </div>
      </div>
    );
  }
}
