import React, { Component } from "react";
import "./Login.css";
import logo from "./img/GrasslandToolsLogoNewGG.png";

import Flags from "./Flags";
import Translation from "./Translation";
import moment from "moment-with-locales-es6";

import "./Header.css";
class SplashPage extends Component {
  render() {
    const trans = Translation.getInstance().translate;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          height: "100%"
        }}
      >
        <img src={logo} alt="GrasslandTools" className="LoginLogoLarge" />
        <div className="Footer">
          <span
            style={{
              paddingLeft: "10px"
            }}
          >
            {trans("copyright") +
              " © 2017 - " +
              moment().format("YYYY") +
              " True North Technologies Ltd."}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              float: "right"
            }}
          >
            <Flags incrementKey={this.props.incrementKey} />{" "}
            {this.props.userLoggedIn ? (
              <div
                style={{
                  cursor: "pointer"
                }}
                className="Logout"
                onClick={this.props.logout.bind(this)}
              >
                {trans("logout")}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default SplashPage;
